import formStyles from 'modules/auth/Form.module.scss'
import styles from './CreateChannelView.module.scss'
import {ReactComponent as DSLRIcon} from 'icons/DSLR.svg'

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import MetaTags from 'react-meta-tags'
import classnames from 'classnames'

// import AppLayout from 'layout'
import SplitPaneLayout from 'layout/SplitPaneLayout'
import { OnboardingForm } from 'components/Onboarding'
import { PromoContent } from 'components/Onboarding'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import DummyPersonalChannel from './components/DummyPersonalChannel'
import DummyOrganizationChannel from './components/DummyOrganizationChannel'
import DummyOtherChannel from './components/DummyOtherChannel'
import * as actions from './channelActions'
import { uniqueid, linkState, featureUsed } from 'utils'
import { generateUniqueChannelSlug } from 'utils/channel'
import ThemeSelector from 'components/ThemeSelector'
import { Switch } from 'components/Switch'
import IconStyle from 'components/IconStyle'

class CreateChannelView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: uniqueid(),
      photoUrl: '',
      name: '',
      description: '',
      type: 'personal',
      private: false,
      typeSelected: false,
      showErrorMessage: false,
    }

    this.filePickerRef = React.createRef()
    this.hasSetTheme = !!localStorage.theme
  }

  componentDidMount() {
    featureUsed({ action: 'channel_creation_step1', category: 'channel', label: 'User started to create a channel' })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.channelType !== prevProps.channelType) {
      if (!this.props.channelType) {
        this.setState({ typeSelected: false })
      } else {
        this.setState({ type: this.props.channelType })
      }
    }
  }

  selectType(e) {
    e.preventDefault()

    this.setState({ typeSelected: true }, () => {
      this.props.dispatch(push(`?type=${this.state.type}`))
      featureUsed({ action: 'channel_creation_step2', category: 'channel', label: 'User selected channel type' })
    })
  }

  uploadChannelPhoto(event) {
    if (event.target.files[0]) {
      this.props.actions.uploadChannelPhoto({ id: this.state.id }, event.target.files[0])
        .then(photoUrl => {
          if (photoUrl) {
            this.setState({ photoUrl })
          }
        })
    }
  }

  async createChannel(e) {
    e.preventDefault()

    if (this.state.name.trim().length < 1) {
      return this.setState({ showErrorMessage: true })
    }

    this.props.actions.createChannel({
      photoUrl: this.state.photoUrl,
      name: this.state.name,
      slug: await generateUniqueChannelSlug({ slug: this.state.name, private: this.state.private }),
      description: this.state.description,
      type: this.state.type,
      private: this.state.private,
    }).then(channel => {
      this.props.dispatch(push(`/${channel.slug}`))
      featureUsed({ action: 'channel_creation_step3', category: 'channel', label: 'User created a channel' })
    })
  }

  onChannelTypeChanged(e) {
    this.setState({ type: e.currentTarget.value })
  }

  renderDummyChannel() {
    switch (this.state.type) {
      case 'personal':
        return <DummyPersonalChannel name={this.state.name} photoUrl={this.state.photoUrl} />

      case 'organization':
        return <DummyOrganizationChannel name={this.state.name} photoUrl={this.state.photoUrl} />

      case 'other':
        return <DummyOtherChannel name={this.state.name} photoUrl={this.state.photoUrl} />

      default:
        return <></>
    }
  }

  renderChannelTypeSelection() {
    return (
      <form className={formStyles.form}>
        <div className={formStyles.radioControl}>
          <input
            type="radio"
            name="type"
            id="personal"
            value="personal"
            checked={this.state.type === 'personal'}
            onChange={this.onChannelTypeChanged.bind(this)}
          />

          <label className={formStyles.richLabel} htmlFor="personal">
            <div className={formStyles.labelTitle}>Personal</div>
            <div className={formStyles.labelDescription}>
              👩‍ 🎓 Highlight your individuality and build better visibility online
            </div>
          </label>
        </div>
        <div className={formStyles.radioControl}>
          <input
            type="radio"
            name="type"
            id="organization"
            value="organization"
            checked={this.state.type === 'organization'}
            onChange={this.onChannelTypeChanged.bind(this)}
          />

          <label className={formStyles.richLabel} htmlFor="organization">
            <div className={formStyles.labelTitle}>Organization</div>
            <div className={formStyles.labelDescription}>
              👨‍ 👩🏾‍ 🔬 Engage with your communities in powerful new ways
            </div>
          </label>
        </div>
        <div className={formStyles.radioControl}>
          <input
            type="radio"
            name="type"
            id="other"
            value="other"
            checked={this.state.type === 'other'}
            onChange={this.onChannelTypeChanged.bind(this)}
          />

          <label className={formStyles.richLabel} htmlFor="other">
            <div className={formStyles.labelTitle}>Other (cause, topic, etc.)</div>
            <div className={formStyles.labelDescription}>
              🍃 📄 Build better discussions for a cause you care about or a new research topic
            </div>
          </label>
        </div>
        <Button
          kind="primary"
          isBlocky={true}
          onClick={this.selectType.bind(this)}
          >
          Next
        </Button>
        {/* <div className={formStyles.alternatives}>
          <p>
            <Link to="/">Skip</Link><span>I'll do this later</span>
          </p>
        </div> */}
      </form>
    )
  }

  triggerFilePicker(e) {
    e.preventDefault()
    this.filePickerRef.current.click()
  }

  renderChannelForm() {
    const nameIsInvalid = this.state.showErrorMessage && this.state.name.trim().length < 1

    return (
      <form className={formStyles.form}>
        <div className={formStyles.fileInput}>
          <label
            id="channel-photo-file-label"
            className={styles.uploadIcon}
            htmlFor="channel-photo-file">
            {this.state.photoUrl ? (
              <Avatar
                photoUrl={this.state.photoUrl}
                size={64}
                border={true} />
            ) : (
              <IconStyle size={64} border={true}>
                <DSLRIcon />
              </IconStyle>
            )
            }
          </label>
          <input
            id="channel-photo-file"
            ref={this.filePickerRef}
            type="file"
            onChange={this.uploadChannelPhoto.bind(this)}
            accept="image/*" />
          <Button
            kind="text"
            htmlFor="channel-photo-file"
            onClick={this.triggerFilePicker.bind(this)}
          >
            Add cover
          </Button>
        </div>

        <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: nameIsInvalid })}>
          {/* <label>Channel name</label> */}
          <input
            type="text"
            name="name"
            maxLength="100"
            className={formStyles.input}
            value={this.state.name}
            onChange={linkState(this, 'name')}
            placeholder="Channel name"
            autoFocus
          />
          <div className={formStyles.additionalControlInfo}>
            <small className={formStyles.invalidFeedback}>
              Please, choose a name for the channel
            </small>
          </div>
        </div>

        <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: false })}>
          {/* <label>About channel</label> */}
          <textarea
            name="description"
            maxLength="1000"
            style={{height: '1em'}}
            value={this.state.description}
            onChange={linkState(this, 'description')}
            placeholder="About channel"
          />
        </div>

        <Switch
          id="private"
          name="private"
          className={formStyles.formControlOutstanding}
          title="Private channel"
          subtitle="Only collaborators can see it"
          onChange={linkState(this, 'private')}
        />

        {!this.hasSetTheme && this.props.userChannels.length === 0 &&
          <ThemeSelector
            className={formStyles.formControlOutstanding}
          />
        }

        <Button
          kind="primary"
          isBlocky={true}
          onClick={this.createChannel.bind(this)}
          disabled={this.props.channelFetchInProgress}
        >
          Let's go!
        </Button>
      </form>
    )
  }

  render() {
    // const subtitle = this.state.typeSelected ? undefined : 'Lightspot Channels are a unique space to broadcast what you love to a small or a large audience'
    const subtitle = undefined
    return (
      <>
        <MetaTags>
          <title>Create new channel – Lightspot</title>
          <meta property="og:title" content={'Create new channel – Lightspot'} />
          <meta name="description" content={'Create new channel – Lightspot'} />
          {/* <meta property="og:image" content="path/to/image.jpg" /> */}
        </MetaTags>

        <SplitPaneLayout
          leftPane={
            <OnboardingForm
              title='Create your channel'
              subtitle={subtitle}
              // mobilePromo={<WatchVideoButton />}
              footer={undefined}>
                {this.state.typeSelected ? this.renderChannelForm() : this.renderChannelTypeSelection()}
            </OnboardingForm>
          }
          rightPane={
            <PromoContent>
              <div className={styles.dummyChannel}>
                {this.renderDummyChannel()}
              </div>
            </PromoContent>
          }
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  userChannels: state.user.channels,
  channelFetchInProgress: state.channel.channelFetchInProgress,
  channelType: state.router.location.query.type || false,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateChannelView)
