import {
  FEED_FETCH_SUBSCRIPTIONS_START,
  FEED_FETCH_SUBSCRIPTIONS_SUCCESS,
  FEED_FETCH_SUBSCRIPTIONS_ERROR,
  FEED_FETCH_FEED_START,
  FEED_FETCH_FEED_SUCCESS,
  FEED_FETCH_FEED_ERROR,
} from './feedConstants'

import {
  EPHIES_REACT_TO_EPHY_SUCCESS
} from 'modules/ephy/ephyConstants'

import { sortEphies } from 'utils/ephy'

const initialState = {
  subscriptions: [],
  ephies: [],
  subscriptionsFetchInProgress: false,
  feedFetchInProgress: false,
}

export default function feedReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FEED_FETCH_SUBSCRIPTIONS_START:
      return {...state,
        subscriptions: [],
        subscriptionsFetchInProgress: true,
        feedFetchInProgress: true,
      }

    case FEED_FETCH_FEED_START:
      return {...state,
        ephies: [],
        feedFetchInProgress: true,
      }

    case FEED_FETCH_SUBSCRIPTIONS_SUCCESS:
      return {...state,
        subscriptions: payload.data,
        subscriptionsFetchInProgress: false,
      }

    case FEED_FETCH_FEED_SUCCESS:
      return {...state,
        ephies: sortEphies([
          ...(state.ephies.filter(ephy => !payload.data.find(newEphy => newEphy.id === ephy.id))),
          ...payload.data
        ], { ignorePinned: true }),
        feedFetchInProgress: false,
      }

    case FEED_FETCH_SUBSCRIPTIONS_ERROR:
      return {...state,
        subscriptionsFetchInProgress: false
      }

    case FEED_FETCH_FEED_ERROR:
      return {...state,
        feedFetchInProgress: false
      }

    // TODO remove copy-paste. 2nd copy in channelReducer
    case EPHIES_REACT_TO_EPHY_SUCCESS:
      return {...state,
        ephies: state.ephies.map(ephy => {
          if (ephy.id !== payload.data.ephyId) {
            return ephy
          } else {
            const reactions = {...ephy.reactions}
            if (payload.data.reaction) {
              if (reactions[payload.data.reaction]) {
                reactions[payload.data.reaction]++
              } else {
                reactions[payload.data.reaction] = 1
              }
            }

            if (payload.data.removeReaction) {
              if (reactions[payload.data.removeReaction] === 1) {
                delete reactions[payload.data.removeReaction]
              } else {
                reactions[payload.data.removeReaction]--
              }
            }
            return {...ephy, reactions}
          }
        })
      }

    default:
      return state
  }
}
