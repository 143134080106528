import React from 'react'

import Avatar from 'components/Avatar'

export const ChannelAvatar = ({
  name,
  slug,
  photoUrl,
  photoSize = 40,
}) => (
  <Avatar
    photoUrl={photoUrl}
    linkTo={slug && `/${slug}`}
    name={name}
    size={photoSize}
    border={true}
  />
)
