import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getFirebase } from 'react-redux-firebase'
import classnames from 'classnames'
import Error404View from 'modules/error/Error404View'
import MetaTags from 'react-meta-tags'

// import AppLayout from 'layout/AppLayout'
import Button from 'components/Button'
import { HelloUser } from 'components/Onboarding'

import formStyles from './Form.module.scss'
import styles from './Auth.module.scss'

class ConfirmPasswordResetView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newPassword: null,
      newPasswordRepeat: null,
      passwordIsReset: false,
      showPassword: false,
      showErrorMessage: false,
      validation: [],
    }

    this.firebase = getFirebase()
  }

  confirmPasswordReset(e) {
    e.preventDefault()

    const passwordFormValidation = this.validatePasswordForm()

    if (!passwordFormValidation.newPassword || !passwordFormValidation.newPasswordRepeat) {
      this.setState({
        showErrorMessage: true,
        validation: passwordFormValidation
      })
    } else {
      this.firebase
        .confirmPasswordReset(this.props.oobCode, this.state.newPassword)
        .then(() => {
          this.setState({ newPassword: '', newPasswordRepeat: '', passwordIsReset: true })
        })
        .catch(e => {
          this.setState({ showErrorMessage: true, validation: Object.assign(this.validatePasswordForm(), { code: false })})
        })
    }
  }

  validatePasswordForm() {
    return {
      newPassword: typeof this.state.newPassword === 'string' && this.state.newPassword.trim().length > 5,
      newPasswordRepeat: this.state.newPassword === this.state.newPasswordRepeat,
      code: true,
    }
  }

  showPassword(e) {
    e.preventDefault()
    this.setState({ showPassword: !this.state.showPassword })
  }

  renderPasswordReset() {
    return (
      <div className={ styles.text }>
        Password has been reset!<br/>
        You can <Link to="/login">login</Link> with your new password
      </div>
    )
  }

  renderForm() {
    return (
      <form className={formStyles.form}>
        <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: this.state.showErrorMessage && !this.state.validation.newPassword })}>
          <input
            type={ this.state.showPassword ? 'text' : 'password'}
            name="new-password"
            maxLength="100"
            autoComplete="new-password"
            className={formStyles.input}
            value={this.state.newPassword || ''}
            // onChange={linkState(this, 'newPassword')}
            onChange={(e) => this.setState({ newPassword: e.target.value, showErrorMessage: false })}
            placeholder="New password"
          />
          <div className={formStyles.additionalControlInfo}>
            <small className={formStyles.invalidFeedback}>
              Should be 6 or more characters long
            </small>
            {/* <small className={styles.forgot}> */}
              {/* <Button kind="text" */}
                {/* onClick={this.showPassword.bind(this)}> */}
                {/* { this.state.showPassword ? 'Hide' : 'Show' } */}
              {/* </Button> */}
            {/* </small> */}
          </div>
        </div>
        <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: this.state.showErrorMessage && (!this.state.validation.newPasswordRepeat || !this.state.validation.code)})}>
          <input
            type={ this.state.showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            name="repeat-new-password"
            maxLength="100"
            className={formStyles.input}
            value={this.state.newPasswordRepeat || ''}
            onChange={(e) => this.setState({ newPasswordRepeat: e.target.value, showErrorMessage: false })}
            // onChange={linkState(this, 'newPasswordRepeat')}
            placeholder="Repeat your new password"
          />
          <div className={formStyles.additionalControlInfo}>
            <small className={formStyles.invalidFeedback}>
              {!this.state.validation.newPasswordRepeat && "Passwords don't match"}
              {!this.state.validation.code && "Reset code has expired or is invalid. Please, request a new one"}
            </small>
            <small className={styles.forgot}>
              <Button kind="text"
                onClick={this.showPassword.bind(this)}>
                { this.state.showPassword ? 'Hide' : 'Show' }
              </Button>
            </small>
          </div>
        </div>
        <Button
          kind="primary"
          isBlocky={true}
          onClick={this.confirmPasswordReset.bind(this)}
          >
          Set new password
        </Button>
        <div className={formStyles.alternatives}>
          {/* <p><BackIcon style={{ width: '6px', height: 'auto'}} /><span>Back to </span><Link to="/login">Login</Link></p> */}
          <p><span>&larr;</span><span>Back to </span><Link to="/login">Login</Link></p>
        </div>
      </form>
    )
  }

  render () {
    if (this.props.oobCode.length < 16) {
      return <Error404View />
    }

    return (
      <>
        <MetaTags>
          <title>Set new password – Lightspot</title>
          <meta name="description" content="Set new password on Lightspot" />
          <meta property="og:title" content="Set new password – Lightspot" />
        </MetaTags>

        <HelloUser showPolicy={false}>
          {this.state.passwordIsReset ? this.renderPasswordReset() : this.renderForm()}
        </HelloUser>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authError: state.firebase.authError,
  oobCode: state.router.location.query.oobCode || '',
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPasswordResetView)
