import styles from './EphyChannelName.module.scss'

import React from 'react'
import { Link } from 'react-router-dom'

export const EphyChannelName = ({channel}) => (
  <div className={styles.container}>
    <Link to={`/${channel.slug}`}>{channel.name}</Link>
  </div>
 )
