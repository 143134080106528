import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { push } from 'connected-react-router'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'

import { featureUsed } from 'utils'

export const requireAuthentication = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsAuthenticated',
  authenticatedSelector: state => !state.firebase.auth.isEmpty,
  redirectPath: '/login',
})

export const notLoggedIn = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsNotAuthenticated',
  authenticatedSelector: state => state.firebase.auth.isEmpty,
  redirectAction: push,
  allowRedirectBack: false,
  redirectPath: (state) => {
    const isJustRegistered = (Date.now() - parseInt(state.firebase.auth.createdAt, 10)) / 1000 < 20
    if (state.router.location.pathname === '/signup' && isJustRegistered) {
      featureUsed({ action: 'user_signup', category: 'user', label: 'User signed up' })
    }

    const redirectPath = state.router.location.query.redirect && decodeURIComponent(state.router.location.query.redirect)
    if (redirectPath && redirectPath[0] === '/') {
      return redirectPath
    }

    if (state.router.location.pathname === '/signup' || isJustRegistered) {
      return '/welcome'
    }

    return '/'
  },
})

export const AuthIsLoaded = ({ children }) => {
  const auth = useSelector(state => state.firebase.auth)

  if (!isLoaded(auth)) {
    return <></>
  }

  return children
}
