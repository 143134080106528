import styles from './UserList.module.scss'
import { ReactComponent as TrashIcon } from 'icons/Trash.svg'
import { ReactComponent as CrownIcon } from 'icons/Crown.svg'

import React from 'react'
import isCallable from 'is-callable'

import Button from 'components/Button'
import { UserListItem } from './UserListItem'

export function UserList({ currentUserId, users, onPromote, onRemove, promoteExcludeIds = [], removeExcludeIds = [] }) {
  if (!Array.isArray(users) || !users.length) {
    return <p className={styles.noUsers}>No users yet!</p>
  }

  const getActions = (userId) => {
    if (currentUserId !== userId) {
      return (
        <>
          {isCallable(onPromote)
            && !promoteExcludeIds.includes(userId)
            && <Button kind="icon" onClick={() => onPromote(userId)} title="Promote to Co-host"><CrownIcon /></Button>
          }

          {isCallable(onRemove)
            && !removeExcludeIds.includes(userId)
            && <Button kind="icon" onClick={() => onRemove(userId)} title="Remove"><TrashIcon /></Button>
          }
        </>
      )
    }
  }

  return (
    <ul className={styles.list}>
      {users.map(user =>
        <UserListItem
          key={user.id}
          actions={getActions(user.id)}
          {...user}
        />
      )}
    </ul>
  )
}
