import {
  APP_SWITCH_THEME,
} from './appConstants'

export function switchTheme(theme = 'light') {
  return (dispatch) => {
    switch (theme) {
      case 'dark':
      case 'light':
      case 'sepia':
        document.documentElement.className = `${theme}-theme`
        localStorage.theme = theme
        break

      default:
        document.documentElement.className = `light-theme`
    }

    return dispatch({
      type: APP_SWITCH_THEME,
      payload: {
        theme
      }
    })
  }
}
