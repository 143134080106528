import isCallable from 'is-callable'
import {useEffect, useState} from 'react'

export function useBreakpoints({onChange} = {}) {
  const [isMobile, setIsMobile] = useState(() => window.matchMedia('(max-width: 800px)').matches)

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 800px)')
    const mqlHandler = e => {
      if (onChange) onChange.call(null, {isMobile})
      setIsMobile(e.matches)
    }
    if (isCallable(mql.addEventListener)) {
      mql.addEventListener('change', mqlHandler)
    } else {
      mql.addListener(mqlHandler)
    }
    return () => {
      if (isCallable(mql.removeEventListener)) {
        mql.removeEventListener('change', mqlHandler)
      } else {
        mql.removeListener(mqlHandler)
      }
    }
  }, [isMobile, setIsMobile, onChange])

  return {isMobile, isDesktop: !isMobile}
}
