import {
  CHANNELS_UPDATE_CHANNEL_START,
  CHANNELS_UPDATE_CHANNEL_SUCCESS,
} from '../channelConstants'

import isCallable from 'is-callable'

export function updateChannel(channel, callback) {
  return (dispatch, _getState, getFirebase) => {
    dispatch({ type: CHANNELS_UPDATE_CHANNEL_START })

    const channelData = {
      photoUrl: channel.photoUrl,
      name: channel.name.trim(),
      slug: channel.slug.toLowerCase(),
      description: channel.description ? channel.description.trim() : '',
    }

    if (typeof channel.private === 'boolean') {
      channelData.private = channel.private
    }

    if (typeof channel.inviteToken === 'string' && channel.inviteToken.length === 64) {
      channelData.inviteToken = channel.inviteToken
    }

    return getFirebase()
      .firestore()
      .collection('channels')
      .doc(channel.id)
      .update(channelData)
      .then(() => {
        if (isCallable(callback)) {
          callback(channelData)
        }

        dispatch({
          type: CHANNELS_UPDATE_CHANNEL_SUCCESS,
          payload: {
            channel: channelData
          }
        })
      })
  }
}
