export const initialTypebarState = {
  visible: false,
  collapsed: true,
  dirty: false,
  hidable: true,
  fullscreen: false,
  ephyId: null,
  uploadInProgress: false,
  uploadInProgressFilesCount: 0,
}

export function typebarReducer(state, action) {
  switch (action.type) {
    case 'clickedCollapsed':
      return {...state,
        collapsed: false,
      }
    case 'clickedEsc':
    case 'clickedOutside':
      if (!state.visible || state.collapsed === true) return state
      return {...state,
        collapsed: !state.fullscreen,
        fullscreen: false,
        visible: !state.hidable || state.dirty
      }
    case 'toggleFullscreen':
      return {...state,
        fullscreen: !state.fullscreen,
      }
    case 'view':
      return {...state,
        hidable: !action.payload.writable,
        visible: action.payload.writable || state.dirty
      }
    case /^ephy/.test(action.type) && action.type:
      return ephyReducer(state, action)
    default:
      throw new Error(action.type)
  }
}

function ephyReducer(state, action) {
  switch (action.type) {
    case 'ephy/wasEdited':
      if (state.dirty) return state
      return {...state,
        dirty: true,
      }
    case 'ephy/fileUploadStarted':
      state.uploadInProgressFilesCount += 1
      if (state.uploadInProgressFilesCount > 1) return state
      return {...state,
        uploadInProgress: true,
      }
    case 'ephy/fileUploadFinished':
      state.uploadInProgressFilesCount -= 1
      if (state.uploadInProgressFilesCount > 0) return state
      return {...state,
        uploadInProgress: false,
      }
    case 'ephy/clickedNew':
      if (
        state.dirty &&
        !window.confirm('Do you want to create a new post? Changes to the current one will be lost')
      )
        return state
      return {...state,
        ephyId: null,
        dirty: false,
        visible: true,
        collapsed: false,
      }
    case 'ephy/clickedEdit':
      if (
        state.dirty &&
        // state.ephyId !== action.payload.id &&
        !window.confirm('Do you want to edit another post? Changes to the current one will be lost')
      )
        return state
      return {...state,
        ephyId: action.payload.id,
        collapsed: false,
        dirty: false,
      }
    case 'ephy/wasSaved':
      return {...state,
        dirty: false,
        ephyId: null,
        collapsed: true,
        visible: !state.hidable
      }
    default:
      throw new Error(action.type)
  }
}
