import styles from './TagListItem.module.scss'

import React, { useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import classnames from 'classnames'
import TagLink from 'components/TagLink'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { ReactComponent as MenuIcon } from 'icons/Dots.svg'
import Menu, { MenuItem } from 'components/Menu'
import TagForm from 'components/TagForm'
import { subscribeUserToChannelTag, unsubscribeUserFromChannelTag } from 'modules/feed/feedActions'
import { deleteTag } from 'modules/tag/tagActions'
import { isUserSubscribedToChannelTag } from 'utils/feed'

export const EditableTagLink = ({
  item: tag,
  activeTag,
  channel,
  showActionsButton = true,
  pillStyle = 'light',
  isOverlay,
  onTagClick,
}) => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const onDelete = useCallback(
    () => dispatch(deleteTag(tag, { channel }))
  , [dispatch, channel, tag])

  if (isEditing) {
    return (
      <li key={tag.id} className={styles.tagGroup}>
        <TagForm
          tag={tag}
          channelId={channel?.id}
          onFinish={() => setIsEditing(false)}
          pillStyle={pillStyle}
        />
      </li>
    )
  }

  return (
    <li key={tag.id} className={classnames(styles.tagGroup, {[styles.overlay]: isOverlay})}>
      <TagLink
        channel={channel}
        tag={tag}
        active={tag.name === activeTag}
        pillStyle={pillStyle}
        onClick={onTagClick}
      />
      {showActionsButton &&
        <Dropdown
          className={styles.tagMenu}
          dropdown={
            <Menu>
              <MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
              <MenuItem onClick={onDelete}>Delete</MenuItem>
            </Menu>
          }
          button={
            <Button
              kind={isOverlay ? "iconDark" : "icon"}
              size="36"
            >
              <MenuIcon />
            </Button>
          }
        />
      }
    </li>
  )
}

export const NonEditableTagLink = ({
  item: tag,
  channel,
  activeTag,
  subscriptions,
  showActionsButton = true,
  isOverlay,
  pillStyle,
  onTagClick,
}) => {
  const dispatch = useDispatch()
  const isAuthenticated = !useSelector(state => state.firebase.auth.isEmpty)
  const isUserSubscribed = useMemo(
    () => {
      if (!channel?.id) return false
      return isUserSubscribedToChannelTag(channel, tag, subscriptions)
    }
  , [channel, tag, subscriptions])

  let onMenuClick, menuText

  if (showActionsButton) {
    if (isAuthenticated) {
      if (isUserSubscribed) {
        onMenuClick = () => dispatch(unsubscribeUserFromChannelTag(channel, tag))
        menuText = 'Unsubscribe'
      } else {
        onMenuClick = () => dispatch(subscribeUserToChannelTag(channel, tag))
        menuText = 'Subscribe'
      }
    } else {
      onMenuClick = () => dispatch(push(`/signup?redirect=${encodeURIComponent('/?subscribe_to_channel=' + channel.id + '&subscribe_to_tag=' + tag.id)}`))
      menuText = 'Subscribe'
    }
  }

  return (
    <li key={tag.id} className={classnames(styles.tagGroup, {[styles.overlay]: isOverlay})}>
      <TagLink
        channel={channel}
        tag={tag}
        pillStyle={pillStyle}
        active={tag.name === activeTag}
        onClick={onTagClick}
      />
      {showActionsButton &&
        <Dropdown
          className={styles.tagMenu}
          dropdown={
            <Menu>
              <MenuItem onClick={onMenuClick}>{menuText}</MenuItem>
            </Menu>
          }
          button={
            <Button
              kind={isOverlay ? "iconDark" : "icon"}
              size="36"
            >
              <MenuIcon />
            </Button>
          }
        />
      }
    </li>
  )
}

export const TagListItem = ({
  item: tag,
  activeTag,
  editable = false,
  subscriptions,
  channel,
}) => {
  const dispatch = useDispatch()
  const isUserSubscribed = isUserSubscribedToChannelTag(channel, tag, subscriptions)

  return (
    <li className={styles.tagGroup}>
      {editable ? (
        <EditableTagLink
          tag={tag}
          active={activeTag === tag.name}
          channel={channel}
          onDelete={() => dispatch(deleteTag(tag, { channel }))}
        />
      )
      :
      (
        <NonEditableTagLink
          tag={tag}
          active={activeTag === tag.name}
          channel={channel}
          menuText={isUserSubscribed ? 'Unsubscribe' : 'Subscribe'}
          onMenuClick={isUserSubscribed ?
            () => dispatch(unsubscribeUserFromChannelTag(channel, tag))
            :
            () => dispatch(subscribeUserToChannelTag(channel, tag))
          }
        />
      )}
    </li>
  )
}

TagListItem.propTypes = { }
