import styles from './Hint.module.scss'

import { Overlay } from 'components/Overlay'
import { useState, useEffect } from 'react'
import { usePopper } from 'react-popper'
import { useLockBodyScroll } from 'hooks'
import isCallable from 'is-callable'

export function Hint({
  selectors,
  children,
  placement = 'auto',
  onSelectorNotFound,
}) {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    update: popperUpdatePosition,
  } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: 'offset', options: {offset: [0, 16]} },
      { name: 'arrow', options: {element: arrowElement} },
    ],
  })

  useLockBodyScroll()

  useEffect(function findSelectorTarget() {
    const referenceElement = findElement(selectors)

    if (referenceElement) {
      if (!isInViewport(referenceElement)) {
        onSelectorNotFound()
        return
      }
      setReferenceElement(referenceElement)
    } else {
      setReferenceElement(null)
    }

    // fallback

    const observer = new MutationObserver((mutations, observer) => {
      const referenceElement = findElement(selectors)

      if (referenceElement) {
        if (!isInViewport(referenceElement)) {
          onSelectorNotFound()
          return
        }
        setReferenceElement(referenceElement)
        if (popperUpdatePosition) popperUpdatePosition()
        return
      }
    })

    observer.observe(document, {
      childList: true,
      subtree: true
    })

    return () => observer.disconnect()

    function findElement(selectors) {
      const referenceElements = selectors.map((selector) => {
        const element = isCallable(selector) ? selector(document) : document.querySelector(selector)
        return element
      })

      // eslint-disable-next-line array-callback-return
      return referenceElements.reduce((acc, el) => {
        // console.log(acc, el)
        if (acc) return acc
        if (el) return el
      }, null)
    }

    function isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  }, [selectors, popperUpdatePosition, onSelectorNotFound])

  useEffect(function waitForSelector() {
    if (referenceElement) return

    const timeout = setTimeout(() => {
      if (!referenceElement) onSelectorNotFound()
    }, 2000)

    return () => clearTimeout(timeout)
  }, [referenceElement, onSelectorNotFound])

  useEffect(function updatePositionOnRefElementResize() {
    if (referenceElement) {
      const observer = new ResizeObserver(entries => {
        if (popperUpdatePosition) popperUpdatePosition()
      })
      observer.observe(referenceElement)

      return () => observer.disconnect()
    }
  }, [referenceElement, popperUpdatePosition])

  if (!referenceElement) return null

  return (
    <Overlay>
      <div
        className={styles.container}
        ref={setPopperElement}
        style={{
          ...popperStyles.popper,
        }}
        {...popperAttributes.popper}
      >
        {children}
        <div
          className={styles.arrow}
          ref={setArrowElement}
          style={{...popperStyles.arrow}}
        />
      </div>
    </Overlay>
  )
}
