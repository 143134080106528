import {
  CHANNELS_CREATE_CHANNEL_START,
  CHANNELS_CREATE_CHANNEL_SUCCESS,
  CHANNELS_CREATE_CHANNEL_ERROR,
  CHANNELS_FETCH_CHANNEL_START,
  CHANNELS_FETCH_CHANNEL_SUCCESS,
  CHANNELS_FETCH_CHANNEL_ERROR,
  // CHANNELS_DELETE_CHANNEL_START,
  // CHANNELS_DELETE_CHANNEL_SUCCESS,
  // CHANNELS_DELETE_CHANNEL_ERROR,
  CHANNELS_FETCH_EPHIES_START,
  CHANNELS_FETCH_EPHIES_SUCCESS,
  CHANNELS_FETCH_EPHIES_ERROR,
  CHANNELS_FETCH_TAGS_START,
  CHANNELS_FETCH_TAGS_SUCCESS,
  CHANNELS_FETCH_TAGS_ERROR,
  // CHANNELS_SEARCH_EPHIES_START,
  CHANNELS_SEARCH_SUCCESS,
} from './channelConstants'

import {
  EPHIES_UPDATE_EPHY_SUCCESS,
  EPHIES_DELETE_EPHY_SUCCESS,
  EPHIES_CREATE_COMMENT_SUCCESS,
} from 'modules/ephy/ephyConstants'

import {
  TAGS_UPDATE_TAG_SUCCESS,
  TAGS_DELETE_TAG_SUCCESS,
} from 'modules/tag/tagConstants'

import {
  EPHIES_REACT_TO_EPHY_SUCCESS
} from 'modules/ephy/ephyConstants'

import { constants as firebaseConsants } from 'react-redux-firebase'

import { sortEphies } from 'utils/ephy'

const initialState = {
  channel: {},
  searchChannels: [],
  tags: {}, // { channelId1: [tag1, tag2, ...], channelId2... }
  ephies: [],
  channelFetchInProgress: false,
  tagsFetchInProgress: false,
  ephiesFetchInProgress: false,
}

export default function channelReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHANNELS_FETCH_CHANNEL_START:
      return {...state, channelFetchInProgress: true}

    case CHANNELS_FETCH_TAGS_START:
      return {...state, tagsFetchInProgress: true}

    case CHANNELS_FETCH_EPHIES_START:
      return {...state,
        ephies: [],
        ephiesFetchInProgress: true,
      }

    case CHANNELS_CREATE_CHANNEL_START:
      return {...state, channelFetchInProgress: true}

    case CHANNELS_FETCH_CHANNEL_SUCCESS:
      return {...state,
        channel: payload.data,
        channelFetchInProgress: false,
      }

    case CHANNELS_FETCH_TAGS_SUCCESS:
      return {...state,
        tags: {...state.tags, [payload.channelId]: payload.data },
        tagsFetchInProgress: false,
      }

    case CHANNELS_FETCH_EPHIES_SUCCESS:
      return {...state,
        ephies: sortEphies([
          ...(state.ephies.filter(ephy => !payload.data.find(newEphy => newEphy.id === ephy.id))),
          ...payload.data
        ]),
        ephiesFetchInProgress: false,
      }

    case EPHIES_REACT_TO_EPHY_SUCCESS:
      return {...state,
        ephies: state.ephies.map(ephy => {
          if (ephy.id !== payload.data.ephyId) {
            return ephy
          } else {
            const reactions = {...ephy.reactions}
            if (payload.data.reaction) {
              if (reactions[payload.data.reaction]) {
                reactions[payload.data.reaction]++
              } else {
                reactions[payload.data.reaction] = 1
              }
            }

            if (payload.data.removeReaction) {
              if (reactions[payload.data.removeReaction] === 1) {
                delete reactions[payload.data.removeReaction]
              } else {
                reactions[payload.data.removeReaction]--
              }
            }
            return {...ephy, reactions}
          }
        })
      }

    case EPHIES_UPDATE_EPHY_SUCCESS:
      if (payload.data.channel && state.channel.id && payload.data.channel.id === state.channel.id) {
        return {...state,
          ephies: sortEphies(state.ephies.map(ephy => (
            ephy.id === payload.data.id ? payload.data : ephy
          )))
        }
      }

      return state

    case EPHIES_DELETE_EPHY_SUCCESS:
      if (payload.data.channel && state.channel.id && payload.data.channel.id === state.channel.id) {
        return {...state,
          ephies: state.ephies.filter(ephy => ephy.id !== payload.data.id)
        }
      }

      return state

    case CHANNELS_CREATE_CHANNEL_SUCCESS:
      return {...state,
        channel: payload.data,
        channelFetchInProgress: false,
      }

    case CHANNELS_SEARCH_SUCCESS:
      return {...state,
        searchChannels: payload.data,
      }

    case CHANNELS_FETCH_CHANNEL_ERROR:
      return {...state,
        channelFetchInProgress: false
      }

    case CHANNELS_FETCH_TAGS_ERROR:
      return {...state,
        tagsFetchInProgress: false
      }

    case CHANNELS_FETCH_EPHIES_ERROR:
      return {...state,
        ephiesFetchInProgress: false
      }

    case CHANNELS_CREATE_CHANNEL_ERROR:
      return {...state,
        channelFetchInProgress: false
      }

    // tag updates
    case TAGS_UPDATE_TAG_SUCCESS:
      return {...state,
        ephies: state.ephies.map(ephy => {
          const tagIndex = ephy.tags.indexOf(payload.originalData.name)
          if (tagIndex !== -1) {
            const updatedEphy = {...ephy, tags: [...ephy.tags]}
            updatedEphy.tags[tagIndex] = payload.data.name

            return updatedEphy
          }

          return ephy
        })
      }

    case TAGS_DELETE_TAG_SUCCESS:
      return {...state,
        ephies: state.ephies.map(ephy => {
          if (ephy.tags.indexOf(payload.data.name) !== -1) {
            return {...ephy, tags: ephy.tags.filter(tag => tag !== payload.data.name)}
          }

          return ephy
        })
      }

    case EPHIES_CREATE_COMMENT_SUCCESS:
      return {...state,
        ephies: state.ephies.map(ephy => {
          if (ephy.id === payload.data.ephyId) {
            return {...ephy, commentsCount: (ephy.commentsCount || 0) + 1}
          }

          return ephy
        })
      }

    case firebaseConsants.actionTypes.LOGOUT:
      return initialState

    default:
      return state
  }
}
