import { uniqueid } from 'utils'

const channel = {
  id: uniqueid(),
  name: 'Lightspot',
  slug: 'lightspot',
  photoUrl: 'https://firebasestorage.googleapis.com/v0/b/ephy-dev.appspot.com/o/users%2FwFe8vf0TsPQIjW9ylyvfRNCjzbY2%2Fphoto-gQKKO18oRJoNKsWYcr.png?alt=media&token=ee715f7d-073f-4860-a6c7-d0369a8fa76c',
}

const dummyOrganizationChannelData = {
  channel,
  tags: [
    { id: uniqueid(), name: 'Overview'},
    { id: uniqueid(), name: 'Vision'},
    { id: uniqueid(), name: 'Research'},
    { id: uniqueid(), name: 'Impact'},
    { id: uniqueid(), name: 'People'},
  ],
  ephies: [
    {
      id: uniqueid(),
      channel,
      text: "<ul>"
      + '<figure class="media"><div data-oembed-url="https://www.youtube.com/watch?v=C-1KEzO5pEI"><div style="position:relative;padding-bottom:100%;height:0;padding-bottom:56.2493%"><iframe src="https://www.youtube.com/embed/C-1KEzO5pEI" style="position:absolute;width:100%;height:100%;top:0;left:0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div></div></figure>'
      + "<li>Build a more meaningful online presence for your insights and passions, inspire your communities, and highlight your individuality the way you want to</li>"
      + "<li>Display your works, courses, or what you like in easy-to-share public channels, whether it’s climate and investing or science and productivity."
      + "<li>Streamline anything to a large or a small audience in easy to start open or private rooms. Voice, text or video — whatever you feel in the moment.</li>"
      + "</ul>",
      tags: ['Overview'],
      impressions: '2.2K',
      pinned: false,
      createdAt: {
        seconds: 1608109163,
        nanoseconds: 813000000
      },
    },
  ]
}

export default dummyOrganizationChannelData
