import {
  CHANNELS_DELETE_CHANNEL_START,
  CHANNELS_DELETE_CHANNEL_SUCCESS,
  CHANNELS_DELETE_CHANNEL_ERROR,
} from '../channelConstants'

export function deleteChannel(channel) {
  return (dispatch, _getState, getFirebase) => {
    dispatch({ type: CHANNELS_DELETE_CHANNEL_START })

    return new Promise((resolve, reject) => {
      getFirebase()
        .firestore()
        .collection('channels')
        .doc(channel.id)
        .update({
          deleted: true
        })
        .then(() => {
          resolve()

          dispatch({ type: CHANNELS_DELETE_CHANNEL_SUCCESS })
        })
        .catch(error => {
          reject(error)

          dispatch({ type: CHANNELS_DELETE_CHANNEL_ERROR })
        })
      })
  }
}
