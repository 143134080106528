import { SUBSCRIPTION_ALL } from 'modules/feed/feedConstants'

export const isUserSubscribedToChannel = ({id: targetChannelID}, userSubscriptions) => {
  const hasSameIDasTargetChannelID = ({channelId}) => channelId === targetChannelID
  return userSubscriptions.some(hasSameIDasTargetChannelID)
}

export const isUserSubscribedToChannelTag = ({ id: channelId }, { id: tagId }, userSubscriptions) => {
  const isSubscribedToTag = (subscription) => (
    subscription.channelId === channelId
      && !subscription.unsubTagIds.includes(tagId)
      && (subscription.type === SUBSCRIPTION_ALL || subscription.subTagIds.includes(tagId))
  )

  return userSubscriptions.some(isSubscribedToTag)
}
