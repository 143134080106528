import sanitizeHtml from 'sanitize-html'
import slugify from 'slugify'

export function getEphyUrl(ephy) {
  let slug = ephy.text ?
    sanitizeHtml(ephy.text, {
      allowedTags: [],
      allowedAttributes: {}
    }).substring(0, 48) :
    ''

  slug = slug ? slugify(slug, { strict: true, lower: true }) + '-' : ''

  return `/${ephy.channel ? ephy.channel.slug : 'drafts'}/p/${slug}${ephy.id}`
}

export function sortEphies(ephies, { ignorePinned = false } = {}) {
  const pinnedWeightCoef = ignorePinned ? 0 : 10000000000

  return ephies.sort(
    (ephy1, ephy2) => (
      +ephy2.pinned * pinnedWeightCoef - +ephy1.pinned * pinnedWeightCoef +
      ephy2.createdAt.seconds - ephy1.createdAt.seconds
    )
  )
}

export function getCachableChannelData(channel) {
  const channelData = {}
  if (channel) {
    ['id', 'name', 'slug', 'private', 'photoUrl', 'admins'].forEach(attr => {
      channelData[attr] = channel[attr]
    })
  }

  return channelData
}
