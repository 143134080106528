import formStyles from 'modules/auth/Form.module.scss'
// import styles from './ChannelInvitationSettings.module.scss'

import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import copyToClipboard from 'clipboard-copy'
import { toast } from 'react-toastify'

import Button from 'components/Button'
import { regenerateInviteToken } from 'modules/channel/channelActions'

export function ChannelInvitationSettings({ channel }) {
  const dispatch = useDispatch()
  const channelFetchInProgress = useSelector(({ channel }) => channel.channelFetchInProgress)

  const inviteLink = useMemo(() => {
    return new URL(`/join_channel/${channel.inviteToken}`, document.location).href
  }, [channel.inviteToken])

  const generateNewInviteToken = (e) => {
    e.preventDefault()

    dispatch(regenerateInviteToken(channel.id)).then(() => (
      toast.success('Invitation link regenerated')
    ))
  }

  return (
    <section>
      <form className={formStyles.form}>
        <p>Invite new users to the channel by sharing the following link</p>

        <div className={formStyles.formControl}>
          <label>Invitation link (click to copy)</label>
          <input
            type="text"
            name="name"
            className={formStyles.input}
            value={inviteLink}
            readOnly
            onClick={() => {
              copyToClipboard(inviteLink).then(() => {
                toast.success('Invitation link copied to clipboard')
              })
            }}
          />
        </div>

        <Button
          onClick={generateNewInviteToken}
          kind="primary"
          isBlocky={true}
          disabled={channelFetchInProgress}
        >
          Regenerate invitation link
        </Button>
      </form>
    </section>
  )
}
