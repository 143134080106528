import styles from './Modal.module.scss'
import {ReactComponent as CloseIcon} from 'icons/Close.svg'

import { useBreakpoints, useOnClickOutsideCallback, useLockBodyScroll } from 'hooks'
import { Overlay } from 'components/Overlay'
import Button from 'components/Button'

export default function Modal({backdrop = true, children, onClose}) {
  const { isDesktop } = useBreakpoints()
  const dialogContainerRef = useOnClickOutsideCallback(() => {
    if (isDesktop) onClose()
  })
  useLockBodyScroll()
  return (
    <Overlay>
      <div className={styles.container}>
        {backdrop && <div className={styles.backdrop} />}
        <div ref={dialogContainerRef} className={styles.modal}>
          <Button
            className={styles.close}
            kind="iconDark"
            size="40"
            onClick={onClose}
          >
            <CloseIcon />
          </Button>
          {children}
        </div>
      </div>
    </Overlay>
  )
}
