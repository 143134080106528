import PrivateEphiesView from './PrivateEphiesView'
import SettingsView from './SettingsView'
import userReducer from './userReducer'

export {
  PrivateEphiesView,
  SettingsView,
  userReducer,
}

export default SettingsView
