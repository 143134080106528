import {
  USERS_ACKNOWLEDGE_HINT_START,
  USERS_ACKNOWLEDGE_HINT_SUCCESS,
  USERS_ACKNOWLEDGE_HINT_ERROR,
} from '../userConstants'

/**
 *
 * @param {String} hintName Available hints: invite_co_host, create_topic, create_ephy
 * @returns
 */
export function acknowledgeHint(hintName) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: USERS_ACKNOWLEDGE_HINT_START })

    const profile = getState().firebase.profile

    if (!profile.hints) {
      profile.hints = {}
    }

    profile.hints[hintName] = true

    getFirebase()
      .updateProfile(profile)
      .then(() => {
        dispatch({ type: USERS_ACKNOWLEDGE_HINT_SUCCESS })
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: USERS_ACKNOWLEDGE_HINT_ERROR,
        })
      })
  }
}
