import styles from './EphyImpressions.module.scss'
// import { ReactComponent as ImpressionsIcon } from 'icons/Star.svg'

import React from 'react'

export const EphyImpressions = ({count = 0}) => (
  <div className={styles.container}>
    {/* <ImpressionsIcon /> */}
    <span className={styles.impressionsNumber}>{count}</span>
    {!Number.isFinite(count) || count > 1 ? 'views' : 'view'}
  </div>
 )
