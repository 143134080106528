import styles from './Slider.module.scss'

import React, { useState, createContext, useContext, useRef } from 'react'
import { useTransition, animated } from '@react-spring/web'

import { cls } from 'utils'

const SliderContext = createContext({
  slidesCount: 0,
  currentSlide: 0,
  setCurrentSlide: () => {},
})

export function Slider({children}) {
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const [context, setContext] = useState({
    slidesCount: 3, // TODO: children should register its slides count
    currentSlide: 0,
    setCurrentSlide: (i) => setContext(prevContext => ({...prevContext, currentSlide: i}))
  })

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX)
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const next = d => (context.currentSlide + d + context.slidesCount) % context.slidesCount

  function handleTouchEnd() {
    if (touchStart - touchEnd > 100) {
      setContext(prevContext => ({
        ...prevContext,
        currentSlide: next(1)
      }))
    }

    if (touchStart - touchEnd < -100) {
      setContext(prevContext => ({
        ...prevContext,
        currentSlide: next(-1)
      }))
    }
  }

  function handleClick() {
    setContext(prevContext => ({
      ...prevContext,
      currentSlide: next(1)
    }))
  }

  return (
    <SliderContext.Provider value={context}>
      <div
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {children}
      </div>
    </SliderContext.Provider>
  )
}
Slider.Nav = Nav
Slider.Slides = Slides

function Nav({children, activeClass, ...restProps}) {
  const sliderContext = useContext(SliderContext)

  const indicator = React.Children.only(children)

  return (
    <div {...restProps}>
      {[...Array(sliderContext.slidesCount)].map((_, idx) => {
        return React.cloneElement(indicator, {
          key: idx,
          className: cls`
            ${indicator.props.className}
            ${idx === sliderContext.currentSlide && activeClass}
          `,
          onClick: (e) => {e.stopPropagation(); sliderContext.setCurrentSlide(idx)}
        })
      })}
    </div>
  )
}

function Slides({children, className, animation = 'fade', ...restProps}) {
  const prevSlide = useRef()
  const sliderContext = useContext(SliderContext)

  const slides = React.Children.toArray(children)

  const currentSlide = slides[sliderContext.currentSlide]
  prevSlide.current = {
    idx: sliderContext.currentSlide,
    slide: currentSlide
  }

  const animations = {
    slide: {
      keys: null,
      from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
      enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    },
    fade: {
      keys: null,
      from: { opacity: 0, },
      enter: { opacity: 1, },
      leave: { opacity: 0, },
      config: { duration: 400}
    }
  }

  const transitions = useTransition(sliderContext.currentSlide, animations[animation])

  return (
    <div className={`${styles.container} ${className}`}>
      {transitions((style, i) => (
        <animated.div style={{...style, height: '100%'}}>
          {children[i]}
        </animated.div>
      ))}
    </div>
  )
}
