import styles from './TagList.module.scss'
import pillStyles from 'components/Pill/Pill.module.scss'
import { ReactComponent as HamburgerIcon } from 'icons/Hamburger.svg'
import { ReactComponent as CloseIcon } from 'icons/Close.svg'

import React, {useRef, useMemo, useCallback} from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import {TagCreate} from '../TagCreate/TagCreate'
import { EditableTagLink, NonEditableTagLink } from '../TagListItem'
import {
  useBreakpoints,
  // useOnClickOutsideCallback,
  useOverlay,
  useScroll
} from 'hooks'
import { reorder } from 'modules/tag/tagActions'
import Button from 'components/Button'

export const listWithClass = type => WrappedComponent => {
  const ListWithClass = ({items, ...restProps}) => (
    <ul className={type}>
      {items.map((item, index) => (
        <WrappedComponent {...restProps} key={item.id} index={index} item={item} />
      ))}
    </ul>
  )

  return ListWithClass
}

// TODO extract this generic function somewhere
export const injectProps = injectedProps => WrappedComponent => {
  const InjectProps = (props) => <WrappedComponent {...injectedProps} {...props} />
  return InjectProps
}

function getEditableTagList(showActionsButton, isOverlay, isDesktop) {
  const SortablePillList = compose(
    SortableContainer,
    listWithClass((isOverlay || isDesktop) ? pillStyles.verticalList : pillStyles.horizontalList),
    SortableElement,
    injectProps({showActionsButton, pillStyle: isOverlay ? 'dark' : 'light', isOverlay})
  )
  return SortablePillList(EditableTagLink)
}

function getNonEditableTagList(isOverlay, isDesktop) {
  const showActionsButton = isDesktop || isOverlay
  const PillList = compose(
    listWithClass((isOverlay || isDesktop) ? pillStyles.verticalList : pillStyles.horizontalList),
    injectProps({showActionsButton, pillStyle: isOverlay ? 'dark' : 'light', isOverlay})
  )
  return PillList(NonEditableTagLink)
}

/* const hocPill = WrappedComponent =>
  (props) => {
    const pillInterface = props
    const pillDependenciesInterface = {item: props.item}
    return <Pill {...pillInterface} children={<WrappedComponent {...pillDependenciesInterface} />} />
  }

function initSimpleTagList() {
  const SimpleTagList = compose(
    listWithClass('list'),
    injectProps({size: 'small'}),
    hocPill
  )
  const TagName = ({item}) => <>{item.name}</>
  return SimpleTagList(TagName)
} */

const ChannelTagList = ({ channel, tags, activeTag, editable = false }) => {
  const [isOverlay, setIsOverlay, OverlayOrInFlow] = useOverlay()

  const onChange = useCallback(() => setIsOverlay(false), [setIsOverlay])
  const {isMobile, isDesktop } = useBreakpoints({onChange})

  const overlayRef = useRef()
  // const overlayRef = useOnClickOutsideCallback(() => setIsOverlay(false))

  const {scrollY, scrollDirection} = useScroll()

  const dispatch = useDispatch()

  const subscriptions = useSelector(state => state.feed.subscriptions)
  const isTagsFetchInProgress = useSelector(state => state.channel.tagsFetchInProgress)

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    dispatch(reorder(arrayMove(tags, oldIndex, newIndex), {channel}))
  }, [dispatch, channel, tags])

  const tagsProps = useMemo(
    () => ({
      items: tags,
      channel,
      activeTag,
      subscriptions,
      onTagClick: () => setIsOverlay(false)
    }),
    [ tags, channel, activeTag, subscriptions, setIsOverlay ]
  )

  const sortableListProps = useMemo(
    () => ({
      axis: "y",
      distance: 10,
      onSortEnd: onSortEnd,
      updateBeforeSortStart: (node) => { node.node.style.zIndex = 1 }
      // shouldCancelStart= () => !editable
    }),
    [onSortEnd]
  )
  // let specificTagListProps = tagsProps
  const [SpecificTagList, specificTagListProps = tagsProps] = useMemo(
    () => {
      let SpecificTagList, specificTagListProps
      if (editable & (isOverlay || isDesktop)) {
        SpecificTagList = getEditableTagList(true, isOverlay, isDesktop)
        specificTagListProps = {...tagsProps, ...sortableListProps}
      } else {
        // SpecificTagList = initSimpleTagList()
        SpecificTagList = getNonEditableTagList(isOverlay, isDesktop)
      }
      return [React.memo(SpecificTagList), specificTagListProps]
    },
    [editable, isDesktop, isOverlay, tagsProps, sortableListProps]
    // [editable, isDesktop, isOverlay]
  )

  return (
    <OverlayOrInFlow>
      {!isTagsFetchInProgress &&
      <div ref={overlayRef} className={isOverlay ? styles.overlay : styles.inFlow}>
        {isMobile &&
          <div className={styles.header}>
            <Button
              id="topic_menu"
              kind={isOverlay ? 'iconDark' : 'icon'}
              size="38"
              onClick={() => setIsOverlay(!isOverlay)}
            >
              {isOverlay ? <CloseIcon /> : <HamburgerIcon /> }
            </Button>
          </div>
        }
        <h1>Topics</h1>
        <div style={{display: isDesktop && scrollY > 0 && scrollDirection === 'up' ? 'none' : 'block'}}>
        {/* <div> */}
          <SpecificTagList {...specificTagListProps} />
          {(isDesktop || isOverlay) && editable &&
            <TagCreate
              id="create_topic"
              channelId={channel?.id}
              isVerbose={!tags.length}
              pillStyle={isOverlay ? "dark" : "light"}
            />
          }
        </div>
      </div>
      }
    </OverlayOrInFlow>
  )
}
/*
TagList.propTypes = {
  source: PropTypes.object,
  tags: PropTypes.array,
  activeTag: PropTypes.string,
  editable: PropTypes.bool,
  onReorder: PropTypes.func,
} */

export default ChannelTagList
