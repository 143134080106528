import { ReactComponent as LockIcon } from 'icons/Lock.svg'
import { ReactComponent as DraftsIcon } from 'icons/Drafts.svg'
import { ReactComponent as CreateChannelIcon } from 'icons/Plus.svg'

import React from 'react'
import { Link } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Menu from 'components/Menu'
import Avatar from 'components/Avatar'
import IconStyle from 'components/IconStyle'

const UserAuthenticatedMenu = () => {
  const firebase = useFirebase()
  const channels = useSelector(state => state.user.channels)
  const logout = () => {
    firebase.logout()
    localStorage.removeItem('theme')
    document.documentElement.className = "light-theme"
    toast.success('Logged out')
  }
  const menuWidth = 228

  return (
    <>
      <Menu variety="userMenuBold" {...{menuWidth}}>
        {channels.map(channel => (
          <Menu.Item
            isHighlighted={channel.private}
            title={channel.private ? 'Private channel' : ''}
            key={channel.id}
            icon={<Avatar
              photoUrl={channel.photoUrl}
              linkTo={`/${channel.slug}`}
              name={channel.name}
              size={28} />}
            >
            {channel.name}
            {channel.private && <LockIcon />}
          </Menu.Item>
        ))}
        <Menu.Item icon={<IconStyle background={true} size="28"><DraftsIcon /></IconStyle>}><Link to="/drafts">Pocket</Link></Menu.Item>
      </Menu>
      <Menu.Divider></Menu.Divider>
      <Menu variety="userMenuBold" {...{menuWidth}}>
        <Menu.Item icon={<CreateChannelIcon />} iconPosition="right"><Link to="/welcome/create_channel">Create channel</Link></Menu.Item>
      </Menu>
      <Menu variety="userMenu" {...{menuWidth}}>
        <Menu.Item><Link to="/discover">Discover</Link></Menu.Item>
      </Menu>
      <Menu.Divider></Menu.Divider>
      <Menu variety="userMenu" {...{menuWidth}}>
        <Menu.Item><Link to="/help">Help</Link></Menu.Item>
        <Menu.Item><Link to="/settings">Settings</Link></Menu.Item>
        <Menu.Item><Link to="#" onClick={logout}>Logout</Link></Menu.Item>
      </Menu>
    </>
  )
}

export default UserAuthenticatedMenu
