import React, {useContext} from 'react'

import NoContentMessage from 'components/NoContentMessage'
import { Link } from 'react-router-dom'

import TypebarDispatch from 'modules/ephy/components/Typebar/typebarContext'

export const OwnChannelNoContent = React.memo(() => {
  const typebarDispatch = useContext(TypebarDispatch)
  const title = "Nothing is here yet. Make your first step"
  const subtitle = (
    <span>
      <Link to="/settings?section=import">Import</Link> your things or&nbsp;
      <Link
        to="#"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          window.requestAnimationFrame(() => typebarDispatch({type: 'ephy/clickedNew'}))
        }}
      >
        add anything you like
          </Link> with a typebar
    </span>
  )
  return (
    <NoContentMessage
      title={title}
      subtitle={subtitle}
    />
  )
})
