import {
  EPHIES_COMMENT_LIMIT,
  EPHIES_FETCH_COMMENTS_START,
  EPHIES_FETCH_COMMENTS_SUCCESS,
  EPHIES_FETCH_COMMENTS_ERROR,
} from '../ephyConstants'

import isCallable from 'is-callable'
import { createRecordsLoader } from 'utils/infiniteScroll'

/**
 * @param {String} channelId
 * @param {String} ephyId
 * @returns { unsubscribe, loadMore }
 */
export function subscribeToComments({ channelId, ephyId }) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_FETCH_COMMENTS_START })

    const createQuery = ({ startAfter, endBefore } = {}) => {
      let query = getFirebase()
        .firestore()
        .collection('comments')
        .where('channelId', '==', channelId)
        .where('ephyId', '==', ephyId)
        // TODO rewrite this to ASC sorting
        // to retrieve old comments first
        .orderBy('createdAt', 'desc')
        .limit(EPHIES_COMMENT_LIMIT)

      if (startAfter) {
        query = query.startAfter(startAfter)
      }

      if (endBefore) {
        query = query.endBefore(endBefore)
      }

      return query
    }

    const createSnapshotProcessor = (callback) => {
      let callbackFired = false

      return (commentsSnapshot) => {
        const comments = []

        commentsSnapshot.forEach(commentSnapshot => {
          comments.push(Object.assign({ id: commentSnapshot.id }, commentSnapshot.data()))
        })

        if (!callbackFired) {
          if (isCallable(callback)) {
            callback(commentsSnapshot)
          }

          callbackFired = true
        }

        dispatch({
          type: EPHIES_FETCH_COMMENTS_SUCCESS,
          payload: {
            ephyId,
            data: comments,
          }
        })
      }
    }

    const { load, loadMore, unsubscribe } = createRecordsLoader({
      createQuery,
      dispatch,
      limit: EPHIES_COMMENT_LIMIT,
      successAction: EPHIES_FETCH_COMMENTS_SUCCESS,
      errorAction: EPHIES_FETCH_COMMENTS_ERROR,
      snapshotProcessor: createSnapshotProcessor,
    })

    load()

    return {
      unsubscribe,
      loadMore
    }
  }
}
