import {
  CHANNELS_UPDATE_CHANNEL_START,
  CHANNELS_UPDATE_CHANNEL_SUCCESS,
} from '../channelConstants'

import { uniqueid } from 'utils'

export function regenerateInviteToken(channelId) {
  return (dispatch, _getState, getFirebase) => {
    dispatch({ type: CHANNELS_UPDATE_CHANNEL_START })

    return getFirebase()
      .firestore()
      .collection('channels')
      .doc(channelId)
      .update({
        inviteToken: uniqueid({ length: 64 })
      })
      .then(() => {
        dispatch({
          type: CHANNELS_UPDATE_CHANNEL_SUCCESS,
        })
      })
  }
}
