import { getFirebase } from 'react-redux-firebase'
import isCallable from 'is-callable'

import { bumpImpressionsCount } from 'modules/ephy/ephyActions'

export const SNAPSHOT_PROCESSOR_DEFAULT = 'default'
export const SNAPSHOT_PROCESSOR_EPHY = 'ephy'
export const SNAPSHOT_PROCESSOR_FEED = 'feed'

export const createRecordsLoader = ({
  createQuery,
  dispatch,
  limit,
  successAction,
  errorAction,
  snapshotProcessor = SNAPSHOT_PROCESSOR_DEFAULT,
  bumpEphyImpressions = false,
}) => {
  const listeners = []

  const createDefaultSnapshotProcessor = (callback) => {
    let callbackFired = false

    return (querySnapshot) => {
      const items = []

      querySnapshot.forEach(itemSnapshot => {
        items.push(Object.assign({ id: itemSnapshot.id }, itemSnapshot.data()))
      })

      if (!callbackFired) {
        if (isCallable(callback)) {
          callback(querySnapshot)
        }

        callbackFired = true
      }

      dispatch({
        type: successAction,
        payload: {
          data: items
        }
      })
    }
  }

  const createEphySnapshotProcessor = (callback) => {
    let callbackFired = false

    return (querySnapshot) => {
      const ephiesList = []

      querySnapshot.forEach(ephy => {
        ephiesList.push(Object.assign({ id: ephy.id }, ephy.data()))
      })

      if (!callbackFired) {
        if (isCallable(callback)) {
          callback(querySnapshot)
        }

        if (bumpEphyImpressions && ephiesList.length > 0) {
          dispatch(bumpImpressionsCount(ephiesList))
        }

        callbackFired = true
      }

      dispatch({
        type: successAction,
        payload: {
          data: ephiesList
        }
      })
    }
  }

  const createFeedSnapshotProcessor = (callback) => {
    let callbackFired = false

    return (querySnapshot) => {
      const ephiesPromises = []

      querySnapshot.forEach(feedItem => {
        ephiesPromises.push(
          getFirebase()
            .firestore()
            .doc(`channels/${feedItem.data().channelId}/ephies/${feedItem.data().ephyId}`)
            .get()
            .then(ephy => {
              if (!ephy.exists) {
                return false
              }

              return {
                id: ephy.id,
                ...ephy.data()
              }
            })
            .catch(error => {
              console.warn(error)
              return false
            })
        )
      })

      Promise
        .all(ephiesPromises)
        .then(ephiesUnfiltered => {
          const ephiesList = ephiesUnfiltered.filter(ephy => ephy !== false)

          if (!callbackFired) {
            if (isCallable(callback)) {
              callback(querySnapshot)
            }

            if (bumpEphyImpressions && ephiesList.length > 0) {
              dispatch(bumpImpressionsCount(ephiesList))
            }

            callbackFired = true
          }

          dispatch({
            type: successAction,
            payload: {
              data: ephiesList
            }
          })
        })
    }
  }

  let createSnapshotProcessor
  if (isCallable(snapshotProcessor)) {
    createSnapshotProcessor = snapshotProcessor
  } else {
    switch (snapshotProcessor) {
      case SNAPSHOT_PROCESSOR_EPHY:
        createSnapshotProcessor = createEphySnapshotProcessor
        break

      case SNAPSHOT_PROCESSOR_FEED:
        createSnapshotProcessor = createFeedSnapshotProcessor
        break

      default:
        createSnapshotProcessor = createDefaultSnapshotProcessor
        break
    }
  }

  const processSnapshotError = (error) => {
    console.error(error)

    dispatch({
      type: errorAction,
      payload: {
        error
      }
    })
  }

  let lastRecordPromise = Promise.resolve(false)

  return {
    load: () => {
      lastRecordPromise = new Promise((resolve) => {
        // first N records
        createQuery().get().then(createSnapshotProcessor((querySnapshot) => {
          if (!querySnapshot.empty) {
            // new records (up to N records, end before the last ephy retrieved) - listener
            listeners.push(
              createQuery({ endBefore: querySnapshot.docs[0] })
                .onSnapshot(createSnapshotProcessor(), processSnapshotError)
            )

            if (querySnapshot.docs.length === limit) {
              resolve(querySnapshot.docs[querySnapshot.docs.length - 1])
            } else {
              resolve(false)
            }
          } else {
            // new records (up to N new records) - listener
            listeners.push(
              createQuery()
                .onSnapshot(createSnapshotProcessor(), processSnapshotError)
            )

            resolve(false)
          }
        }), processSnapshotError)
      })

      return lastRecordPromise
    },
    loadMore: async () => {
      const lastEphy = await lastRecordPromise
      lastRecordPromise = new Promise((resolve) => {
        if (lastEphy) {
          // N more records
          createQuery({ startAfter: lastEphy })
            .get()
            .then(createSnapshotProcessor((querySnapshot) => {
              if (querySnapshot.docs.length === limit) {
                resolve(querySnapshot.docs[querySnapshot.docs.length - 1])
              } else {
                resolve(false)
              }
            }), processSnapshotError)
        } else {
          resolve(false)
        }
      })

      return lastRecordPromise
    },
    unsubscribe: () => {
      listeners.forEach(listener => listener())
    },
  }
}
