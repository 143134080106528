import Hint from 'components/Hint'
import { useState } from 'react'

export function ProductTour({
  hints,
  hintsSeenStatus,
  onSkipAllClick,
  onNextClick,
  onHintTargetNotFound,
  HintTemplateComponent,
}) {
  const [unseenHintsAtStart, setUnseenHintsAtStart] = useState(null)
  const [currentIdx, setCurrentIdx] = useState(0)

  if (!unseenHintsAtStart) {
    const isUnseen = hint => {
      return !(
        hintsSeenStatus?.hasOwnProperty(hint.name) &&
        hintsSeenStatus[hint.name]
      )
    }
    const unseenHints = hints.filter(isUnseen)

    setUnseenHintsAtStart(unseenHints)
  }

  if (!unseenHintsAtStart || currentIdx > unseenHintsAtStart.length - 1) return null

  const currentHint = unseenHintsAtStart[currentIdx]

  const {
    selectors,
    placement,
    content,
  } = currentHint

  return (
    <Hint
      selectors={selectors}
      placement={placement}
      onSelectorNotFound={() => {
        setCurrentIdx(prevState => ++prevState)
        onHintTargetNotFound(currentHint)
      }}
    >
      <HintTemplateComponent
        content={content}
        currentStep={currentIdx + 1}
        totalSteps={unseenHintsAtStart.length}
        onSkipAllClick={() => {
          setCurrentIdx(unseenHintsAtStart.length)
          onSkipAllClick(unseenHintsAtStart.slice(currentIdx))
        }}
        onNextClick={() => {
          setCurrentIdx(prevState => ++prevState)
          onNextClick(currentHint)
        }}
      />
    </Hint>
  )
}
