import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFirebase } from 'react-redux-firebase'
import { toast } from 'react-toastify'
import classnames from 'classnames'

import Button from 'components/Button'
import * as userActions from 'modules/user/userActions'
import { linkState } from 'utils'

import formStyles from 'modules/auth/Form.module.scss'

class ChangePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPassword: null,
      newPassword: null,
      newPasswordRepeat: null,
      showPassword: false,
    }

    this.firebase = getFirebase()
  }

  updatePassword(event) {
    event.preventDefault()

    this.props.userActions.updatePassword({
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
    }, () => {
      this.setState({
        currentPassword: null,
        newPassword: null,
        newPasswordRepeat: null
      })

      toast.success('Password updated')
    })
  }

  validatePasswordForm() {
    const newPasswordIsValid = this.state.newPassword === null ||
      (typeof this.state.newPassword === 'string' && this.state.newPassword.trim().length > 5)

    const newPasswordRepeatIsValid = this.state.newPasswordRepeat === null ||
      this.state.newPassword === this.state.newPasswordRepeat

    return {
      newPassword: {
        valid: newPasswordIsValid,
        initial: this.state.newPassword === null,
        errors: [] // TODO show extended error message
      },
      newPasswordRepeat: {
        valid: newPasswordRepeatIsValid,
        initial: this.state.newPasswordRepeat === null,
        errors: []
      },
    }
  }

  showPassword(e) {
    e.preventDefault()
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    const passwordFormValidation = this.validatePasswordForm()

      return (
        <>
          <h1>Change password</h1>
          <form className={formStyles.form}>
            <div className={formStyles.formControl}>
              <label>Current password</label>
              <input
                type={ this.state.showPassword ? 'text' : 'password'}
                name="current-password"
                autoComplete="current-password"
                className={formStyles.input}
                value={this.state.currentPassword || ''}
                onChange={linkState(this, 'currentPassword')}
                // onChange={(e) => this.setState({ newPassword: e.target.value, showErrorMessage: false })}
              />
            </div>
            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: !passwordFormValidation.newPassword.valid})}>
              <label>New password</label>
              <input
                type={ this.state.showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                name="new-password"
                className={formStyles.input}
                value={this.state.newPassword || ''}
                // onChange={(e) => this.setState({ newPasswordRepeat: e.target.value, showErrorMessage: false })}
                onChange={linkState(this, 'newPassword')}
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Should be 6 or more characters long
                </small>
                <small>
                  <Button kind="text"
                    onClick={this.showPassword.bind(this)}>
                    { this.state.showPassword ? 'Hide' : 'Show' }
                  </Button>
                </small>
              </div>
            </div>
            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: !passwordFormValidation.newPasswordRepeat.valid})}>
              <label>Repeat your new password</label>
              <input
                type={ this.state.showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                name="repeat-new-password"
                className={formStyles.input}
                value={this.state.newPasswordRepeat || ''}
                // onChange={(e) => this.setState({ newPasswordRepeat: e.target.value, showErrorMessage: false })}
                onChange={linkState(this, 'newPasswordRepeat')}
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Passwords don't match
                </small>
              </div>
            </div>
            <Button
              kind="primary"
              isBlocky={true}
              onClick={this.updatePassword.bind(this)}
              disabled={this.props.fetchInProgress}
            >
              Update password
            </Button>
          </form>
        </>
      )
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  layout: state.layout,
  fetchInProgress: state.user.fetchInProgress,
})

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
