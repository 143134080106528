export const CHANNELS_EPHY_LIMIT                   = 15
export const CHANNELS_NEW_CHANNEL                  = 'CHANNELS_NEW_CHANNEL'
export const CHANNELS_REPLACE_CHANNEL              = 'CHANNELS_REPLACE_CHANNEL'
export const CHANNELS_REMOVE_CHANNEL               = 'CHANNELS_REMOVE_CHANNEL'
export const CHANNELS_ENABLE_EDIT_FORM             = 'CHANNELS_ENABLE_EDIT_FORM'
export const CHANNELS_DISABLE_EDIT_FORM            = 'CHANNELS_DISABLE_EDIT_FORM'
export const CHANNELS_FETCH_CHANNEL_START          = 'CHANNELS_FETCH_CHANNEL_START'
export const CHANNELS_FETCH_CHANNEL_SUCCESS        = 'CHANNELS_FETCH_CHANNEL_SUCCESS'
export const CHANNELS_FETCH_CHANNEL_ERROR          = 'CHANNELS_FETCH_CHANNEL_ERROR'
export const CHANNELS_CREATE_CHANNEL_START         = 'CHANNELS_CREATE_CHANNEL_START'
export const CHANNELS_CREATE_CHANNEL_SUCCESS       = 'CHANNELS_CREATE_CHANNEL_SUCCESS'
export const CHANNELS_CREATE_CHANNEL_ERROR         = 'CHANNELS_CREATE_CHANNEL_ERROR'
export const CHANNELS_UPDATE_CHANNEL_START         = 'CHANNELS_UPDATE_CHANNEL_START'
export const CHANNELS_UPDATE_CHANNEL_SUCCESS       = 'CHANNELS_UPDATE_CHANNEL_SUCCESS'
export const CHANNELS_UPDATE_CHANNEL_ERROR         = 'CHANNELS_UPDATE_CHANNEL_ERROR'
export const CHANNELS_DELETE_CHANNEL_START         = 'CHANNELS_DELETE_CHANNEL_START'
export const CHANNELS_DELETE_CHANNEL_SUCCESS       = 'CHANNELS_DELETE_CHANNEL_SUCCESS'
export const CHANNELS_DELETE_CHANNEL_ERROR         = 'CHANNELS_DELETE_CHANNEL_ERROR'
export const CHANNELS_FETCH_EPHIES_START           = 'CHANNELS_FETCH_EPHIES_START'
export const CHANNELS_FETCH_EPHIES_SUCCESS         = 'CHANNELS_FETCH_EPHIES_SUCCESS'
export const CHANNELS_FETCH_EPHIES_ERROR           = 'CHANNELS_FETCH_EPHIES_ERROR'
export const CHANNELS_FETCH_TAGS_START             = 'CHANNELS_FETCH_TAGS_START'
export const CHANNELS_FETCH_TAGS_SUCCESS           = 'CHANNELS_FETCH_TAGS_SUCCESS'
export const CHANNELS_FETCH_TAGS_ERROR             = 'CHANNELS_FETCH_TAGS_ERROR'
export const CHANNELS_SEARCH_START                 = 'CHANNELS_SEARCH_START'
export const CHANNELS_SEARCH_SUCCESS               = 'CHANNELS_SEARCH_SUCCESS'
export const CHANNELS_SEARCH_ERROR                 = 'CHANNELS_SEARCH_ERROR'
export const CHANNELS_UPLOAD_CHANNEL_PHOTO_START   = 'CHANNELS_UPLOAD_CHANNEL_PHOTO_START'
export const CHANNELS_UPLOAD_CHANNEL_PHOTO_SUCCESS = 'CHANNELS_UPLOAD_CHANNEL_PHOTO_SUCCESS'
export const CHANNELS_UPLOAD_CHANNEL_PHOTO_ERROR   = 'CHANNELS_UPLOAD_CHANNEL_PHOTO_ERROR'
export const CHANNELS_JOIN_PRIVATE_CHANNEL_START   = 'CHANNELS_JOIN_PRIVATE_CHANNEL_START'
export const CHANNELS_JOIN_PRIVATE_CHANNEL_SUCCESS = 'CHANNELS_JOIN_PRIVATE_CHANNEL_SUCCESS'
export const CHANNELS_JOIN_PRIVATE_CHANNEL_ERROR   = 'CHANNELS_JOIN_PRIVATE_CHANNEL_ERROR'
