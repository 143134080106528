import {
  USERS_FETCH_PUBLIC_USER_PROFILES_START,
  USERS_FETCH_PUBLIC_USER_PROFILES_SUCCESS,
  USERS_FETCH_PUBLIC_USER_PROFILES_ERROR,
} from '../userConstants'

const _fetchPublicUserProfiles = (firebase, userIds) => {
  const userIds10ItemsPerChunk = []
  // breaking userIds into 10 items per chunk
  // because Firestore doesn't support "in" clause
  // with more than 10 items
  for (let i = 0, n = userIds.length; i < n; i += 10) {
    userIds10ItemsPerChunk.push(userIds.slice(i, i + 10))
  }

  return Promise.all(
    userIds10ItemsPerChunk.map(uIds => (
      firebase
        .firestore()
        .collection('users_public')
        .where(firebase.firestore.FieldPath.documentId(), 'in', uIds)
        .get()
        .then(usersSnapshot => {
          return usersSnapshot.docs.map(userProfile => ({ id: userProfile.id, ...userProfile.data() }))
      })
    ))
  ).then(profiles10ItemsPerChunk => (
    profiles10ItemsPerChunk.reduce((allProfiles, profiles) => [...allProfiles, ...profiles], [])
  ))
}

export function fetchPublicUserProfiles(userIds) {
  return (dispatch, _getState, getFirebase) => {
    dispatch({ type: USERS_FETCH_PUBLIC_USER_PROFILES_START })

    return new Promise((resolve, reject) => {
      if (!userIds || userIds.length === 0) {
        resolve([])

        return dispatch({
          type: USERS_FETCH_PUBLIC_USER_PROFILES_SUCCESS,
          payload: {
            data: []
          }
        })
      }

      try {
        _fetchPublicUserProfiles(getFirebase(), userIds)
          .then(profiles => {
            resolve(profiles)

            dispatch({
              type: USERS_FETCH_PUBLIC_USER_PROFILES_SUCCESS,
              payload: {
                data: profiles
              }
            })
          })
        } catch (error) {
          console.error(error)

          reject(error)

          dispatch({
            type: USERS_FETCH_PUBLIC_USER_PROFILES_ERROR,
            payload: {
              error
            }
          })
        }
    })
  }
}
