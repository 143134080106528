import styles from './ChannelUsers.module.scss'

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { Link } from 'react-router-dom'

import UserList from 'modules/channel/components/UserList'
// import { featureUsed } from 'utils'
import { fetchPublicUserProfiles } from 'modules/user/userActions'
import { addAdmin, removeMember } from 'modules/channel/channelActions'

export function ChannelMembers({ channel }) {
  const dispatch = useDispatch()
  const currentUserId = useSelector(state => state.firebase.auth.uid)
  const publicUserProfiles = useSelector(state => state.user.publicUserProfiles)

  useEffect(() => {
    dispatch(fetchPublicUserProfiles(channel.members))
  }, [dispatch, channel.members])

  const onMemberPromote = (memberId) => {
    dispatch(addAdmin(channel.id, memberId))
  }
  const onMemberRemove = (memberId) => {
    dispatch(removeMember(channel.id, memberId))
  }
  // const [users, setUsers] = useState([])
/*   useEffect(() => {
    (async () => {
      const response = await fetchPublicUserProfiles(membersIds)
      response.data && setUsers(response.data)
    })()
  }, membersIds) */

  return (
    <section className={styles.userList}>
      <h2 className={styles.title}>{channel.private ? 'Members' : 'Subscribers'}</h2>

      <UserList
        currentUserId={currentUserId}
        users={publicUserProfiles.filter(profile => channel.members.includes(profile.id))}
        onPromote={onMemberPromote}
        promoteExcludeIds={channel.admins}
        onRemove={channel.private && onMemberRemove}
      />
    </section>
  )
}
