import styles from './EphyComments.module.scss'

import Button from 'components/Button'
import { ReactComponent as CommentsIcon } from 'icons/Comments.svg'
import { Link } from 'react-router-dom'

export default function EphyComments({ count, link }) {
  return (
    <div className={styles.root}>
      {link ? (
          <Button kind="icon" size="27" component={Link} to={link}>
            <CommentsIcon />
          </Button>
        ) : (
          <Button kind="icon" size="27">
            <CommentsIcon />
          </Button>
        )
      }
      {count > 0 && <span className={styles.count}>{count}</span>}
    </div>
  )

}
