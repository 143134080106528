export const USERS_EPHY_LIMIT                             = 15
export const USERS_NOTIFICATION_LIMIT                     = 10
export const USERS_FETCH_USER_START                       = 'USERS_FETCH_USER_START'
export const USERS_FETCH_USER_SUCCESS                     = 'USERS_FETCH_USER_SUCCESS'
export const USERS_UPDATE_USER_PASSWORD_START             = 'USERS_UPDATE_USER_PASSWORD_START'
export const USERS_UPDATE_USER_PASSWORD_SUCCESS           = 'USERS_UPDATE_USER_PASSWORD_SUCCESS'
export const USERS_UPLOAD_USER_PHOTO_START                = 'USERS_UPLOAD_USER_PHOTO_START'
export const USERS_UPLOAD_USER_PHOTO_SUCCESS              = 'USERS_UPLOAD_USER_PHOTO_SUCCESS'
export const USERS_UPLOAD_USER_PHOTO_ERROR                = 'USERS_UPLOAD_USER_PHOTO_ERROR'
export const USERS_FETCH_USER_CHANNELS_START              = 'USERS_FETCH_USER_CHANNELS_START'
export const USERS_FETCH_USER_CHANNELS_SUCCESS            = 'USERS_FETCH_USER_CHANNELS_SUCCESS'
export const USERS_FETCH_USER_CHANNELS_ERROR              = 'USERS_FETCH_USER_CHANNELS_ERROR'
export const USERS_FETCH_PRIVATE_EPHIES_START             = 'USERS_FETCH_PRIVATE_EPHIES_START'
export const USERS_FETCH_PRIVATE_EPHIES_SUCCESS           = 'USERS_FETCH_PRIVATE_EPHIES_SUCCESS'
export const USERS_FETCH_PRIVATE_EPHIES_ERROR             = 'USERS_FETCH_PRIVATE_EPHIES_ERROR'
export const USERS_FETCH_PRIVATE_TAGS_START               = 'USERS_FETCH_PRIVATE_TAGS_START'
export const USERS_FETCH_PRIVATE_TAGS_SUCCESS             = 'USERS_FETCH_PRIVATE_TAGS_SUCCESS'
export const USERS_FETCH_PRIVATE_TAGS_ERROR               = 'USERS_FETCH_PRIVATE_TAGS_ERROR'
export const USERS_FETCH_PUBLIC_USER_PROFILES_START       = 'USERS_FETCH_PUBLIC_USER_PROFILES_START'
export const USERS_FETCH_PUBLIC_USER_PROFILES_SUCCESS     = 'USERS_FETCH_PUBLIC_USER_PROFILES_SUCCESS'
export const USERS_FETCH_PUBLIC_USER_PROFILES_ERROR       = 'USERS_FETCH_PUBLIC_USER_PROFILES_ERROR'
export const USERS_DATA_PROCESSING_START                  = 'USERS_DATA_PROCESSING_START'
export const USERS_DATA_PROCESSING_SUCCESS                = 'USERS_DATA_PROCESSING_SUCCESS'
export const USERS_DATA_PROCESSING_ERROR                  = 'USERS_DATA_PROCESSING_ERROR'
export const USERS_SHOW_EPHY_AUTHOR_NEVER                 = 'never'
export const USERS_SHOW_EPHY_AUTHOR_MULTIPLE_HOSTS        = 'multiple_hosts'
export const USERS_SHOW_EPHY_AUTHOR_ALWAYS                = 'always'
export const USERS_FETCH_NOTIFICATIONS_START              = 'USERS_FETCH_NOTIFICATIONS_START'
export const USERS_FETCH_NOTIFICATIONS_SUCCESS            = 'USERS_FETCH_NOTIFICATIONS_SUCCESS'
export const USERS_FETCH_NOTIFICATIONS_ERROR              = 'USERS_FETCH_NOTIFICATIONS_ERROR'
export const USERS_MARK_ALL_NOTIFICATIONS_AS_READ_START   = 'USERS_MARK_ALL_NOTIFICATIONS_AS_READ_START'
export const USERS_MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS = 'USERS_MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS'
export const USERS_MARK_ALL_NOTIFICATIONS_AS_READ_ERROR   = 'USERS_MARK_ALL_NOTIFICATIONS_AS_READ_ERROR'
export const USERS_FETCH_REACTIONS_START                  = 'USERS_FETCH_REACTIONS_START'
export const USERS_FETCH_REACTIONS_SUCCESS                = 'USERS_FETCH_REACTIONS_SUCCESS'
export const USERS_FETCH_REACTIONS_ERROR                  = 'USERS_FETCH_REACTIONS_ERROR'
export const USERS_FETCH_COMMENT_LIKES_START              = 'USERS_FETCH_COMMENT_LIKES_START'
export const USERS_FETCH_COMMENT_LIKES_SUCCESS            = 'USERS_FETCH_COMMENT_LIKES_SUCCESS'
export const USERS_FETCH_COMMENT_LIKES_ERROR              = 'USERS_FETCH_COMMENT_LIKES_ERROR'
export const USERS_ACKNOWLEDGE_HINT_START                 = 'USERS_ACKNOWLEDGE_HINT_START'
export const USERS_ACKNOWLEDGE_HINT_SUCCESS               = 'USERS_ACKNOWLEDGE_HINT_SUCCESS'
export const USERS_ACKNOWLEDGE_HINT_ERROR                 = 'USERS_ACKNOWLEDGE_HINT_ERROR'

export const USERS_DEFAULT_USER_SETTINGS = {
  showEphyAuthor: USERS_SHOW_EPHY_AUTHOR_MULTIPLE_HOSTS,
  theme: 'default',
}
