import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import * as discoverActions from 'modules/discover/discoverActions'
import * as feedActions from 'modules/feed/feedActions'

export default function useDiscoverChannelList() {
  const dispatch = useDispatch()

  const subscriptions = useSelector(({ feed }) => feed.subscriptions)
  const channels = useSelector(({ discover }) => discover.channels)
  const currentUser = useSelector(state => state.firebase.auth.uid)

  const profile = useSelector(({ firebase }) => firebase.profile)
  const channelsFetchInProgress = useSelector(({ discover }) => discover.fetchInProgress)
  const subscriptionsFetchInProgress = useSelector(({ feed }) => feed.subscriptionsFetchInProgress)
  const [channelsSubscription, setChannelsSubscription] = useState(null)
  const [hasMoreChannels, setHasMoreChannels] = useState(true)

  const [activeChannelId, setActiveChannelId] = useState(null)
  const [selectedChannelsIds, setSelectedChannelsIds] = useState([])

  function handleChannelClick(id) {
    setActiveChannelId(id)
    setSelectedChannelsIds(prevState => {
      const newState = prevState.filter(el => el !== id)
      if (newState.length === prevState.length) newState.push(id)
      return newState
    })
  }

  let channelsToDiscover = []
  if (!channelsFetchInProgress && !subscriptionsFetchInProgress) {
    channelsToDiscover = channels.filter(channel => {
      const alreadySubscribed = subscriptions.find(subscription => subscription.channel.id === channel.id)
      const theOnlyAdmin = channel.admins.length === 1 && channel.admins.some(id => id === currentUser?.id)
      return !(alreadySubscribed || theOnlyAdmin)
    })
  }

  function subscribeToSelected() {
    selectedChannelsIds.forEach(channelId => {
      dispatch(feedActions.subscribeUserToChannel({id: channelId}))
    })
  }

  const loadMoreChannels = useCallback(() => {
    if (channelsSubscription) {
      channelsSubscription
        .loadMore()
        .then(setHasMoreChannels)
    }
  }, [channelsSubscription])

  useEffect(() => {
    setChannelsSubscription(dispatch(discoverActions.fetchChannels()))
  }, [dispatch])

  useEffect(() => { loadMoreChannels() }, [loadMoreChannels])

  return {
    channels: channelsToDiscover,
    activeChannelId, setActiveChannelId,
    selectedChannelsIds, setSelectedChannelsIds,
    subscribeToSelected,
    handleChannelClick,
    hasMoreChannels,
    loadMoreChannels,
    profile, dispatch,
    isLoading: channelsFetchInProgress || subscriptionsFetchInProgress || !profile.isLoaded
  }
}
