import {
  CHANNELS_UPDATE_CHANNEL_START,
  CHANNELS_UPDATE_CHANNEL_SUCCESS,
} from '../channelConstants'

export function removeMember(channelId, userId) {
  return (dispatch, _getState, getFirebase) => {
    dispatch({ type: CHANNELS_UPDATE_CHANNEL_START })

    return getFirebase()
      .firestore()
      .collection('channels')
      .doc(channelId)
      .update({
        members: getFirebase().firestore.FieldValue.arrayRemove(userId)
      })
      .then(() => {
        dispatch({
          type: CHANNELS_UPDATE_CHANNEL_SUCCESS,
        })
      })
  }
}
