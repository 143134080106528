import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isUserSubscribedToChannel, isUserSubscribedToChannelTag } from 'utils/feed'
import * as feedActions from 'modules/feed/feedActions'
import { useSelector } from 'react-redux'
import { push } from 'connected-react-router'

export function useChannelInfo(channel, subscriptions, tags) {
  const dispatch = useDispatch()
  const isAuthenticated = !useSelector(state => state.firebase.auth.isEmpty)
  const isSubscribed = isUserSubscribedToChannel(channel, subscriptions)
  const { subscribeUserToChannel, unsubscribeUserFromChannel, subscribeUserToChannelTag, unsubscribeUserFromChannelTag } = bindActionCreators(feedActions, dispatch)

  const subscribedTags = tags.filter(tag => isUserSubscribedToChannelTag(channel, tag, subscriptions))
  const otherTags = tags.filter(tag => !subscribedTags.find(t => t.id === tag.id))

  const redirectToSignupWithChannelSubscription = () => dispatch(push(`/signup?redirect=${encodeURIComponent('/?subscribe_to_channel=' + channel.id)}`))
  const onSubscribe = isAuthenticated
    ? subscribeUserToChannel.bind(this, channel)
    : redirectToSignupWithChannelSubscription
  const onUnsubscribe = unsubscribeUserFromChannel.bind(this, channel)

  const redirectToSignupWithTagSubscription = (tag) => dispatch(push(`/signup?redirect=${encodeURIComponent('/?subscribe_to_channel=' + channel.id + '&subscribe_to_tag=' + tag.id)}`))
  const onSubscribeToTag = tag => {
    return isAuthenticated
      ? subscribeUserToChannelTag(channel, tag)
      : redirectToSignupWithTagSubscription(tag)
  }
  const onUnsubscribeFromTag = tag => unsubscribeUserFromChannelTag(channel, tag)

  return {
    isSubscribed,
    subscribedTags,
    otherTags,
    onSubscribe,
    onUnsubscribe,
    onSubscribeToTag,
    onUnsubscribeFromTag
  }
}
