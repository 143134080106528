import styles from './ChannelInfoVertical.module.scss'

import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  ChannelInfoButtons,
  ChannelCard,
  ChannelAvatar,
  ChannelDescription,
  // ChannelInfoTagList,
  // ChannelLink,
  ChannelAdminList,
  SubscribeButton,
  UnSubscribeButton,
  AddCohostButton,
  MoreButton,
  // MessageButton
} from 'components/ChannelInfo'
// import { featureUsed } from 'utils'
import { useChannelInfo } from 'modules/channel/hooks/useChannelInfo'
import Modal from 'components/Modal/Modal'
import InviteToChannel from '../InviteToChannel/InviteToChannel'
import Dropdown from 'components/Dropdown'
import Menu, { MenuItem } from 'components/Menu'
import { Link } from 'react-router-dom'
// import isCallable from 'is-callable'

export const ChannelInfoVertical = React.memo(({
  channel,
  tags,
  subscriptions,
  allowSubscription,
  isUserChannelAdmin,
  onCopyLinkClick,
}) => {
  const [showAddCohostModal, setShowAddCohostModal] = useState(false)

  const {
    photoUrl,
    name,
    slug,
    private: privat3,
    description,
    subscribersCount,
    viewsCount,
    admins,
  } = channel

  const {
    isSubscribed,
    // subscribedTags,
    // otherTags,
    onSubscribe,
    onUnsubscribe,
    // onSubscribeToTag,
    // onUnsubscribeFromTag,
  } = useChannelInfo(channel, subscriptions, tags)

  const publicUserProfiles = useSelector(({ user }) => user.publicUserProfiles)
  const adminProfiles = useMemo(() => {
    return publicUserProfiles.filter(profile => admins.includes(profile.id))
  }, [publicUserProfiles, admins])

  // const onMessage = () => (
  //   featureUsed(
  //     {
  //       action: 'channel_send_message',
  //       category: 'channel',
  //       label: 'Send message to channel owner'
  //     },
  //     'Messaging is in progress...'
  //   )
  // )

  return (
    <div className={styles.container}>
      <ChannelAvatar {...{name, slug, photoUrl}} photoSize={64} />
      <ChannelCard {...{name, private: privat3, viewsCount, subscribersCount}} />
      <ChannelInfoButtons>
        {allowSubscription &&
          <>
            {isSubscribed
              ? <UnSubscribeButton onClick={onUnsubscribe} />
              : <SubscribeButton onClick={onSubscribe} />
            }
          </>
        }
        {!(privat3 && !isUserChannelAdmin) &&
          <AddCohostButton id="add_cohost" onClick={() => setShowAddCohostModal(true)} />
        }
        {/* {isCallable(onMessage) && allowSubscription && <MessageButton onClick={onMessage} />} */}
        <Dropdown
          button={<MoreButton />}
          menu={
            <Menu>
              <MenuItem onClick={onCopyLinkClick}>Copy Link</MenuItem>
              {isUserChannelAdmin &&
              <MenuItem>
                <Link
                  to={{
                    pathname: "/settings",
                    search: `?section=channel&id=${channel.id}`,
                  }}
                >
                  Settings
                </Link>
              </MenuItem>
              }
            </Menu>
          }
          offset={8}
        />

      </ChannelInfoButtons>
      {showAddCohostModal &&
        <Modal onClose={() => setShowAddCohostModal(false)}>
          <InviteToChannel
            channel={channel}
            isUserChannelAdmin={isUserChannelAdmin}
            onClose={() => setShowAddCohostModal(false)}
          />
        </Modal>
      }
      <div className={styles.additional}>
        {/* <ChannelLink channel={channel} /> */}
        <ChannelDescription text={description} />

        {/* {allowSubscription && tags.length > 0 && <hr />} */}
        {/* {allowSubscription && subscribedTags.length > 0 &&
        <ChannelInfoTagList
          title="Subscribed topics:"
          tags={subscribedTags}
          channel={channel}
          onTagClick={onUnsubscribeFromTag}
        />
        }
        {allowSubscription && otherTags.length > 0 &&
        <ChannelInfoTagList
          title="Not subscribed topics"
          tags={otherTags}
          channel={channel}
          onTagClick={onSubscribeToTag}
        />
        } */}

        {/* TODO extract admins.length > 1 to a component parameter showAdminsList */}
        {admins.length > 1 && <hr />}
        {admins.length > 1 &&
        <ChannelAdminList
          admins={adminProfiles}
        />
        }
      </div>
    </div>
  )
})

ChannelInfoVertical.propTypes = {
  channel: PropTypes.object.isRequired,
  allowSubscription: PropTypes.bool,
}
