import React, { useReducer, Suspense } from 'react'

import HeaderBar from 'components/HeaderBar'
import { PulsingLoader } from 'components/Loader'
import { typebarReducer, initialTypebarState } from 'modules/ephy/components/Typebar/typebarReducer'
import { Overlay } from 'components/Overlay'
import TypebarDispatch from 'modules/ephy/components/Typebar/typebarContext'

const Typebar = React.lazy(() => import('modules/ephy/components/Typebar'))

export default function AppLayout(props) {
  const [typebarState, typebarDispatch] = useReducer(typebarReducer, initialTypebarState)

  return (
    <>
      <TypebarDispatch.Provider value={typebarDispatch}>
        <HeaderBar />

        {props.children}

        <Suspense fallback={<PulsingLoader />}>
          <Overlay>
            <Typebar
              typebarState={typebarState}
            />
          </Overlay>
        </Suspense>

      </TypebarDispatch.Provider>
    </>
  )
}
