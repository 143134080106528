import styles from './ChannelAdminList.module.scss'

import React from 'react'
import { ChannelAdmin } from './ChannelAdmin'
import capitalize from 'utils/capitalize'

export function ChannelAdminList({ admins, showTitle = true, direction='vertical' }) {
  return (
    <>
      {showTitle && <div className={styles.header}>Channel hosts:</div>}
      <ul className={styles['list' + capitalize(direction)]}>
        {admins.map(admin => (
          <ChannelAdmin
            key={admin.id}
            name={admin.name}
            username={admin.username}
            photoUrl={admin.photoUrl}
          />
        ))}
      </ul>
    </>
  )
}
