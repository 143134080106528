import {useEffect, useRef} from 'react'

// const root = document.getElementById('root')
const root = document

export function useOnClickOutsideCallback(callback, useCapture = false) {
  const callbackRef = useRef()
  const elementRef = useRef()

  useEffect(() => { callbackRef.current = callback })
  useEffect(() => {
    root.addEventListener('click', handleClick, useCapture)
    return () => root.removeEventListener('click', handleClick)
    function handleClick(e) {
      if (elementRef.current && callbackRef.current &&
        !elementRef.current.contains(e.target)
      ) callbackRef.current(e)
    }
  }, [useCapture])

  return elementRef
}
