import styles from './PrivacyPolicyView.module.scss'

import React from 'react'

import { MainLayout } from 'layout/MainLayout'

// TODO migrate content from notion

export default function PrivacyPolicyView() {
  return (
    <MainLayout variant="onlyMain">
      <MainLayout.LeftSidebar />
      <MainLayout.MainContent>
        <div className={styles.container}>
          <h1>Privacy Policy</h1>
          <h2>Adding content to your post</h2>
          <p>Political correctness has been one of the biggest learnings of this year. Amid a worldwide outbreak, voices from all over the world rallied against the age-old contagions that have been plaguing humanity. On this uphill climb to social justice, we have uncovered an underlying structure of discrimination that lurks beneath our milieu. As our language has been shaped alongside a predominantly racist climate, it has absorbed some of the recollections from that period. On our journey to free our system of its prejudice, we must also purge our lexicons of the scars of the past which</p>
          <h2>Following and unfollowing</h2>
          <p>Political correctness has been one of the biggest learnings of this year. Amid a worldwide outbreak, voices from all over the world rallied against the age-old contagions that have been plaguing humanity. On this uphill climb to social justice, we have uncovered an underlying structure of discrimination that lurks beneath our milieu. As our language has been shaped alongside a predominantly racist climate, it has absorbed some of the recollections from that period. On our journey to free our system of its prejudice, we must also purge our lexicons of the scars of the past which</p>
        </div>
      </MainLayout.MainContent>
      <MainLayout.RightSidebar />
    </MainLayout>
  )
}
