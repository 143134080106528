import styles from './PulsingLoader.module.scss'

import React from 'react'

const PulsingLoader = ({position = 'absolute'}) => (
  <div className={styles.container} style={{position}}>
    <div className={styles.spinner} role="status">
      <div className={styles.srOnly}>Loading...</div>
    </div>
  </div>
)

export default PulsingLoader
