import styles from './ChannelInfoTagList.module.scss'

import React from 'react'
import isCallable from 'is-callable'

import TagLink from 'components/TagLink'
import Pill from 'components/Pill'

export const ChannelInfoTagList = ({ title, tags, channel, onTagClick, isTagActive }) => {
  return (
    <dl className={styles.container}>
      {title && <dt>{title}</dt>}
      {tags.map(tag => (
        <dd key={tag.id}>
          {isCallable(onTagClick)?
            <Pill
              pillStyle="dark"
              size="small"
              active={isCallable(isTagActive)? isTagActive(tag) : false}
              onClick={() => onTagClick(tag)}
            >
              {tag.name}
            </Pill>
            :
            <TagLink
              channel={channel}
              tag={tag}
              active={isCallable(isTagActive)? isTagActive(tag) : false}
              pillStyle="dark"
            />
          }
        </dd>
      ))}
    </dl>
  )
}
