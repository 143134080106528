import styles from './EphyTags.module.scss'
import {ReactComponent as DropdownIcon} from 'icons/ArrowDown.svg'

import React, {
  useRef,
  useState,
  useEffect,
  // useLayoutEffect
} from 'react'
// import isCallable from 'is-callable'

import { useIsBoxOverflown } from 'hooks/useIsBoxOverflown'
import TagLink from 'components/TagLink'
// import TagForm from 'components/TagForm'
import Dropdown from 'components/Dropdown'
import Button from 'components/Button'
import { ChannelInfoTagList } from 'components/ChannelInfo'
import FadableBox from 'components/FadableBox'


export const EphyTags = ({
  ephyTags,
  isTagActiveFn,
  channel,
}) => {
  const [tagsContainerRef, isTagsContainerOverflown] = useIsBoxOverflown()
  const tagDomElementsRef = useRef({})
  const [ephyTagsHidden, setEphyTagsHidden] = useState([])
  useEffect(
    () => {
      setEphyTagsHidden(
        Object.values(tagDomElementsRef.current)
          .filter(([tag, element]) => {
            if (!element) return false
            // console.log('parent width: ', tagsContainerRef.current.clientWidth)
            // console.log('element dimensions: ', element.offsetLeft, element.offsetWidth, element.offsetLeft + element.offsetWidth)
            return (element.offsetLeft + element.offsetWidth) > tagsContainerRef.current.clientWidth
          })
          .map(([tag]) => tag)
      )
    }, [setEphyTagsHidden, ephyTags, tagsContainerRef]
  )
  // useLayoutEffect(
  //   () => {

  //   }
  // )
  // const maybeOnTagClick = isCallable(onTagClick)? tag => onTagClick(tag.name) : undefined
  return (
    <FadableBox isFaded={isTagsContainerOverflown} ref={tagsContainerRef}>
      <div className={styles.container}>
        {ephyTags.length > 0 &&
        <div className={styles.tags}>
          {ephyTags
            .map(tag => (
              <TagLink
                key={tag.id}
                ref={element => tagDomElementsRef.current[tag.name] = [tag, element]}
                channel={channel}
                tag={tag}
                active={isTagActiveFn(tag.name)}
                pillStyle="dark"
              />
            ))
          }
        </div>
        }
        {isTagsContainerOverflown &&
          <div className={styles.tagsSelector}>
            <Dropdown
              button={
                <Button kind="iconMini" size="10,16">
                  <DropdownIcon />
                </Button>
              }
              dropdown={
                <div className={styles.popup}>
                  <ChannelInfoTagList
                    tags={ephyTagsHidden}
                    channel={channel}
                    isTagActive={tag => isTagActiveFn(tag.name)}
                  />
                </div>
              }
              offset="15"
            />
          </div>
        }
      </div>
    </FadableBox>
  )
}
