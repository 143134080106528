import { useScrollToElement } from 'hooks/useScrollToElement'
import { MainLayout } from 'layout'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Comments from './components/Comments/Comments'
import EphiesListItem from './components/EphiesListItem'

export default function EphyView({backBtn, rootRef}) {
  const { ephy_slug } = useParams()
  const ephyId = useMemo(() => ephy_slug ? ephy_slug.split('-').pop() : null, [ephy_slug])

  const ephy = useSelector(state => {
    const allFetchedEphies = [...state.feed.ephies, ...state.channel.ephies]
    return allFetchedEphies.find(e => e.id === ephyId)
  })

  // useLockBodyScroll()
  const elementRef = useScrollToElement()

  return (
    <>
      {/* <MetaTags>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
      </MetaTags> */}

      <MainLayout>
        <MainLayout.LeftSidebar>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            {backBtn}
          </div>
        </MainLayout.LeftSidebar>
        <MainLayout.MainContent>
          {ephy &&
            <EphiesListItem
              ephy={ephy}
              truncate={false}
            />
          }
          {ephy &&
            <section ref={elementRef}>
              <Comments
                ephyId={ephy.id}
                channelId={ephy.channel.id}
                ephyCommentsCount={ephy.commentsCount}
                rootRef={rootRef}
              />
            </section>
          }
          <div style={{ height: '20vh' }}></div>
        </MainLayout.MainContent>
        <MainLayout.RightSidebar />
      </MainLayout>
    </>
  )
}
