import {
  USERS_MARK_ALL_NOTIFICATIONS_AS_READ_START,
  USERS_MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  USERS_MARK_ALL_NOTIFICATIONS_AS_READ_ERROR,
} from '../userConstants'

export function markAllNotificationsAsRead() {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: USERS_MARK_ALL_NOTIFICATIONS_AS_READ_START })

    return getFirebase()
      .firestore()
      .collection('users')
      .doc(getState().firebase.auth.uid)
      .collection('notifications')
      .where('read', '==', false)
      .get()
      .then(notificationsSnapshot => {
        return Promise.all(notificationsSnapshot.docs.map(notificationSnap => (
          notificationSnap.ref.update({ read: true })
        )))
      })
      .then(() => {
        dispatch({
          type: USERS_MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
        })
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: USERS_MARK_ALL_NOTIFICATIONS_AS_READ_ERROR,
          payload: {
            error
          }
        })
      })
  }
}
