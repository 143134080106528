import {
  CHANNELS_JOIN_PRIVATE_CHANNEL_START,
  CHANNELS_JOIN_PRIVATE_CHANNEL_SUCCESS,
  CHANNELS_JOIN_PRIVATE_CHANNEL_ERROR,
} from '../channelConstants'

export function joinPrivateChannel(inviteToken) {
  return (dispatch, _getState, getFirebase) => {
    dispatch({
      type: CHANNELS_JOIN_PRIVATE_CHANNEL_START,
    })

    const joinPrivateChannelHttpsCallable = process.env.REACT_APP_USE_FIREBASE_EMULATOR
      ? getFirebase()
          .functions()
          .httpsCallable('channels-joinPrivateChannel')
      : getFirebase()
          .app()
          .functions(process.env.REACT_APP_FIREBASE_REGION)
          .httpsCallable('channels-joinPrivateChannel')

    return joinPrivateChannelHttpsCallable({ inviteToken })
      .then(result => {
        dispatch({
          type: CHANNELS_JOIN_PRIVATE_CHANNEL_SUCCESS,
          data: result.data
        })

        return result.data
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: CHANNELS_JOIN_PRIVATE_CHANNEL_ERROR,
          error
        })
      })
  }
}
