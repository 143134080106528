import styles from './ChannelUsers.module.scss'
// import { ReactComponent as PlusIcon } from 'icons/Plus.svg'

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import UserList from 'modules/channel/components/UserList'
// import Button from `components/Button`
import { fetchPublicUserProfiles } from 'modules/user/userActions'
// import { featureUsed } from 'utils'
import { removeAdmin } from 'modules/channel/channelActions'

// const AddAdminButton = ({ onClick }) => {
  // return <Button kind="flexWithIcon" isBlocky={true} onClick={onClick} icon={<PlusIcon />}>Add Admin</Button>
// }

export function ChannelAdmins({ channel }) {
  const dispatch = useDispatch()
  const currentUserId = useSelector(state => state.firebase.auth.uid)
  const publicUserProfiles = useSelector(state => state.user.publicUserProfiles)

  useEffect(() => {
    dispatch(fetchPublicUserProfiles(channel.admins))
  }, [dispatch, channel.admins])

  // const onAdminAdd = () => featureUsed({ action: 'channel_add_admin', category: 'channel_settings', label: 'Add admin in channel settings' }, 'Users management is in progress...')
  const onAdminRemove = (userId) => {
    dispatch(removeAdmin(channel.id, userId))
  }

  return (
    <section className={styles.userList}>
      <h2 className={styles.title}>Co-hosts</h2>

      <UserList
        currentUserId={currentUserId}
        users={publicUserProfiles.filter(profile => channel.admins.includes(profile.id))}
        onRemove={onAdminRemove}
      />

      {/* <AddAdminButton onClick={onAdminAdd} /> */}
    </section>
  )
}
