export const EPHIES_NEW_EPHY                = 'EPHIES_NEW_EPHY'
export const EPHIES_REPLACE_EPHY            = 'EPHIES_REPLACE_EPHY'
export const EPHIES_REMOVE_EPHY             = 'EPHIES_REMOVE_EPHY'
export const EPHIES_CREATE_EPHY_START       = 'EPHIES_CREATE_EPHY_START'
export const EPHIES_CREATE_EPHY_SUCCESS     = 'EPHIES_CREATE_EPHY_SUCCESS'
export const EPHIES_CREATE_EPHY_ERROR       = 'EPHIES_CREATE_EPHY_ERROR'
export const EPHIES_UPDATE_EPHY_START       = 'EPHIES_UPDATE_EPHY_START'
export const EPHIES_UPDATE_EPHY_SUCCESS     = 'EPHIES_UPDATE_EPHY_SUCCESS'
export const EPHIES_UPDATE_EPHY_ERROR       = 'EPHIES_UPDATE_EPHY_ERROR'
export const EPHIES_DELETE_EPHY_START       = 'EPHIES_DELETE_EPHY_START'
export const EPHIES_DELETE_EPHY_SUCCESS     = 'EPHIES_DELETE_EPHY_SUCCESS'
export const EPHIES_DELETE_EPHY_ERROR       = 'EPHIES_DELETE_EPHY_ERROR'
export const EPHIES_FETCH_START             = 'EPHIES_FETCH_START'
export const EPHIES_FETCH_SUCCESS           = 'EPHIES_FETCH_SUCCESS'
export const EPHIES_FETCH_ERROR             = 'EPHIES_FETCH_ERROR'
export const EPHIES_SEARCH_START            = 'EPHIES_SEARCH_START'
export const EPHIES_SEARCH_SUCCESS          = 'EPHIES_SEARCH_SUCCESS'
export const EPHIES_SEARCH_ERROR            = 'EPHIES_SEARCH_ERROR'
export const EPHIES_IMPRESSION_BUMP_START   = 'EPHIES_IMPRESSION_BUMP_START'
export const EPHIES_IMPRESSION_BUMP_SUCCESS = 'EPHIES_IMPRESSION_BUMP_SUCCESS'
export const EPHIES_REACT_TO_EPHY_START     = 'EPHIES_REACT_TO_EPHY_START'
export const EPHIES_REACT_TO_EPHY_SUCCESS   = 'EPHIES_REACT_TO_EPHY_SUCCESS'
export const EPHIES_REACT_TO_EPHY_ERROR     = 'EPHIES_REACT_TO_EPHY_ERROR'
export const EPHIES_COMMENT_LIMIT           = 100
export const EPHIES_FETCH_COMMENTS_START    = 'EPHIES_FETCH_COMMENTS_START'
export const EPHIES_FETCH_COMMENTS_SUCCESS  = 'EPHIES_FETCH_COMMENTS_SUCCESS'
export const EPHIES_FETCH_COMMENTS_ERROR    = 'EPHIES_FETCH_COMMENTS_ERROR'
export const EPHIES_CREATE_COMMENT_START    = 'EPHIES_CREATE_COMMENT_START'
export const EPHIES_CREATE_COMMENT_SUCCESS  = 'EPHIES_CREATE_COMMENT_SUCCESS'
export const EPHIES_CREATE_COMMENT_ERROR    = 'EPHIES_CREATE_COMMENT_ERROR'
export const EPHIES_UPDATE_COMMENT_START    = 'EPHIES_UPDATE_COMMENT_START'
export const EPHIES_UPDATE_COMMENT_SUCCESS  = 'EPHIES_UPDATE_COMMENT_SUCCESS'
export const EPHIES_UPDATE_COMMENT_ERROR    = 'EPHIES_UPDATE_COMMENT_ERROR'
export const EPHIES_DELETE_COMMENT_START    = 'EPHIES_DELETE_COMMENT_START'
export const EPHIES_DELETE_COMMENT_SUCCESS  = 'EPHIES_DELETE_COMMENT_SUCCESS'
export const EPHIES_DELETE_COMMENT_ERROR    = 'EPHIES_DELETE_COMMENT_ERROR'
export const EPHIES_LIKE_COMMENT_START      = 'EPHIES_LIKE_COMMENT_START'
export const EPHIES_LIKE_COMMENT_SUCCESS    = 'EPHIES_LIKE_COMMENT_SUCCESS'
export const EPHIES_LIKE_COMMENT_ERROR      = 'EPHIES_LIKE_COMMENT_ERROR'
