import styles from './ChannelAdmin.module.scss'

import React from 'react'
import Avatar from 'components/Avatar'
import { Link } from 'react-router-dom'

export const ChannelAdmin = ({ name, username, photoUrl }) => {
  return (
    <li className={styles.user}>
      <span className={styles.avatar}>
        <Avatar
          photoUrl={photoUrl}
          name={name}
          linkTo={`/users/${username}`}
          size={28}
        />
      </span>
      <Link className={styles.name} to={`/users/${username}`}>{name}</Link>
    </li>
  )
}
