import styles from './SettingsMenu.module.scss'
import {ReactComponent as HamburgerIcon} from 'icons/Hamburger.svg'
import {ReactComponent as CloseIcon} from 'icons/Close.svg'

import React, {useRef, useMemo} from 'react'
import useOnClickOutside from 'use-onclickoutside'

import {SettingsMenu} from './SettingsMenu'
import { useBreakpoints, useOverlay } from 'hooks'
import Button from 'components/Button'

export function AdaptiveSettingsMenu({menuItems}) {
  const [isOverlay, setIsOverlay, OverlayOrInFlow] = useOverlay()
  const onChange = useMemo(() => () => setIsOverlay(false), [setIsOverlay])
  const {isMobile, isDesktop } = useBreakpoints({onChange})

  const overlayRef = useRef()
  useOnClickOutside(overlayRef, () => setIsOverlay(false))

  return (
    <OverlayOrInFlow>
      <div ref={overlayRef} className={isOverlay ? styles.overlay : styles.inFlow}>
        {isMobile &&
          <div className={styles.header}>
            <Button
              kind={isOverlay ? 'iconDark' : 'icon'}
              size="38"
              onClick={() => setIsOverlay(!isOverlay)}
            >
              {isOverlay ? <CloseIcon /> : <HamburgerIcon /> }
            </Button>
          </div>
        }
        {(isDesktop || isOverlay) &&
          <SettingsMenu menuItems={menuItems} onClick={() => setIsOverlay(false)} /> }
      </div>
    </OverlayOrInFlow>
  )
}
