import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import PropTypes from 'prop-types'
import {createTag, updateTag} from 'modules/tag/tagActions'

import {PillForm} from 'components/Pill'

const TagForm = ({
  tag = {},
  channelId = null,
  onFinish,
  pillStyle = 'light',
  id
}) => {
  const tags = useSelector(({ channel, user }) => channelId ? channel.tags[channelId] : user.tags)
  const dispatch = useDispatch()
  const validateForm = (newTagName) => {
    const newTagNameTrimmed = newTagName.trim()
    const withSameName = (tag) => newTagNameTrimmed === tag.name
    if (!newTagNameTrimmed || tags.some(withSameName)) return false
    return true
  }
  const onSave = (newTagName) => {
    if (validateForm(newTagName)) {
      const maxOrder = tags.length > 0 ? Math.max(...tags.map(tag => tag.order)) : 0
      const tagData = {
        id: tag.id || '',
        name: newTagName.trim(),
        order: maxOrder + 1,
      }
      tag.id ?
        dispatch(updateTag(tagData, channelId ? { channel: { id: channelId }} : {})) :
        dispatch(createTag(tagData, channelId ? { channel: { id: channelId }} : {}))
      onFinish()
    }
  }
  return (
    <PillForm
      id={id}
      inputValue={tag.name || ''}
      inputProps={{ maxLength: 50 }}
      onSave={onSave}
      onCancel={onFinish}
      pillStyle={pillStyle}
    />
  )
}

TagForm.propTypes = {}

export default TagForm
