import {
  // TAGS_NEW_TAG,
  // TAGS_REPLACE_TAG,
  // TAGS_REMOVE_TAG,
  TAGS_CREATE_TAG_START,
  TAGS_CREATE_TAG_SUCCESS,
  TAGS_CREATE_TAG_ERROR,
  TAGS_UPDATE_TAG_START,
  TAGS_UPDATE_TAG_SUCCESS,
  TAGS_UPDATE_TAG_ERROR,
  TAGS_DELETE_TAG_START,
  TAGS_DELETE_TAG_SUCCESS,
  TAGS_DELETE_TAG_ERROR,
  TAGS_REORDER_START,
  TAGS_REORDER_SUCCESS,
  TAGS_REORDER_ERROR,
} from './tagConstants'

const initialState = {
  fetchInProgress: false,
}

export default function tagReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TAGS_CREATE_TAG_START:
    case TAGS_UPDATE_TAG_START:
    case TAGS_DELETE_TAG_START:
    case TAGS_REORDER_START:
      return {...state,
        fetchInProgress: true,
      }

    case TAGS_CREATE_TAG_SUCCESS:
    case TAGS_UPDATE_TAG_SUCCESS:
    case TAGS_DELETE_TAG_SUCCESS:
    case TAGS_REORDER_SUCCESS:
      return {
        fetchInProgress: false,
      }

    case TAGS_CREATE_TAG_ERROR:
    case TAGS_UPDATE_TAG_ERROR:
    case TAGS_DELETE_TAG_ERROR:
    case TAGS_REORDER_ERROR:
      return {...state,
        fetchInProgress: false,
      }

    default:
      return state
  }
}
