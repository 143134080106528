import styles from './SearchBar.module.scss'
import { ReactComponent as SearchIcon } from 'icons/Search.svg'
import {ReactComponent as CloseIcon} from 'icons/Close.svg'

import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
// import useOnClickOutside from 'use-onclickoutside'
import { useOnClickOutsideCallback } from 'hooks'
import { searchEphies } from 'modules/ephy/ephyActions'
import { searchChannels } from 'modules/channel/channelActions'
import { ChannelAvatar, ChannelCard } from 'components/ChannelInfo'
import Button from 'components/Button'
import { getEphyUrl, featureUsed } from 'utils'
import { useBreakpoints, useDebounce } from 'hooks'
import { PulsingLoader } from 'components/Loader'

const SearchBar = () => {
  const [fullWidthSearch, setFullWidthSearch] = useState(null)
  const onChange = useCallback(() => ({isMobile}) => isMobile?
    setFullWidthSearch(false) :
    setFullWidthSearch(true)
  , [setFullWidthSearch])
  const {isMobile, isDesktop } = useBreakpoints({onChange})
  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebounce(searchString, 500)
  const [showResults, setShowResults] = useState(false)
  const [ephyResults, setEphyResults] = useState(false)
  const [channelResults, setChannelResults] = useState(false)
  const dispatch = useDispatch()
  const searchInputRef = useRef()
  // const searchString = useSelector(state => state.router.location.query.search || '')

  if (isDesktop && !fullWidthSearch) setFullWidthSearch(true)

  const searchResultsRef = useOnClickOutsideCallback(() => {
    setShowResults(false)
  })

  const onSearchKeyPressed = e => {
    if (e.key === 'Enter') {
      e.preventDefault()

      setShowResults(true)
      dispatch(searchEphies({ searchString })).then(results => setEphyResults(results))
      dispatch(searchChannels({ searchString })).then(results => setChannelResults(results))

      featureUsed({ action: 'search_bar_search', category: 'search', label: 'Search in the search bar' })
    }
  }

  useEffect(
    () => {
      if (debouncedSearchString) {
        setShowResults(true)
        dispatch(searchEphies({ searchString: debouncedSearchString }))
          .then(results => setEphyResults(results))
        dispatch(searchChannels({ searchString: debouncedSearchString }))
          .then(results => setChannelResults(results))

        featureUsed({
          action: 'search_bar_search',
          category: 'search',
          label: 'Search in the search bar'
        })
      } else {

      }
    },
    [debouncedSearchString, dispatch]
  )

  const onSearchFocus = e => (searchString !== '') && setShowResults(true)
  const onSearchClick = e => {
    setFullWidthSearch(true)
    setTimeout(() => searchInputRef.current.focus(), 0)
  }
  const onCloseSearchClick = e => {
    e.preventDefault()
    setSearchString('')
    if (isMobile) setFullWidthSearch(false)
  }
  const onResultsClick = e => setShowResults(false)

  return (
    <>
      <form
        autoComplete="off"
        className={
          `${styles.group} ${fullWidthSearch? styles.fullWidth: ''}`
        }>
        {fullWidthSearch?
          <span className={styles.searchIcon}><SearchIcon /></span>
        :
          <Button kind="icon" size="40" onClick={onSearchClick}><SearchIcon /></Button>
        }
        <input
          type="search"
          name="search"
          maxLength="50"
          ref={searchInputRef}
          className={styles.input}
          placeholder="Search for meaning"
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
          onKeyPress={onSearchKeyPressed}
          onFocus={onSearchFocus}
        />
        {((isMobile && fullWidthSearch) || (isDesktop && !!searchString)) &&
          <Button kind="icon" size="25" className={styles.closeIcon} onClick={onCloseSearchClick}>
            <CloseIcon />
          </Button>
        }
      </form>

      {showResults &&
        <>
        <div className={styles.searchResultsOverlay}></div>
        <div className={styles.results} ref={searchResultsRef} onClick={onResultsClick}>
        {(ephyResults === false || channelResults === false) && <PulsingLoader />}

        {ephyResults !== false && channelResults !== false &&
          <>
          {(ephyResults.length > 0 || channelResults.length > 0)?
            <>
            <section>
              <div className={styles.ephyResults}>
                {ephyResults.slice(0, 5).map(ephy => {
                  const {
                    id,
                    channel: { name: channelName },
                    _highlightResult: {text: {value: highlightedText}},
                  } = ephy
                  const highlightedTextEl = document.createElement('div')
                  highlightedTextEl.innerHTML = highlightedText
                  const [firstEm] = highlightedTextEl.getElementsByTagName('em')
                  let highlightedString
                  if (firstEm) {
                    const containingElText = firstEm.parentElement.textContent
                    const firstEmIndex = containingElText.indexOf(firstEm.textContent)
                    highlightedString = containingElText.slice(firstEmIndex)
                  } else {
                    highlightedString = highlightedTextEl.textContent
                  }

                  return (
                    <Link to={getEphyUrl(ephy)} key={id} className={styles.ephyResult}>
                      <h1>{channelName}</h1>
                      <p>{highlightedString}</p>
                    </Link>
                  )
                })}
              </div>
              <div className={styles.moreResults}>
                <p>
                  <Link to="#" onClick={e => { e.preventDefault(); e.stopPropagation(); featureUsed({ action: 'see_more_ephy_search_results', category: 'search', label: 'See more results (ephies)' }, 'Extended search results is in progress...')}}>
                    See more posts
                  </Link>
                </p>
              </div>
            </section>
            <section>
              <div className={styles.channelResults}>
                {channelResults.slice(0, 5).map(({
                  id,
                  name,
                  slug,
                  private: privat3,
                  photoUrl,
                  viewsCount,
                  subscribersCount
                }) => (
                  <Link to={`/${slug}`} key={id} className={styles.channelResult}>
                    <ChannelAvatar {...{name, photoUrl}} />
                    <ChannelCard {...{name, private: privat3, viewsCount, subscribersCount}} />
                  </Link>
                ))}
              </div>
              <div className={styles.moreResults}>
                <p>
                  <Link to="#" onClick={e => { e.preventDefault(); e.stopPropagation(); featureUsed({ action: 'see_more_channel_search_results', category: 'search', label: 'See more results (channels)' }, 'Extended search results is in progress...')}}>
                    See more channels
                  </Link>
                </p>
              </div>
            </section>
            </>
            :
            <div className={styles.noResults}>
              <p>Sorry, we couldn't find anything matching your search</p>
            </div>
          }
          </>
        }
        </div>
        </>
      }
    </>
  )
}

export default SearchBar
