import styles from './ModalView.module.scss'

import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'

import { useLockBodyScroll } from 'hooks'
import Button from 'components/Button'
import {ReactComponent as BackIcon} from 'icons/ArrowLeft.svg'

export default function ModalView({component: View, routProps}) {
  const history = useHistory()
  const rootRef = useRef()

  const back = e => {
    e.stopPropagation()
    history.goBack()
  }

  useLockBodyScroll()

  useEffect(() => () => console.log('unmount'))

  return (
    <div id="fg_view" ref={rootRef} className={styles.root}>
      <div className={styles.background} />
      <View
        {...routProps}
        backBtn={
          <Button kind="icon" className={styles.backBtn} onClick={back} size="32">
            <BackIcon />
          </Button>
        }
        rootRef={rootRef}
      />
    </div>
  )
}
