import styles from './PillForm.module.scss'
import {ReactComponent as CheckIcon} from './CheckMark.svg'

import React, { useState, useEffect, useRef } from 'react'
// import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types'
import classnames from 'classnames'
import useOnClickOutside from 'use-onclickoutside'
import Button from 'components/Button'
import Pill from 'components/Pill'

export const PillForm = ({
  className,
  id,
  inputValue,
  inputProps = {},
  placeholderText = '',
  // error = '',
  onSave,
  onCancel,
  pillStyle = 'light'
}) => {
  const [newValue, setNewValue] = useState(inputValue)
  const containerRef = useRef()
  const nameInputRef = useRef()

  const onFormKeyDown = e => {
    if (['Enter', 'Escape'].includes(e.key)) {
      e.preventDefault()
      if (e.key === 'Enter') onSave(newValue)
      onCancel()
    }
  }

  useOnClickOutside(containerRef, () => {
    onCancel()
  })

  useEffect(() => {
    nameInputRef.current.focus()
  })

  return (
    <div id={id} ref={containerRef} className={classnames(styles.form, className)}>
      <Pill size="small" pillStyle={pillStyle}>
        <input
          ref={nameInputRef}
          type="text"
          placeholder={placeholderText}
          value={newValue}
          onChange={e => setNewValue(e.target.value)}
          onKeyDown={onFormKeyDown}
          {...inputProps}
        />
      </Pill>

      <Button kind="icon" onClick={() => onSave(newValue)} ><CheckIcon /></Button>
    </div>
  )
}

PillForm.propTypes = { }
