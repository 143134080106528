import React, { useEffect } from 'react'
import { useDispatch} from 'react-redux'
import { useParams } from 'react-router-dom'
import { replace } from 'connected-react-router'

import { getEphyUrl } from 'utils'
import { fetchEphy } from 'modules/channel/channelActions'

export default function EphyRedirectView() {
  const dispatch = useDispatch()
  const { channel_id, ephy_id } = useParams()

  useEffect(function redirectToEphy() {
    dispatch(fetchEphy(channel_id, ephy_id))
      .then(ephy => {
        if (ephy) {
          dispatch(replace(getEphyUrl(ephy)))
        }
      })
  }, [dispatch, channel_id, ephy_id])

  return <></>
}
