import {
  CHANNELS_FETCH_CHANNEL_START,
  CHANNELS_FETCH_CHANNEL_SUCCESS,
  CHANNELS_FETCH_CHANNEL_ERROR,
} from '../channelConstants'

import isCallable from 'is-callable'

const getSnapshotProcessor = (isCollectionProcessor = false, dispatch, callback) => {
  return (querySnapshot) => {
    if (!querySnapshot.empty) {
      const channelSnapshot = isCollectionProcessor ? querySnapshot.docs[0] : querySnapshot
      const channel = { id: channelSnapshot.id, ...channelSnapshot.data() }
      if (isCallable(callback)) {
        callback(channel)
      }

      dispatch({
        type: CHANNELS_FETCH_CHANNEL_SUCCESS,
        payload: {
          data: channel
        }
      })
    } else {
      if (isCallable(callback)) {
        callback(false)
      }

      dispatch({
        type: CHANNELS_FETCH_CHANNEL_ERROR,
        payload: {
          error: 'Channel not found'
        }
      })
    }
  }
}

const getDocumentSnapshotProcessor = (dispatch, callback) => getSnapshotProcessor(false, dispatch, callback)
const getCollectionSnapshotProcessor = (dispatch, callback) => getSnapshotProcessor(true, dispatch, callback)


const getErrorHandler = (dispatch) => (
  (error) => {
    console.error(error)

    dispatch({
      type: CHANNELS_FETCH_CHANNEL_ERROR,
      payload: {
        error: error
      }
    })
  }
)
export function subscribeToChannel({ id, slug }, callback) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: CHANNELS_FETCH_CHANNEL_START })

    // TODO add callback fired? remove callback fired at all?
    const query = id
      // get channel by id
      ? getFirebase()
          .firestore()
          .collection('channels')
          .doc(id)
      // get channel by slug
      // it's a collection query and it requires to specify the condition
      // to match the Firestore security rules
      // so, we fetch the channel as public first
      : getFirebase()
          .firestore()
          .collection('channels')
          .where('slug', '==', slug)
          .where('deleted', '==', false)
          .where('private', '==', false)

    let unsubscribe = query
      .onSnapshot(
        (querySnapshot) => {
          const authUserId = getState().firebase.auth.uid

          // channel was fetched by id
          if (id) {
            return (getDocumentSnapshotProcessor(dispatch, callback))(querySnapshot)
          // channel was fetched by slug
          } else if (!querySnapshot.empty || !authUserId) {
            return (getCollectionSnapshotProcessor(dispatch, callback))(querySnapshot)
          } else {
            // if nothing was found, trying to fetch as an admin
            unsubscribe = getFirebase()
              .firestore()
              .collection('channels')
              .where('slug', '==', slug)
              .where('deleted', '==', false)
              .where('admins', 'array-contains', authUserId)
              .onSnapshot(
                (querySnapshot) => {
                  if (!querySnapshot.empty) {
                    return (getCollectionSnapshotProcessor(dispatch, callback))(querySnapshot)
                  }

                  // if nothing was found, trying to fetch as a member
                  unsubscribe = getFirebase()
                    .firestore()
                    .collection('channels')
                    .where('slug', '==', slug)
                    .where('deleted', '==', false)
                    .where('members', 'array-contains', authUserId)
                    .onSnapshot(
                      (querySnapshot) => {
                        return (getCollectionSnapshotProcessor(dispatch, callback))(querySnapshot)
                      },
                      getErrorHandler(dispatch)
                    )
                },
                getErrorHandler(dispatch)
              )
          }
        },
        getErrorHandler(dispatch)
      )

    return () => unsubscribe()
  }
}
