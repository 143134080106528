import React from 'react'

import NoContentMessage from 'components/NoContentMessage'
import { Link } from 'react-router-dom'

export const AnothersChannelNoContent = React.memo(() => {
  const title = "This channel has no content yet."
  const subtitle = (
    <span>
      Go to <Link to="/">feed</Link> or <Link to="/discover">discover more channels</Link>
    </span>
  )

  return (
    <NoContentMessage
      title={title}
      subtitle={subtitle}
    />
  )
})
