import {
  USERS_NOTIFICATION_LIMIT,
  USERS_FETCH_NOTIFICATIONS_START,
  USERS_FETCH_NOTIFICATIONS_SUCCESS,
  USERS_FETCH_NOTIFICATIONS_ERROR,
} from '../userConstants'

import { createRecordsLoader } from 'utils/infiniteScroll'

export function subscribeToNotifications() {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: USERS_FETCH_NOTIFICATIONS_START })

    const userId = getState().firebase.auth.uid

    const createQuery = ({ startAfter, endBefore } = {}) => {
      let query = getFirebase()
        .firestore()
        .collection('users')
        .doc(userId)
        .collection('notifications')
        .orderBy('createdAt', 'desc')
        .limit(USERS_NOTIFICATION_LIMIT)

      if (startAfter) {
        query = query.startAfter(startAfter)
      }

      if (endBefore) {
        query = query.endBefore(endBefore)
      }

      return query
    }

    const { load, loadMore, unsubscribe } = createRecordsLoader({
      createQuery,
      dispatch,
      limit: USERS_NOTIFICATION_LIMIT,
      successAction: USERS_FETCH_NOTIFICATIONS_SUCCESS,
      errorAction: USERS_FETCH_NOTIFICATIONS_ERROR,
    })

    load()

    return {
      unsubscribe,
      loadMore
    }
  }
}
