import styles from './JoinPrivateChannelView.module.scss'

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import { PulsingLoader } from 'components/Loader'
import { joinPrivateChannel } from 'modules/channel/channelActions'
import NoContentMessage from 'components/NoContentMessage'
import MainLayout from 'layout/MainLayout'

const ErrorView = () => (
  <MainLayout variant="onlyMain">
    <MainLayout.LeftSidebar />
    <MainLayout.MainContent>
      <div className={styles.container}>
        <NoContentMessage
          title="Error joining private channel"
          subtitle={
            <>
              <Link to="/">Go home</Link> or <Link to="/login">login</Link>
            </>
          }
        />
      </div>
    </MainLayout.MainContent>
    <MainLayout.RightSidebar />
  </MainLayout>
)

export default function JoinPrivateChannelView() {
  const dispatch = useDispatch()
  const { invite_token } = useParams()
  const [error, setError] = useState()

  useEffect(() => {
    dispatch(joinPrivateChannel(invite_token))
      .then(channel => {
        if (!channel || !channel.slug) {
          return setError('Error')
        }

        toast.success(`Successfully joined channel "${channel.name}"`)
        dispatch(push(`/${channel.slug}`))
      })
  }, [dispatch, invite_token])

  return error
    ? <ErrorView />
    : <PulsingLoader />
}
