import styles from './ChannelInfoButtons.module.scss'
import { ReactComponent as MessageIcon } from 'icons/Message.svg'
import { ReactComponent as DotsIcon } from 'icons/Dots.svg'
import { ReactComponent as AddCohostIcon } from 'icons/AddUser.svg'

import React, { useState } from 'react'
import Button from 'components/Button'

export const SubscribeButton = ({onClick}) => (
  <Button kind="primary" style={{width: '11ch'}} onClick={onClick}>Subscribe</Button>
)

export const UnSubscribeButton = ({onClick}) => {
  const [hovered, setHovered] = useState(false)
  const btnProps = {
    style: {width: '11ch'},
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
    onClick,
  }
  return (
    <Button kind="outlined" {...btnProps}>
      {hovered ? 'Unsubscribe' : 'Subscribed'}
    </Button>
  )
}

export const MessageButton = ({onClick}) => (
  <Button kind="iconBordered" onClick={onClick} size="40"><MessageIcon /></Button>
)

export const AddCohostButton = ({onClick, ...restProps}) => (
  <Button kind="iconBordered" onClick={onClick} size="40" {...restProps}><AddCohostIcon /></Button>
)

export const MoreButton = React.forwardRef(({...restProps}, ref) => (
  <Button ref={ref} kind="iconBordered" size="40" {...restProps}><DotsIcon /></Button>
))

export const ChannelInfoButtons = ({
  children
}) => (
  <div className={styles.container}>
    {children}
  </div>
)
