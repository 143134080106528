import styles from './DummyChannelPreview.module.scss'

import React from 'react'

export default function DummyChannelPreview({
  children,
  title = 'How your channel may look like'
}) {
  return (
    <div
      className={styles.container}
      style={{pointerEvents: 'none'}}
      onClickCapture={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  )
}
