import styles from './EphyTags.module.scss'
import {ReactComponent as DropdownIcon} from 'icons/ArrowDown.svg'

import React, {
  // useRef,
  // useState,
  useEffect,
  // useLayoutEffect
} from 'react'
import { useSelector, useDispatch} from 'react-redux'
// import isCallable from 'is-callable'

import * as channelActions from 'modules/channel/channelActions'
import * as userActions from 'modules/user/userActions'
import { useIsBoxOverflown } from 'hooks/useIsBoxOverflown'
// import TagForm from 'components/TagForm'
import Dropdown from 'components/Dropdown'
import Button from 'components/Button'
import { ChannelInfoTagList } from 'components/ChannelInfo'
import Pill from 'components/Pill'
import FadableBox from 'components/FadableBox'


export const EphyFormTags = ({
  channel,
  // channelTags,
  ephyTags,
  onTagClick,
  isTagActiveFn,
}) => {
  const dispatch = useDispatch()
  const [tagsContainerRef, isTagsContainerOverflown] = useIsBoxOverflown()

  const channelTags = useSelector(state => {
    if (channel?.id) {
      return state.channel.tags[channel.id] || []
    }
    return state.user.tags
  })

  useEffect(() => {
    if (channel?.id) {
      return dispatch(channelActions.subscribeToChannelTags(channel.id))
    }
    return dispatch(userActions.subscribeToPrivateTags())
  }, [dispatch, channel])

  return (
    <FadableBox isFaded={isTagsContainerOverflown} ref={tagsContainerRef}>
      <div className={styles.container}>
        {ephyTags.length > 0 &&
        <div className={styles.tags}>
          {ephyTags
            // .filter(tag => ephyTags.includes(tag.name))
            .map((tagName, index) => (
              <Pill
                key={index}
                pillStyle="dark"
                size="small"
                active={false}
                onClick={() => onTagClick(tagName)}
              >
                {tagName}
              </Pill>
            ))
          }
        </div>
        }
          <Dropdown
            button={
              <div className={styles.tagsSelector}>
                {ephyTags.length === 0 &&
                <span className={styles.tagsLabel}>Topics</span>
                }
                <Button type="button" kind="iconMini" size="10,16">
                  <DropdownIcon />
                </Button>
              </div>
            }
            dropdown={
              <div className={styles.popup}>
                <ChannelInfoTagList
                  title="Topics:"
                  tags={channelTags}
                  channel={channel}
                  isTagActive={tag => isTagActiveFn(tag.name)}
                  onTagClick={tag => onTagClick(tag.name)}
                />
              </div>
            }
            offset="15"
          />
        {/* {this.state.editTag &&
          <TagForm
            tag={this.state.editTag}
            tags={this.props.tags}
            onSave={tag => this.saveTag(tag)}
            onEditFinished={() => this.setState({ editTag: null })}
          />}

        {!this.state.editTag && this.renderNewTagButton()} */}
      </div>
    </FadableBox>
  )
}
