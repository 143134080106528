import styles from './IconStyle.module.scss'

import React from 'react'
import classnames from 'classnames'

export const IconStyle = React.forwardRef((props, ref) => {
  let {
    active,
    border,
    background,
    size,
    className,
    style = {},
    ...restProps
  } = props

  className = classnames(
    styles.icon,
    {
      [styles.iconActive]: active,
      [styles.iconBackground]: background,
      [styles.iconBordered]: border,
    },
    className,
  )

  if (size) {
    style.width = style.height = `${size}px`
  }

  return (
    <span className={className} style={style} {...restProps} ref={ref} />
  )
})
