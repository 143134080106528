import styles from './SubscriptionInfo.module.scss'
import React from 'react'

import {
  ChannelInfoButtons,
  ChannelCard,
  ChannelAvatar,
  ChannelDescription,
  ChannelInfoTagList,
  ChannelLink,
  UnSubscribeButton,
} from 'components/ChannelInfo'

// import { featureUsed } from 'utils'
import { useChannelInfo } from 'modules/channel/hooks/useChannelInfo'

export const SubscriptionInfo = ({ subscription  }) => {

  const {
    channel,
  } = subscription

  const {
    photoUrl,
    name,
    slug,
    private: privat3,
    description,
    subscribersCount,
    viewsCount,
  } = channel

  const {
    subscribedTags,
    otherTags,
    onUnsubscribe,
    onSubscribeToTag,
    onUnsubscribeFromTag,
  } = useChannelInfo(channel, [subscription], subscription.tags)

  // const onMessage = () => (
  //   featureUsed(
  //     {
  //       action: 'channel_send_message',
  //       category: 'channel',
  //       label: 'Send message to channel owner'
  //     },
  //     'Messaging is in progress...'
  //   )
  // )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ChannelAvatar {...{name, slug, photoUrl}} />
        <ChannelInfoButtons>
          <UnSubscribeButton onClick={onUnsubscribe} />
        </ChannelInfoButtons>
      </div>
      <ChannelCard {...{name, slug, private: privat3, viewsCount, subscribersCount}} />
      <ChannelLink channel={channel} />
      <ChannelDescription text={description} />
      {(subscribedTags.length + otherTags.length > 0) &&
      <hr />
      }
      {subscribedTags.length > 0 &&
      <ChannelInfoTagList
        title="Subscribed to topics:"
        tags={subscribedTags}
        channel={channel}
        onTagClick={onUnsubscribeFromTag}
      />
      }
      {otherTags.length > 0 &&
      <ChannelInfoTagList
        title={subscribedTags.length > 0 ? 'Not subscribed to topics:' : 'Topics'}
        tags={otherTags}
        channel={channel}
        onTagClick={onSubscribeToTag}
      />
      }
    </div>
  )
}
