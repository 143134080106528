import { useState, useEffect, useCallback } from 'react'

// const throttleFn = (fn, timeout, scheduled = false) =>
// // eslint-disable-next-line no-sequences
//   (...args) => scheduled ? null : (fn(args), scheduled = true, setTimeout(() => scheduled = false, timeout))

const throttleFn = (fn, timeout, scheduled = false) =>
// eslint-disable-next-line no-sequences
  (...args) => scheduled ? null : scheduled = setTimeout(() => (scheduled = false, fn(args)), timeout)

export const useScroll = () => {
  const [state, setState] = useState({
    lastScrollTop: 0,
    bodyOffset: document.body.getBoundingClientRect(),
    scrollY: document.body.getBoundingClientRect().top,
    scrollX: document.body.getBoundingClientRect().left,
    scrollDirection: 'down', // down, up
  })

  const handleScrollEvent = useCallback((e) => {
    setState(prevState => {
      const prevLastScrollTop = prevState.lastScrollTop
      const bodyOffset = document.body.getBoundingClientRect()

      return {
        setBodyOffset: bodyOffset,
        scrollY: -bodyOffset.top,
        scrollX: bodyOffset.left,
        scrollDirection: prevLastScrollTop > -bodyOffset.top ? 'down' : 'up',
        lastScrollTop: -bodyOffset.top,
      }
    })
  }, [])

  useEffect(() => {
    const scrollListener = throttleFn(e => {
      handleScrollEvent(e)
    }, 400)
    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [handleScrollEvent])

  return {
    scrollY: state.scrollY,
    scrollX: state.scrollX,
    scrollDirection: state.scrollDirection,
  }
}
