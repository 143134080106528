import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// import classnames from 'classnames'

import Pill from 'components/Pill'

const TagLink = React.forwardRef(({
  tag,
  channel,
  active = false,
  size = 'small',
  pillStyle = 'light',
  className = '',
  children,
  ...restProps
}, ref) => {

  const tagURI = encodeURIComponent(tag.name)
  const slugPath = `/${channel ? channel.slug : 'drafts'}`
  const href = slugPath + (active ? '' : `/${tagURI}`)

  const combinedProps = {
    component: Link,
    active,
    size,
    pillStyle,
    to: { pathname: href },
    className,
    ref,
    /*     className: classnames(className, {
          'ephy-tag-active': active,
        }), */
    ...restProps
  }

  return (
    <Pill {...combinedProps}>
      {tag.name}
    </Pill>
  )
})

TagLink.propTypes = {
  channel: PropTypes.object,
  tag: PropTypes.object.isRequired,
  active: PropTypes.bool,
  size: PropTypes.string,
  pillStyle: PropTypes.string,
}

export default TagLink
