import {
  EPHIES_IMPRESSION_BUMP_START,
  EPHIES_IMPRESSION_BUMP_SUCCESS,
} from '../ephyConstants'

export function bumpImpressionsCount(ephies) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_IMPRESSION_BUMP_START })

    return new Promise((resolve, reject) => {
      const incrementImpressions = getFirebase().firestore.FieldValue.increment(1)
      const batch = getFirebase().firestore().batch()

      ephies.forEach(ephy => {
        let ephiesCollectionRef

        if (ephy.channel) {
          ephiesCollectionRef = getFirebase()
            .firestore()
            .collection('channels')
            .doc(ephy.channel.id)
            .collection('ephies')
        } else {
          ephiesCollectionRef = getFirebase()
            .firestore()
            .collection('users')
            .doc(getState().firebase.auth.uid)
            .collection('ephies')
        }

        const ephyRef = ephiesCollectionRef.doc(ephy.id)

        batch.update(ephyRef, {
          impressions: incrementImpressions,
        })
      })

      batch.commit().then(() => {
        resolve()

        dispatch({
          type: EPHIES_IMPRESSION_BUMP_SUCCESS
        })
      })
    })
  }
}
