import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey           : process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain       : process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL      : process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId        : process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket    : process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId            : process.env.REACT_APP_FIREBASE_APPID,
  measurementId    : process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}

firebase.initializeApp(firebaseConfig)
firebase.firestore()

if (process.env.REACT_APP_USE_FIREBASE_EMULATOR) {
  firebase.firestore().useEmulator('localhost', 8080)
  firebase.auth().useEmulator('http://localhost:9099')
  firebase.functions().useEmulator('localhost', 5001)
}

export default firebase
