import {
  USERS_FETCH_USER_CHANNELS_START,
  USERS_FETCH_USER_CHANNELS_SUCCESS,
  USERS_FETCH_USER_CHANNELS_ERROR,
} from '../userConstants'

import isCallable from 'is-callable'

export function subscribeToChannels(callback) {
  return (dispatch, getState, getFirebase) => {

    dispatch({ type: USERS_FETCH_USER_CHANNELS_START })

    return getFirebase()
      .firestore()
      .collection('channels')
      .where('deleted', '==', false)
      .where('admins', 'array-contains', getState().firebase.auth.uid)
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const channels = []

        querySnapshot.forEach(channelSnap => {
          channels.push({ id: channelSnap.id, ...channelSnap.data() })
        })

        if (isCallable(callback)) {
          callback(channels)
        }

        dispatch({
          type: USERS_FETCH_USER_CHANNELS_SUCCESS,
          payload: {
            data: channels
          }
        })
      }, error => {
        console.error(error)

        dispatch({
          type: USERS_FETCH_USER_CHANNELS_ERROR,
          payload: {
            error
          }
        })
      })
  }
}
