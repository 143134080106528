import styles from './EphyAuthor.module.scss'

import React from 'react'
import Avatar from 'components/Avatar'
import { useBreakpoints } from 'hooks'
import { Link } from 'react-router-dom'

export const EphyAuthor = ({ profile }) => {
  const { isMobile } = useBreakpoints()

  return (
    <div className={styles.container}>
      <Avatar
        photoUrl={profile.photoUrl}
        name={profile.name}
        linkTo={`/users/${profile.username}`}
        size={24}
        border={true}
      />
      {!isMobile &&
      <div className={styles.name}>
        {/* {profile.name} */}
        <Link to={`/users/${profile.username}`} className={styles.userLink}>{profile.name}</Link>
      </div>
      }
    </div>
  )
}
