import styles from './ProfileView.module.scss'
import {ReactComponent as ProfileIcon} from 'icons/ProfileSmall.svg'
import {ReactComponent as WebsiteIcon} from 'icons/Website.svg'

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { MetaTags } from 'react-meta-tags'

import { MainLayout } from 'layout'
import { PulsingLoader } from 'components/Loader'
import { Error404View } from 'modules/error'
import * as actions from './profileActions'
import Channel from 'modules/discover/components/Channel'
import { Surface } from 'modules/discover/DiscoverView'
import { useBreakpoints } from 'hooks'
import Avatar from 'components/Avatar'

export default function UserProfileView() {
  const dispatch = useDispatch()
  const { isMobile } = useBreakpoints()

  const { username } = useParams()

  const authProfile = useSelector(({ firebase }) => firebase.profile)
  const fetchInProgress = useSelector(({ profile }) => profile.fetchInProgress)
  const profile = useSelector(({ profile }) => profile.profile)
  const channels = useSelector(({ profile }) => profile.channels)
  const subscriptions = useSelector(state => state.feed.subscriptions)

  const {
    photoUrl,
    name,
    slug,
  } = profile

  const totalSubscribers = channels.reduce((acc, channel) => acc += channel.subscribersCount, 0)

  useEffect(() => {
    const unsubscribeFromProfile = dispatch(actions.subscribeToProfile({ username }))

    return () => {
      unsubscribeFromProfile()
    }
  }, [dispatch, username])

  useEffect(() => {
    if (profile.id) {
      const unsubscribeFromChannels = dispatch(actions.subscribeToChannels({ userId: profile.id }))

      return () => {
        unsubscribeFromChannels()
      }
    }
  }, [dispatch, profile])

  if (!authProfile.isLoaded || fetchInProgress || profile.username !== username)
    return <PulsingLoader />

  if (!profile.id) {
    return <Error404View />
  }

  return (
    <>
      <MetaTags>
        <title>{profile.name}</title>
        <meta property="og:title" content={profile.name} />
        <meta name="description" content={`${profile.name} profile on Lightspot`} />
        <meta property="og:description" content={`${profile.name} profile on Lightspot`} />
        <meta property="og:image" content={profile.photoUrl} />
      </MetaTags>

      <MainLayout variant="onlyMain">
        <MainLayout.LeftSidebar />

        <MainLayout.MainContent>
          <div className={styles.profile}>
            <Avatar
              className={styles.avatar}
              photoUrl={photoUrl}
              linkTo={slug && `/${slug}`}
              name={name}
              size={90}
              border={true}
            />
            <div className={styles.cover}>
              <span className={styles.stats}><span>{totalSubscribers}</span> subscribers</span>
            </div>
            <div className={styles.info}>
              <h1>{profile.name}</h1>
              <p className={styles.about}>{profile.about}</p>
              <div className={styles.extraInfo}>
                {profile.occupation && <span><ProfileIcon />{profile.occupation}</span>}
                {profile.website && <span><WebsiteIcon />{profile.website}</span>}
              </div>
            </div>
          </div>
          <div className={styles.channelsCount}><span>{channels.length}</span> channels</div>
          <div className={styles.channels}>
            {channels.length > 0 ? (
              <>
              {channels.map(channel => (
                <Surface key={channel.id}>
                  <Channel
                    channel={channel}
                    subscriptions={subscriptions}
                    isMobile={isMobile}
                  />
                </Surface>
              ))}
              <div style={{ height: '20vh' }}></div>
              </>
            ) : (
              <p className={styles.emptyMessage}>
                User has not created a channel yet
              </p>
            )}
          </div>
        </MainLayout.MainContent>

        <MainLayout.RightSidebar />
      </MainLayout>
    </>
  )
}
