import {
  EPHIES_DELETE_EPHY_START,
  EPHIES_DELETE_EPHY_SUCCESS,
  EPHIES_DELETE_EPHY_ERROR,
} from '../ephyConstants'

export function deleteEphy(ephy) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_DELETE_EPHY_START })

    let ephiesCollectionRef

    if (ephy.channel) {
      ephiesCollectionRef = getFirebase()
        .firestore()
        .collection('channels')
        .doc(ephy.channel.id)
        .collection('ephies')
    } else {
      ephiesCollectionRef = getFirebase()
        .firestore()
        .collection('users')
        .doc(getState().firebase.auth.uid)
        .collection('ephies')
    }

    return ephiesCollectionRef
      .doc(ephy.id)
      .delete()
      .then(() => {

        dispatch({
          type: EPHIES_DELETE_EPHY_SUCCESS,
          payload: {
            data: ephy
          }
        })

        return true
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: EPHIES_DELETE_EPHY_ERROR,
          error,
        })
      })
  }
}
