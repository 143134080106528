import styles from './PrivateEphiesView.module.scss'
import {ReactComponent as FolderIcon} from 'icons/Folder.svg'

import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import HashLinkObserver from 'react-hash-link'

import { MainLayout } from 'layout'
import TagList from 'modules/channel/components/TagList'
import EphiesList from 'modules/ephy/components/EphiesList'
import { PulsingLoader } from 'components/Loader'
import IconStyle from 'components/IconStyle'
import NoContentMessage from 'components/NoContentMessage'
import * as actions from './userActions'
import TypebarDispatch from 'modules/ephy/components/Typebar/typebarContext'

export default function PrivateEphiesView() {
  const dispatch = useDispatch()
  const typebarDispatch = useContext(TypebarDispatch)

  const ephies = useSelector(({user}) => user.ephies)
  const tags = useSelector(({user}) => user.tags)

  const { tag } = useParams()
  const activeTag = useMemo(() => tag ? decodeURIComponent(tag) : null, [tag])

  useEffect(() => {
    return dispatch(actions.subscribeToPrivateTags())
  }, [dispatch])

  const [hasMoreEphies, setHasMoreEphies] = useState(true)
  const [privateEphiesSubscription, setPrivateEphiesSubscription] = useState(null)
  useEffect(() => {
    const privateEphiesSubscription = dispatch(actions.subscribeToPrivateEphies({tag: activeTag}))
    setPrivateEphiesSubscription(privateEphiesSubscription)
    return () => privateEphiesSubscription.unsubscribe()
  }, [dispatch, activeTag])

  const loadMoreEphies = useCallback(
    () => {
      if (!privateEphiesSubscription) return

      privateEphiesSubscription.loadMore().then((lastEphy) => {
        const hasMore = !!lastEphy
        if (hasMore !== hasMoreEphies) {
          setHasMoreEphies(hasMore)
        }
      })
    }, [privateEphiesSubscription, hasMoreEphies]
  )

  const emptyMessage = useCallback(() => (
      <div className={styles.noContent}>
        <NoContentMessage
          title="This is your personal space."
          subtitle={
            <span>
              Forward your drafts, media, or files here to use it later.<br/>
              <Link to="/settings?section=import">Import</Link> your things or&nbsp;
              <Link to="#" onClick={e => { e.preventDefault(); e.stopPropagation(); window.requestAnimationFrame(() => typebarDispatch({type: 'ephy/clickedNew'})) }}>add anything you like</Link> with a typebar
            </span>
          }
        />
      </div>
    ), [typebarDispatch]
  )

  const title = useMemo(() => {
    return `${activeTag ? `${activeTag} – ` : ''}Private posts - Lightspot`
  }, [activeTag])
  const description = `Private posts – Lightspot`

  const profile = useSelector(({firebase}) => firebase.profile)
  const fetchInProgress = useSelector(({user}) => user.fetchInProgress)
  const privateEphiesFetchInProgress = useSelector(({user}) => user.privateEphiesFetchInProgress)

  if (!profile.isLoaded || fetchInProgress)
    return <PulsingLoader />

  return (
    <>
      <MetaTags>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        {/* <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>

      <MainLayout>
        <MainLayout.LeftSidebar>
          <div className={styles.header}>
            <IconStyle border={true}><FolderIcon /></IconStyle>
            <h1>Pocket</h1>
          </div>
        </MainLayout.LeftSidebar>

        <MainLayout.MainContent>
          {privateEphiesFetchInProgress
            ? <PulsingLoader />
            : <EphiesList
                ephies={ephies}
                showChannelAvatarOnDesktop={false}
                tags={tags}
                emptyMessage={emptyMessage}
                editable={true}
                loadMoreEphies={loadMoreEphies}
                hasMoreEphies={hasMoreEphies}
              />
          }
        </MainLayout.MainContent>

        <MainLayout.RightSidebar>
          <TagList
            tags={tags}
            activeTag={activeTag}
            editable={true}
          />
        </MainLayout.RightSidebar>
      </MainLayout>
      <HashLinkObserver />
    </>
  )
}
