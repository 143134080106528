import styles from './EphyDatetime.module.scss'

import moment from 'moment'
import Tooltip from 'components/Tooltip'


moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a second',
    ss: '%s seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  }
})

export const EphyDatetime = ({datetime}) => (
  <Tooltip
    content={moment.unix(datetime.seconds).format('MMMM Do YYYY, h:mm a')}
    placement="top"
  >
    <div className={styles.container}>
      {moment.unix(datetime.seconds).fromNow()}
    </div>
  </Tooltip>
 )
