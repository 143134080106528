import styles from './EphyReactions.module.scss'
import { ReactComponent as ReactionsIcon } from './Reactions.svg'

import { useState } from 'react'

import * as ephyActions from 'modules/ephy/ephyActions'
import Dropdown from 'components/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Button from 'components/Button'
import { featureUsed } from 'utils'

const DEFAULT_REACTIONS = ['👍', '❤️', '😄', '💡', '💎']

export const EphyReactions = ({channelId, ephyId, reactions = []}) => {
  const dispatch = useDispatch()

  const [opened, setOpened] = useState(false)

  const userIsAuthenticated = useSelector(state => !state.firebase.auth.isEmpty)
  const userReactions = useSelector(state => state.user.reactions)
  const currentUrl = useSelector(state => state.router.location.pathname)
  const userReaction = userReactions.find(reaction => reaction.ephyId === ephyId)

  const sortedReactions = []
  DEFAULT_REACTIONS.forEach(reaction => {
    if (!reactions.hasOwnProperty(reaction))
      return

    sortedReactions.push([reaction, reactions[reaction]])
  })

  function reactToEphy(reaction, removeIfExists = true) {
    if (reaction === userReaction?.reaction) {
      if (!removeIfExists)
        return

      reaction = false
    }

    featureUsed({ action: 'reaction', category: 'ephy', label: 'User reacted to ephy' })

    if (userIsAuthenticated) {
      dispatch(ephyActions.reactToEphy({ channelId, ephyId, reaction }))
    } else {
      dispatch(push(`/login?redirect=${encodeURIComponent(currentUrl)}`))
    }
  }

  return (
    <div className={styles.container}>
      <Dropdown
        button={<Button kind="icon" isActive={opened} size="27"><ReactionsIcon /></Button>}
        dropdown={
          <ul className={styles.reactionsSelectorList}>
            {DEFAULT_REACTIONS.map((reaction, idx) =>
              <li key={idx}>
                <Button
                  kind="text"
                  size="26"
                  onClick={() => reactToEphy(reaction, false)}
                >
                  {reaction}
                </Button>
              </li>
            )}
          </ul>
        }
        placement="right"
        offset="8"
        borderRadius="6"
        onHide={() => setOpened(false)}
        onShow={() => setOpened(true)}
      />
      <ul className={styles.postReactions}>
        {sortedReactions.map(([reaction, count], idx) => (
          <li key={idx}>
            <Button
              kind={userReaction?.reaction === reaction ? 'outlined' : 'text' }
              onClick={() => reactToEphy(reaction)}
            >
              <span>{reaction}</span>
              <span>{count}</span>
            </Button>
          </li>
        ))}
      </ul>
    </div>
  )
}
