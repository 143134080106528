import styles from './HeaderBar.module.scss'

import { useSelector } from 'react-redux'
// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import SearchBar from 'components/SearchBar'
import CreateEphyMenuItem from './CreateEphyMenuItem'
import NotificationsMenuItem from './NotificationsMenuItem'
import UserMenuItem from './UserMenuItem'
import { ReactComponent as EphyLogo } from './Logo.svg'

const HeaderBar = () => {
  const isAuthenticated = !useSelector(state => state.firebase.auth.isEmpty)

  return (
    <header className={styles.navbar}>
      <div className={styles.navbarContainer}>
        <div className={styles.logo}>
          <div className={styles.container}>
            <Link to="/" title="Feed">
              <EphyLogo />
            </Link>
          </div>
        </div>
        <div className={styles.search}>
          <div className={styles.container}>
            <SearchBar />
          </div>
        </div>
        <div className={styles.navigation}>
          <div className={styles.container}>
            <ul className={styles.menu}>
              <li className={styles.menuItem}>
                <UserMenuItem />
              </li>
              {isAuthenticated &&
                <>
                  <li className={styles.menuItem}>
                    <NotificationsMenuItem />
                  </li>
                  <li className={styles.menuItem}>
                    <CreateEphyMenuItem />
                  </li>
                </>
              }
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

// HeaderBar.propTypes = {
  // showAddButton: PropTypes.bool,
  // activeChannelId: PropTypes.string,
// }

export default HeaderBar
