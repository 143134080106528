import {
  USERS_FETCH_COMMENT_LIKES_START,
  USERS_FETCH_COMMENT_LIKES_SUCCESS,
  USERS_FETCH_COMMENT_LIKES_ERROR,
} from '../userConstants'

const _fetchCommentLikes = (firebase, userId, commentIds) => {
  const commentIds10ItemsPerChunk = []
  // breaking commentIds into 10 items per chunk
  // because Firestore doesn't support "in" clause
  // with more than 10 items
  for (let i = 0, n = commentIds.length; i < n; i += 10) {
    commentIds10ItemsPerChunk.push(commentIds.slice(i, i + 10))
  }

  return Promise.all(
    commentIds10ItemsPerChunk.map(commentIds => (
      firebase
        .firestore()
        .collection('comment_likes')
        .where('userId', '==', userId)
        .where('commentId', 'in', commentIds)
        .limit(10)
        .get()
        .then(commentLikesSnapshot => {
          return commentLikesSnapshot.docs.map(commentReaction => (
            { id: commentReaction.id, ...commentReaction.data() }
          ))
      })
    ))
  ).then(commentLikes10ItemsPerChunk => (
    commentLikes10ItemsPerChunk.reduce((allCommentLikes, commentLikes) => (
      [...allCommentLikes, ...commentLikes]
    ), [])
  ))
}

export function fetchCommentLikes(commentIds) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: USERS_FETCH_COMMENT_LIKES_START })

    return new Promise((resolve, reject) => {
      if (!commentIds || commentIds.length === 0 || !getState().firebase.auth.uid) {
        resolve([])

        return dispatch({
          type: USERS_FETCH_COMMENT_LIKES_SUCCESS,
          payload: {
            data: []
          }
        })
      }

      try {
        _fetchCommentLikes(getFirebase(), getState().firebase.auth.uid, commentIds)
          .then(commentLikes => {
            resolve(commentLikes)

            dispatch({
              type: USERS_FETCH_COMMENT_LIKES_SUCCESS,
              payload: {
                data: commentLikes
              }
            })
          })
      } catch (error) {
        console.error(error)

        reject(error)

        dispatch({
          type: USERS_FETCH_COMMENT_LIKES_ERROR,
          payload: {
            error
          }
        })
      }
    })
  }
}
