import styles from './Button.module.scss'

import React from 'react'
import classnames from 'classnames'

export const Button = React.forwardRef((props, ref) => {
  let {
    className,
    style,
    kind,
    isActive,
    isBlocky = false,
    icon,
    size, // size="height" || size="height,width"
    children,
    component = 'button',
    ...restProps
  } = props

  let combinedProps = {
    className: classnames(
      styles[kind],
      {
        [styles.blocky]: isBlocky,
        [styles.active]: isActive
      },
      className
    ),
    ref,
    ...restProps
  }

  if (size) {
    const [height, width=height] = size.split(',')
    combinedProps.style = {height: `${height}px`, width: `${width}px`, ...style}
  }

  if (icon) {
    children = <>
      <span className={styles.text}>{children}</span>
      <span className={styles.icon}>{icon}</span>
    </>
  }

  return React.createElement(component, combinedProps, children)
})
