import {
  EPHIES_REACT_TO_EPHY_START,
  EPHIES_REACT_TO_EPHY_SUCCESS,
  EPHIES_REACT_TO_EPHY_ERROR,
} from '../ephyConstants'

export function reactToEphy({ channelId, ephyId, reaction }) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_REACT_TO_EPHY_START })

    return getFirebase()
      .firestore()
      .collection('reactions')
      .where('channelId', '==', channelId)
      .where('ephyId', '==', ephyId)
      .where('userId', '==', getState().firebase.auth.uid)
      .limit(1)
      .get()
      .then(reactionsSnapshot => {
        if (reactionsSnapshot.empty) {
          if (!reaction)
            throw new Error('A reaction should be provided')

          return getFirebase()
            .firestore()
            .collection('reactions')
            .add({
              channelId,
              ephyId,
              reaction,
              userId: getState().firebase.auth.uid
            })
            .then(r => ({
              currentReactionId: r.id,
              prevReaction: null,
            }))
        } else {
          const id = reactionsSnapshot.docs[0].id
          const prevReaction = reactionsSnapshot.docs[0].data()

          return (
            reaction
              ? reactionsSnapshot.docs[0].ref.update({ reaction })
              : reactionsSnapshot.docs[0].ref.delete()
            )
            .then(() => ({
              currentReactionId: id,
              prevReaction: reaction === prevReaction.reaction ? null : prevReaction
            }))
        }
      })
      .then(({ currentReactionId, prevReaction }) => {
        return dispatch({
          type: EPHIES_REACT_TO_EPHY_SUCCESS,
          payload: {
            data: {
              id: currentReactionId,
              channelId,
              ephyId,
              reaction,
              userId: getState().firebase.auth.uid,
              removeReaction: prevReaction?.reaction
            }
          }
        })
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: EPHIES_REACT_TO_EPHY_ERROR,
          payload: {
            error
          }
        })
      })
  }
}
