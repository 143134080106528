import { uniqueid } from 'utils'

const channel = {
  id: uniqueid(),
  name: 'Anna Brown',
  slug: 'anna-brown',
  photoUrl: 'https://firebasestorage.googleapis.com/v0/b/ephy-dev.appspot.com/o/users%2FwFe8vf0TsPQIjW9ylyvfRNCjzbY2%2Fphoto-oE9AjCKwqKSkWLoMUg.jpg?alt=media&token=7226d84b-c99c-43ab-af84-acb53dfbf47f',
}

const dummyPersonalChannelData = {
  channel,
  tags: [
    { id: uniqueid(), name: 'About'},
    { id: uniqueid(), name: 'My bookshelf'},
    { id: uniqueid(), name: 'Portfolio'},
    { id: uniqueid(), name: 'Future of learning'},
    { id: uniqueid(), name: 'Human design'},
  ],
  ephies: [
    {
      id: uniqueid(),
      channel,
      text: '<p>Learning has moved beyond the classroom. It is happening everywhere, all the time, formally and informally, incidentally, and intentionally—and increasingly supported by digital technologies</p><div class="image"><img src="https://firebasestorage.googleapis.com/v0/b/ephy-dev.appspot.com/o/ephies%2Fnew-ephy-1tNVHFVWK797o2ZMAA%2Faudio1.png?alt=media&token=f3d1d2ef-a270-428b-98e7-913f29d37630"/></div>',
      tags: ['Future of learning'],
      impressions: 49,
      pinned: false,
      createdAt: {
        seconds: 1608109163,
        nanoseconds: 813000000
      },
    },
    {
      id: uniqueid(),
      channel,
      text: '<p>In a new economy people can help learn each other (catch up with skills)</p><div class="image"><img src="https://firebasestorage.googleapis.com/v0/b/ephy-dev.appspot.com/o/ephies%2Fnew-ephy-M4615mM76I0A3fGPyG%2Faudio2.png?alt=media&token=a9653cd2-ca46-4def-8c1f-5bda10db260c"/></div>',
      tags: ['Future of learning'],
      impressions: 119,
      pinned: false,
      createdAt: {
        nanoseconds: 544000000,
        seconds: 1608013788

      },
    },
    {
      id: uniqueid(),
      channel,
      text: '<p>To date, our education and training systems have generally focused on the delivery and documentation of formal learning. As a result, we have fostered a society that values the accreditation of formal training and education (think college degrees) and proxy measures of aptitude (time-based promotions) rather than life experiences and direct measures of competence.</p><figure class="image"><img src="https://firebasestorage.googleapis.com/v0/b/ephy-dev.appspot.com/o/ephies%2Fnew-ephy-6hbLGwzkwSwKXxFlBj%2Fphoto5240393506449632995.jpg?alt=media&token=fa58ffb0-ff51-4c55-a4c2-746062eaabfa" /></figure>',
      tags: ['Future of learning'],
      impressions: '1.1K',
      pinned: false,
      createdAt: {
        seconds: 1606812069,
        nanoseconds: 348000000
      },
    }
  ]
}

export default dummyPersonalChannelData
