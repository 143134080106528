import styles from './Error404View.module.scss'

import React from 'react'
import { Link } from 'react-router-dom'

import NoContentMessage from 'components/NoContentMessage'
import MainLayout from 'layout/MainLayout'

const Error404View = () => (
  <MainLayout variant="onlyMain">
    <MainLayout.LeftSidebar />
    <MainLayout.MainContent>
      <div className={styles.container}>
        <NoContentMessage
          title="Page not found"
          subtitle={
            <>
              <Link to="/">Go home</Link> or <Link to="/login">login</Link>
            </>
          }
        />
      </div>
    </MainLayout.MainContent>
    <MainLayout.RightSidebar />
  </MainLayout>
)

export default Error404View
