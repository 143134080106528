import { useState, useEffect } from 'react'

export const useUrlHash = () => {
  const [urlHash, setUrlHash] = useState('')
  // eslint-disable-next-line
  useEffect(() => {
    setUrlHash(document.location.hash.slice(1))
  })
  return urlHash
}
