import SubscriptionPill from 'modules/feed/components/SubscriptionPill'

import React from 'react'

export const ChannelPill = React.forwardRef(({
  name,
  photoUrl,
  isActive = false,
  isSelected,
  onChannelClick,
}, ref) => {
  return (
    <SubscriptionPill
      ref={ref}
      pillStyle="dark"
      name={name}
      photoUrl={photoUrl}
      isActive={isActive}
      isSelected={isSelected}
      onClick={onChannelClick}
    />
  )
})
