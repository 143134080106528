import { useEffect, useRef } from "react"

export function useScrollToElement() {
  const elRef = useRef()

  useEffect(() => {
    if (elRef.current) {
      // console.log('scroll')
      const root = document.getElementById('fg_view')
      const rootStyles = window.getComputedStyle(document.documentElement)
      const headerHeight = parseInt(rootStyles.getPropertyValue('--header-height').slice(0, -2))
      const bannerHeight = parseInt(rootStyles.getPropertyValue('--banner-height').slice(0, -2))
      const elY = elRef.current.getBoundingClientRect().y

      // console.log(elY, headerHeight, bannerHeight)


      // root.scrollTop = elRef.current.getBoundingClientRect().top - 62
      setTimeout(() => {
        root.scroll({
          top: elY - headerHeight - bannerHeight + 15,
          behavior: 'smooth'
        })
      }, 500)
    }

    // window.scroll({
    //   top: elRef.current.getBoundingClientRect().top + window.scrollY - 62, // 62 = header's height + mainContent's padding-top
    //   // behavior: 'smooth'
    // })
    // }, [elRef.current])
  }, [])

  return elRef
}
