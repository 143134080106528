import styles from './InviteToChannel.module.scss'
import formStyles from 'modules/auth/Form.module.scss'

import { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import copyToClipboard from 'clipboard-copy'
import { cls, featureUsed } from 'utils'
import { useDispatch } from 'react-redux'
import * as EmailValidator from 'email-validator'

import { ChannelAvatar, ChannelCard } from 'components/ChannelInfo'
import Button from 'components/Button'
import { inviteMembersByEmail } from 'modules/channel/actions'
import { RotatingLoader } from 'components/Loader'

export default function InviteToChannel({ channel, isUserChannelAdmin, onClose }) {
  const dispatch = useDispatch()

  const {
    id,
    photoUrl,
    name,
    slug,
    private: privat3,
    inviteToken,
  } = channel

  const [userEmails, setUserEmails] = useState('')
  const [emailsValidation, setEmailsValidation] = useState({ status: true })
  const [inviteByEmailInProgress, setInviteByEmailInProgress] = useState(false)

  const channelLink = useMemo(() => {
    return privat3
      ? new URL(`/signup?redirect=${encodeURIComponent(`/join_channel/${inviteToken}`)}`, document.location).href
      : new URL(`/signup?redirect=${encodeURIComponent(`/?subscribe_to_channel=${id}`)}`, document.location).href
  }, [id, privat3, inviteToken])

  const channelLinkShort = useMemo(() => {
    return privat3
      ? `${window.location.host}/signup?redirect=${encodeURIComponent(`/join_channel/${inviteToken}`)}`
      : `${window.location.host}/signup?redirect=${encodeURIComponent(`/?subscribe_to_channel=${id}`)}`
  }, [id, privat3, inviteToken])

  function copyLink(event) {
    copyToClipboard(channelLink).then(() => {
      toast.success('Link copied to clipboard')
      featureUsed({
        action: 'channel_copy_link_to_clipboard',
        category: 'channel',
        label: 'Copy Channel link to clipboard'
      })
    })
  }

  function handleUserEmailsInput(e) {
    const input = e.target.value
    setUserEmails(input)

    if (input.split(',').length > 1) {
      setEmailsValidation(validateUserEmails(input.substring(0, input.lastIndexOf(','))))
    } else {
      setEmailsValidation({status: true})
    }
  }

  function inviteByEmail() {
    const emailsValidation = validateUserEmails(userEmails)
    if (emailsValidation.status) {
      setInviteByEmailInProgress(true)
      dispatch(inviteMembersByEmail(channel.id, userEmails))
        .then(mailCount => {
          toast.success(
            mailCount === 1
              ? `Invitational email has been sent`
              : `${mailCount} invitational emails have been sent`
            )
          onClose()
        })
        .catch(() => {
          toast.error('There was an error sending invitational emails')
        })
        .finally(() => setInviteByEmailInProgress(false))
    } else {
      setEmailsValidation(emailsValidation)
    }
  }

  function validateUserEmails(emails) {
    const emailList = emails ? emails.split(',').map(email => email.trim()) : []

    const validRequired = emailList.length > 0
    const validLength = emailList.length <= 10
    const validFormat = emailList.reduce((valid, email) => EmailValidator.validate(email) && valid, true)

    return {
      status: validFormat && validFormat && validRequired,
      details: {validFormat, validLength, validRequired}
    }
  }

  return (
    <div className={styles.container}>
      <header>
        <h1>
          Invite users to join channel
          <div className={styles.channel}>
            <ChannelAvatar {...{ name, slug, photoUrl }} photoSize={48} />
            <ChannelCard {...{ name, private: privat3, viewsCount: false, subscribersCount: false }} />
          </div>
        </h1>
      </header>
      <div className={styles.invitesControls}>
        <div className={styles.inviteLink}>
          <div className={formStyles.formControl}>
            <label>Invite by link</label>
            <input
              type="text"
              name="channel_link"
              className={formStyles.input}
              value={channelLinkShort}
              disabled={true}
            />
          </div>
          <Button
            kind="primary"
            onClick={copyLink}
            size="40, 80"
          >
            Copy Link
          </Button>
        </div>
        <div className={styles.inviteEmail}>
          <div className={cls`
            ${formStyles.formControl}
            ${!emailsValidation.status && formStyles.isInvalid}
          `}>
            <label>Invite by email</label>
            <input
              type="text"
              name="user_emails"
              maxLength="1000"
              className={formStyles.input}
              value={userEmails}
              placeholder="email1,email2,email3"
              onChange={handleUserEmailsInput}
            />
            <div className={formStyles.additionalControlInfo}>
              {!emailsValidation.details?.validRequired &&
                <small className={formStyles.invalidFeedback}>
                  Please, provide an email
                </small>
              }
              {!emailsValidation.details?.validFormat &&
                <small className={formStyles.invalidFeedback}>
                  One of the emails provided is not formatted correctly
                </small>
              }
              {!emailsValidation.details?.validLength &&
                <small className={formStyles.invalidFeedback}>
                  You may only invite 10 users at a time
                </small>
              }
            </div>
          </div>
          <Button
            kind="primary"
            onClick={inviteByEmail}
            size="40, 80"
          >
            {inviteByEmailInProgress ? <RotatingLoader color="var(--ec-on-accent-rgb)" /> : 'Invite'}
          </Button>
        </div>
      </div>
      {isUserChannelAdmin &&
        <div className={styles.manageCohost}>
          <Link
            to={{
              pathname: "/settings",
              search: `?section=channel&id=${channel.id}`,
              hash: "#admins",
            }}
          >
            Manage co-hosts
          </Link>
        </div>
      }
    </div>
  )
}
