import styles from './ProductTourAccented.module.scss'

import Button from 'components/Button'
import Spacer from 'components/Spacer'

export default function ProductTourAccented({
  content,
  currentStep,
  totalSteps,
  onSkipAllClick,
  onNextClick,
}) {
  return (
    <div className={styles.container}>
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{__html: content}}
      />
      <div className={styles.footer}>
        <div className={styles.left}>
          <Button kind="text" onClick={onSkipAllClick}>Skip Tour</Button>
          <span>&bull;</span>
          <span className={styles.stepsCounter}>
            {`${currentStep} of ${totalSteps}`}
          </span>
        </div>
        <Spacer />
        <Button kind="outlinedOnAccentSmall" onClick={onNextClick}>
          {currentStep === totalSteps ? 'Ok' : 'Next'}
        </Button>
      </div>
    </div>
  )
}
