import styles from './Comment.module.scss'
import { ReactComponent as EditIcon } from 'icons/Edit.svg'
import { ReactComponent as TrashIcon } from 'icons/Trash.svg'
import { ReactComponent as LikeIcon } from 'icons/Like.svg'

import React from 'react'
import Avatar from 'components/Avatar'
import Spacer from 'components/Spacer'
// import * as userActions from 'modules/user/actions'
import * as ephyActions from 'modules/ephy/actions'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import EphyDatetime from '../EphyDatetime'
import EphyMenu from '../EphyMenu'
import Button from 'components/Button'
import Content from 'components/Content'
import isCallable from 'is-callable'
import { Suspense } from 'react'
import { PulsingLoader } from 'components/Loader'

const CommentEditor = React.lazy(() => import('modules/ephy/components/Comments/CommentEditor'))

export default function Comment({
  comment,
  ephyId,
  channelId,
  onDeleteClick,
  onLikeClick,
  onReplyClick,
  resetIsReplying,
}) {
  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState(false)
  const [isReplying, setIsReplying] = useState(false)
  const [replyToUser, setReplyToUser] = useState(null)

  const {
    id,
    userId,
    text,
    deleted,
    likesCount,
    createdAt,
    updatedAt,
    // parentId,
  } = comment

  const user = useSelector(state => state.firebase.auth)

  const userChannels = useSelector(state => state.user.channels)

  const hasLikedComment = useSelector(state => {
    const likeRecord = state.user.commentLikes.find(like => like.commentId === comment.id)
    return likeRecord && likeRecord.userId === user.uid
  }, shallowEqual)

  const author = useSelector(state => {
    return state.user.publicUserProfiles.find(profile => profile.id === userId)
  })

  function editComment() {
    setIsEditing(true)
  }

  function deleteComment() {
    if (window.confirm('Delete comment?'))
      onDeleteClick(id)
  }

  function updateComment(commentId, text) {
    dispatch(ephyActions.updateComment({ id: commentId, text }))
    setIsEditing(false)
  }

  function likeComment() {
    if (user.isEmpty || comment.deleted) return
    onLikeClick(comment.id, !hasLikedComment)
  }

  function activateReplyToComment(author_) {
    if (isCallable(onReplyClick)) {
      onReplyClick(author_)
    } else {
      setIsReplying(true)
      setReplyToUser({ name: author_.name, username: author_.username })
    }
  }

  function resetIsReplying_() {
    if (isCallable(resetIsReplying)) {
      resetIsReplying()
    } else {
      setIsReplying(false)
      setReplyToUser(null)
    }
  }

  function resetIsEditing() {
    setIsEditing(false)
  }

  const menuItems = [
    { title: 'Edit', icon: <EditIcon />, onClick: editComment, condition: author?.id === user?.uid },
    {
      title: 'Delete',
      icon: <TrashIcon />,
      onClick: deleteComment,
      condition: author?.id === user?.uid || userChannels.find(channel => channel.id === channelId)
    },
  ]

  if (!author) return null

  if (isEditing) return (
    <Suspense fallback={<PulsingLoader />}>
      <CommentEditor
        className={styles.editor}
        comment={comment}
        initActive={true}
        onCommentEdit={updateComment}
        ephyId={ephyId}
        channelId={channelId}
        onClose={resetIsEditing}
      />
    </Suspense>
  )

  return (
    <div className={styles.root}>
      <div className={styles.comment}>
        <div className={styles.header}>
          <Avatar
            photoUrl={!author.deleted && author.photoUrl}
            name={!author.deleted && author.name}
            size={22}
            linkTo={`/users/${author.username}`}
          />
          {!author.deleted
            ? <Link to={`/users/${author.username}`} className={styles.userLink}>{author.name}</Link>
            : <span>[deleted account]</span>
          }
          <EphyDatetime datetime={createdAt} />
          {createdAt.seconds < updatedAt.seconds && <div><i>(edited)</i></div>}
          <Spacer />

          {!deleted && !user.isEmpty &&
            <EphyMenu
              items={menuItems}
            />
          }
        </div>
        <div className={styles.body}>
          {deleted
            ? <i className={styles.grayedOut}>comment deleted</i>
            : <Content content={text} />
          }
        </div>
        <div className={styles.footer}>
          <Button kind="icon" size="22" className={styles.likeBtn} onClick={likeComment}>
            {hasLikedComment
              ? <LikeIcon style={{ fill: 'var(--ec-accent' }} />
              : <LikeIcon style={{ fill: 'var(--ec-on-surface-c20' }} />}
          </Button>
          {likesCount > 0 && <div>{likesCount}</div>}
          <Spacer />
          {!deleted && !user.isEmpty &&
            <Button
              className={styles.replyBtn}
              kind="text"
              onClick={() => activateReplyToComment(author)}
            >
              Reply
            </Button>
          }
        </div>
      </div>

      <div className={styles.replies}>
        {comment?.children?.length > 0 && (
          comment.children.map(comment => (
            <Comment
              key={comment.id}
              ephyId={ephyId}
              channelId={channelId}
              comment={comment}
              onLikeClick={onLikeClick}
              onDeleteClick={onDeleteClick}
              onReplyClick={activateReplyToComment}
            />
          ))
        )}

        {isReplying && (
          <Suspense fallback={<PulsingLoader />}>
            <CommentEditor
              className={styles.editor}
              ephyId={ephyId}
              channelId={channelId}
              parentId={id}
              replyToUser={replyToUser}
              initActive={true}
              onCommentSent={resetIsReplying_}
              onClose={resetIsReplying_}
            />
          </Suspense>
        )}
      </div>
    </div>
  )
}
