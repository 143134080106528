import styles from './Channel.module.scss'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as channelActions from 'modules/channel/channelActions'
import { fetchPublicUserProfiles } from 'modules/user/userActions'

import {
  ChannelInfoButtons,
  SubscribeButton,
  UnSubscribeButton,
  ChannelCard,
  ChannelAvatar,
  ChannelDescription,
  ChannelInfoTagList,
  ChannelAdminList,
} from 'components/ChannelInfo'
import { useChannelInfo } from 'modules/channel/hooks/useChannelInfo'
import { PulsingLoader } from 'components/Loader'

export const Channel = ({channel, subscriptions, isMobile}) => {
  const dispatch = useDispatch()

  const {
    photoUrl,
    name,
    slug,
    private: privat3,
    description,
    subscribersCount,
  } = channel

  const publicUserProfiles = useSelector(({ user }) => user.publicUserProfiles)
  const admins = channel.admins
    .map(userId => publicUserProfiles.find(profile => profile.id === userId))
    .filter(Boolean)

  const auth = useSelector(({firebase}) => firebase.auth)
  const isUserChannelAdmin = useMemo(() => channel.admins?.includes(auth.uid), [channel, auth])
  const allowSubscription = useMemo(() => {
    return channel.admins?.length > 1 || !isUserChannelAdmin
  }, [isUserChannelAdmin, channel.admins])

  const tags = useSelector(state => state.channel.tags[channel.id] || [])
  const isTagsFetchInProgress = useSelector(state => state.channel.tagsFetchInProgress)

  useEffect(() => dispatch(channelActions.subscribeToChannelTags(channel.id)), [dispatch, channel.id])

  useEffect(() => {
    dispatch(fetchPublicUserProfiles(channel.admins))
  }, [dispatch, channel.admins])

  const {
    isSubscribed,
    onSubscribe,
    onUnsubscribe,
  } = useChannelInfo(channel, subscriptions, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.avatar}>
          <ChannelAvatar {...{name, slug, photoUrl, photoSize: 56}} />
        </div>

        <ChannelCard {...{name, slug, private: privat3, viewsCount: false, subscribersCount}} />

        {allowSubscription &&
          <ChannelInfoButtons>
            {allowSubscription &&
              <>
                {isSubscribed
                  ? <UnSubscribeButton onClick={onUnsubscribe} />
                  : <SubscribeButton onClick={onSubscribe} />
                }
              </>
            }
          </ChannelInfoButtons>
        }
      </div>

      <div className={styles.description}>
        <ChannelDescription text={description} />
      </div>

      {isTagsFetchInProgress ? <PulsingLoader /> :
        <>
          {tags.length > 0 &&
            <div className={styles.tags}>
              <h2>Topics</h2>
              <ChannelInfoTagList
                tags={tags}
                channel={channel}
              />
            </div>
          }
        </>
      }

      <div className={styles.hosts}>
        <h2>Hosts</h2>
        <ChannelAdminList
          admins={admins}
          showTitle={false}
          direction={isMobile ? 'vertical' : 'horizontal'}
        />
      </div>
    </div>
  )
}
