import {
  DISCOVER_CHANNEL_LIMIT,
  DISCOVER_CHANNEL_IDS,
  DISCOVER_FETCH_CHANNELS_START,
  DISCOVER_FETCH_CHANNELS_SUCCESS,
  DISCOVER_FETCH_CHANNELS_ERROR,
} from './discoverConstants'

export function fetchChannels() {
  return (dispatch, _getState, getFirebase) => {
    let cursor = 0

    dispatch({ type: DISCOVER_FETCH_CHANNELS_START })

    const createQuery = () => {
      return getFirebase()
        .firestore()
        .collection('channels')
        .where('deleted', '==', false)
        .where('private', '==', false)
        .where(getFirebase().firestore.FieldPath.documentId(), 'in', DISCOVER_CHANNEL_IDS.slice(cursor, cursor + DISCOVER_CHANNEL_LIMIT))
        .limit(DISCOVER_CHANNEL_LIMIT)
    }

    const snapshotProcessor = (channelsQuerySnapshot) => {
      cursor += DISCOVER_CHANNEL_LIMIT
      const channels = []

      channelsQuerySnapshot.docs.map(channelSnapshot => (
        channels.push({ id: channelSnapshot.id, ...channelSnapshot.data() })
      ))

      channels.sort((c1, c2) => DISCOVER_CHANNEL_IDS.indexOf(c1.id) - DISCOVER_CHANNEL_IDS.indexOf(c2.id))

      dispatch({
        type: DISCOVER_FETCH_CHANNELS_SUCCESS,
        payload: {
          data: channels
        }
      })

      return channels.length === DISCOVER_CHANNEL_LIMIT ? true : false
    }

    const errorProcessor = (error) => {
      console.error(error)

      dispatch({
        type: DISCOVER_FETCH_CHANNELS_ERROR,
        payload: {
          error
        }
      })
    }

    return {
      loadMore: () => {
        if (DISCOVER_CHANNEL_IDS.length > cursor) {
          return createQuery()
            .get()
            .then(snapshotProcessor)
            .catch(errorProcessor)
        } else {
          return Promise.resolve(false)
        }
      }
    }

  }
}
