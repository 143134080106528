import {
  CHANNELS_SEARCH_START,
  CHANNELS_SEARCH_SUCCESS,
} from '../channelConstants'

import algoliasearch from 'algoliasearch'

export function searchChannels(params = {}) {
  return (dispatch, getState, getFirebase) => {
    const { searchString } = params

    if (!searchString) {
      return new Promise((resolve) => {
        resolve ([])

        dispatch({
          type: CHANNELS_SEARCH_SUCCESS,
          payload: {
            data: []
          }
        })
      })
    }

    dispatch({ type: CHANNELS_SEARCH_START })

    const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY)
    const index = client.initIndex('channels')

    return new Promise((resolve) => {
      index
        .search(searchString)
        .then(responses => {
          // Response from Algolia:
          // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
          // console.log(responses.hits)

          const channelsList = []

          responses.hits.forEach((hit) => {
            channelsList.push(Object.assign(hit, {
              id: hit.objectID,
              name: hit.name, //hit._highlightResult.text.value,
              description: hit.description,
              slug: hit.slug,
            }))
          })

          resolve(channelsList)

          dispatch({
            type: CHANNELS_SEARCH_SUCCESS,
            payload: {
              data: channelsList
            }
          })
        })
    })
  }
}
