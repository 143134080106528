import formStyles from 'modules/auth/Form.module.scss'
// import './Account.scss'

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFirebase } from 'react-redux-firebase'
import { toast } from 'react-toastify'
import { MetaTags } from 'react-meta-tags'
import classnames from 'classnames'

import Button from 'components/Button'
import Avatar from 'components/Avatar'
import * as appActions from 'app/appActions'
import * as userActions from 'modules/user/userActions'
import ChangePassword from '../ChangePassword'
import Logout from '../Logout'

class Account extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: this.props.profile.name || '',
      username: this.props.profile.username || '',
      about: this.props.profile.about || '',
      website: this.props.profile.website || '',
      occupation: this.props.profile.occupation || '',
      photoUrl: this.props.profile.photoUrl || '',
      showErrorMessage: false,
    }

    this.firebase = getFirebase()
    this.filePickerRef = React.createRef()
  }

  onThemeChanged(event) {
    this.props.appActions.switchTheme(event.target.checked ? 'dark' : 'default')
  }

  updateProfile(event) {
    event.preventDefault()

    const formValidation = this.validateForm()
    // console.log(formValidation)

    if (!formValidation.name) { // || !formValidation.username) {
      return this.setState({
        showErrorMessage: true
      })
    }

    const profile = {
      theme: this.props.app.theme,
      email: this.props.auth.email,
      name: this.state.name,
      username: this.state.username,
      about: this.state.about,
      website: this.state.website,
      occupation: this.state.occupation,
      photoUrl: this.state.photoUrl,
    }

    this
      .firebase
      .updateProfile(profile)
      .then(() => {
        toast.success('Profile updated')
      })
  }

  validateForm() {
    return {
      name: this.state.name.trim().length > 0,
      username: /^[a-zA-Z0-9_-]+$/.test(this.state.username)
    }
  }

  uploadUserPhoto(event) {
    if (event.target.files[0]) {
      this.props.userActions.uploadUserPhoto(event.target.files[0], (photoUrl) => {
        if (photoUrl) {
          toast.success('Photo uploaded')

          this.setState({ photoUrl })
        }
      })
    }
  }

  removeUserPhoto(e) {
    e.preventDefault()

    this.setState({ photoUrl: '' }, () => toast.success('Photo removed'))
  }

  triggerFilePicker(e) {
    e.preventDefault()

    this.filePickerRef.current.click()
  }

  render() {
    const formValidation = this.validateForm()

    return (
      <>
        <MetaTags>
          <title>{`${this.props.title} – Lightspot`}</title>
        </MetaTags>

        <h1>{this.props.title}</h1>

        <section>
          <form className={formStyles.form}>
            <div className={formStyles.fileInput}>
              <label
                id="user-photo-file-label"
                htmlFor="user-photo-file">
                <Avatar
                  photoUrl={this.state.photoUrl}
                  name={this.props.profile.name}
                  size={40}
                  border={true} />
              </label>
              <input
                id="user-photo-file"
                ref={this.filePickerRef}
                type="file"
                onChange={this.uploadUserPhoto.bind(this)}
                accept="image/*" />
              <Button
                kind="text"
                htmlFor="user-photo-file"
                onClick={this.triggerFilePicker.bind(this)}
              >
                Upload avatar
              </Button>
              <Button
                kind="text"
                onClick={this.removeUserPhoto.bind(this)}
              >
                Remove avatar
              </Button>
            </div>
            <div className={formStyles.formControl}>
              <label>E-mail</label>
              <input
                type="text"
                name="email"
                className={formStyles.input}
                value={this.props.auth.email}
                disabled
              />
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: this.state.showErrorMessage && !formValidation.name } )}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                className={formStyles.input}
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value, showErrorMessage: false })}
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Hey, what's your name? :)
                </small>
              </div>
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: this.state.showErrorMessage && !formValidation.username } )}>
              <label>Username</label>
              <input
                type="text"
                name="username"
                className={formStyles.input}
                value={this.state.username}
                onChange={e => this.setState({ username: e.target.value, showErrorMessage: false })}
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Letters, numbers, "-" and "_" are allowed.
                </small>
              </div>
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: false })}>
              <label>About (optional)</label>
              <textarea
                name="about"
                maxLength="1000"
                style={{height: '70px'}}
                value={this.state.about}
                onChange={e => this.setState({ about: e.target.value, showErrorMessage: false })}
              />
              {/* <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Channel description :)
                </small>
              </div> */}
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: false } )}>
              <label>Website (optional)</label>
              <input
                type="text"
                name="website"
                className={formStyles.input}
                value={this.state.website}
                onChange={e => this.setState({ website: e.target.value, showErrorMessage: false })}
              />
              {/* <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Hey, what's your name? :)
                </small>
              </div> */}
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: false } )}>
              <label>Occupation (optional)</label>
              <input
                type="text"
                name="occupation"
                className={formStyles.input}
                value={this.state.occupation}
                onChange={e => this.setState({ occupation: e.target.value, showErrorMessage: false })}
              />
              {/* <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Hey, what's your name? :)
                </small>
              </div> */}
            </div>

            <Button
              kind="primary"
              isBlocky={true}
              onClick={this.updateProfile.bind(this)}
              disabled={this.props.fetchInProgress}
            >
              Update Profile
            </Button>
          </form>
        </section>
        <section>
          <ChangePassword />
        </section>
        <section>
          <Logout />
        </section>
      </>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  app: state.app,
  fetchInProgress: state.user.fetchInProgress,
})

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)
