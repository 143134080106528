import React, {useState} from 'react'

import TagCreateButton from '../TagCreateButton'
import TagForm from 'components/TagForm'

export function TagCreate({
  isVerbose = false,
  channelId,
  pillStyle = 'light',
  id,
}) {
  const [isEditing, setIsEditing] = useState(false)
  return (
    <>
      {isEditing ? (
          <TagForm
            id={id}
            channelId={channelId}
            onFinish={() => setIsEditing(false)}
            pillStyle={pillStyle}
          />
        ) : (
          <TagCreateButton
            id={id}
            onClick={() => setIsEditing(true)}
            isVerbose={isVerbose}
            iconStyle={pillStyle}
          />
      )}
    </>
  )
}
