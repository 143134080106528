import styles from './SettingsView.module.scss'

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQueryParams } from 'hooks'

import { MainLayout } from 'layout'
import { PulsingLoader } from 'components/Loader'
import { AdaptiveSettingsMenu } from './components/SettingsMenu'
import Account from './components/Account'
import Settings from './components/Settings'
import Import from './components/Import'
import DataManagement from './components/DataManagement'
import { ChannelSettingsView } from 'modules/channel'

const sectionWithName = name => section => section.name === name

const SettingsSections = [
  { name: 'account', title: 'Account', component: Account },
  { name: 'settings', title: 'Settings', component: Settings },
  { name: 'import', title: 'Import', component: Import },
  { name: 'data_management', title: 'Data management', component: DataManagement },
]

export default function SettingsView() {
  const queryParams = useQueryParams()
  const { section: sectionQueryParam, id: idQueryParam } = Object.fromEntries(queryParams)
  // const [{title: defaultTitle, component: defaultComponent}] = SettingsSections

  let SettingsSectionComponent = SettingsSections[0].component
  let settingsSectionProps = { title: SettingsSections[0].title }

  if (sectionQueryParam === 'channel' && idQueryParam) {
    SettingsSectionComponent = ChannelSettingsView
    settingsSectionProps = { channelId: idQueryParam }
  } else {
    const settingsSection = SettingsSections.find(sectionWithName(sectionQueryParam))
    if (settingsSection) {
      SettingsSectionComponent = settingsSection.component
      settingsSectionProps = { title: settingsSection.title }
    }
  }

  // reset settings view postion on settings section change
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const isProfileLoaded = useSelector(state => state.firebase.profile.isLoaded)
  if (!isProfileLoaded) {
    return <PulsingLoader />
  }

  return (
    <MainLayout variant="settings">
      <MainLayout.LeftSidebar>
        <AdaptiveSettingsMenu menuItems={SettingsSections} />
      </MainLayout.LeftSidebar>

      <MainLayout.MainContent>
        <div className={styles.section}>
          <SettingsSectionComponent {...settingsSectionProps} />
        </div>
      </MainLayout.MainContent>

      <MainLayout.RightSidebar />
    </MainLayout>
  )
}
