import {
  DISCOVER_FETCH_CHANNELS_START,
  DISCOVER_FETCH_CHANNELS_SUCCESS,
  DISCOVER_FETCH_CHANNELS_ERROR,
} from './discoverConstants'

const initialState = {
  channels: [],
  fetchInProgress: false,
}

export default function discoverReducer(state = initialState, { type, payload }) {
  switch (type) {
    case DISCOVER_FETCH_CHANNELS_START:
      return {...state,
        channels: [],
        fetchInProgress: true,
      }

    case DISCOVER_FETCH_CHANNELS_SUCCESS:
      return {...state,
        channels: [
          ...(state.channels.filter(channel => !payload.data.find(newChannel => newChannel.id === channel.id))),
          ...payload.data
        ],
        fetchInProgress: false,
      }

    case DISCOVER_FETCH_CHANNELS_ERROR:
      return {...state,
        fetchInProgress: false
      }

    default:
      return state
  }
}
