import styles from './DataManagement.module.scss'

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFirebase } from 'react-redux-firebase'
import { uniqueid } from 'utils'
import { toast } from 'react-toastify'
import { push } from 'connected-react-router'
import isCallable from 'is-callable'

// can be used to rebuild static JSON file at /public/data/patrick-collison.json
// import PatrickCollisonData from './data/PatrickCollison.js'

import Button from 'components/Button'
import * as channelActions from 'modules/channel/channelActions'
import * as userActions from 'modules/user/userActions'
import * as ephyActions from 'modules/ephy/ephyActions'
import * as tagActions from 'modules/tag/tagActions'
import { MetaTags } from 'react-meta-tags'

import {
  USERS_DATA_PROCESSING_START,
  USERS_DATA_PROCESSING_SUCCESS,
  // USERS_DATA_PROCESSING_ERROR,
} from 'modules/user/userConstants'

class DataManagement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      importFile: '',
    }

    this.firebase = getFirebase()
    this.handleChange = this.handleChange.bind(this)
  }

  removeContent(callback) {
    const unsubscribeFromUserChannels = this.props.userActions.subscribeToChannels(async channels => {
      unsubscribeFromUserChannels()

      // channels, tags and ephies
      channels.forEach(channel => {
        if (channel.createdBy === this.props.auth.uid) {
          this.props.channelActions.deleteChannel(channel)
        }
      })

      // private ephies
      const privateEphiesSubscription = this.props.userActions.subscribeToPrivateEphies()

      let hasMore = true
      while (hasMore) hasMore = await privateEphiesSubscription.loadMore()
      privateEphiesSubscription.unsubscribe()

      await Promise.all(this.props.privateEphies.map(ephy => (
        this.props.ephyActions.deleteEphy(ephy)
      )))

      // private tags
      const unsubscribeFromTags = this.props.userActions.subscribeToPrivateTags(async tags => {
        unsubscribeFromTags()

        await Promise.all(tags.map(tag => (
          this.props.tagActions.deleteTag(tag)
        )))

        if (isCallable(callback)) {
          callback()
        }
      })
    })
  }

  deleteContent() {
    this.props.dispatch({ type: USERS_DATA_PROCESSING_START })

    this.removeContent(() => {
      this.props.dispatch({ type: USERS_DATA_PROCESSING_SUCCESS })
      toast.success(
        'All data was removed',
        { autoClose: 3000 }
      )
    })
  }

  deleteAccount() {
    this.props.dispatch({ type: USERS_DATA_PROCESSING_START })

    this.removeContent(() => {
      const user = this.firebase.auth().currentUser

      user.delete().then(() => {
        this.props.dispatch({ type: USERS_DATA_PROCESSING_SUCCESS })
        toast.success(
          'All data and user account was removed',
          { autoClose: 3000 }
        )
      }).catch((error) => {
        console.error(error)

        toast.error(
          'All data was removed, but the user was not deleted. Try to login and try again',
          { autoClose: 3000 }
        )
      })
    })
  }

  importFromPatrickCollison() {
    const importPC = (data) => {
      this.props.channelActions.createChannel({
        photoUrl: 'https://patrickcollison.com/static/profile.jpg',
        name: 'Patrick Collison',
        slug: `patrick-collison-${uniqueid()}`
      }, { createGetStartedData: false }).then(channel => {

        const dataPromises = []

        // Tags
        data['tags'].forEach((tag, index) => {
          dataPromises.push(
            this.props.tagActions.createTag({
              name: tag,
              order: index
            }, { channel })
          )
        })

        // Ephies
        const singleRecordTags = [
          'About', 'Advice', 'Bookshelf', 'Culture', 'Fast', 'Growth', 'Labs',
          'Links', 'People', 'Pollution', 'Progress', 'Questions', 'SV history', 'Travel', 'Work'
        ]
        singleRecordTags.forEach(tag => {
          dataPromises.push(
            this.props.ephyActions.createEphy({
              tags: [tag],
              text: data[tag],
            }, { channel })
          )
        })

        data['Blog'].forEach(blogPost => {
          dataPromises.push(
            this.props.ephyActions.createEphy({
              tags: ['Blog'],
              text: blogPost,
            }, { channel })
          )
        })

        Promise.all(dataPromises).then(() => {
          this.props.dispatch({ type: USERS_DATA_PROCESSING_SUCCESS })
          toast.success(
            <>Data from patrickcollison.com imported.<br/>Click to check it out!</>,
            {
              autoClose: 5000,
              onClick: () => {
                this.props.dispatch(push(`/${channel.slug}`))
              }
            }
          )
        })
      })
    }

    this.props.dispatch({ type: USERS_DATA_PROCESSING_START })

    const dataRequest = new XMLHttpRequest()
    const listener = function () { importPC(this.response) }
    dataRequest.responseType = 'json'
    dataRequest.addEventListener('load', listener)
    dataRequest.open('GET', '/data/patrick-collison.json')
    dataRequest.send()
  }

  importData() {
    const inputField = document.getElementById('import_file')
    const selectedFiles = inputField.files

    if (!selectedFiles[0]) {
      return window.alert('Please, select a JSON file for import')
    }

    this.props.dispatch({ type: USERS_DATA_PROCESSING_START })

    const reader = new FileReader()
    const _this = this

    reader.onload = async (loadedEvent) => {
      const importData = JSON.parse(loadedEvent.target.result)

      if (importData.version === 2) {
        await _this.importDataV2(importData)

        _this.props.dispatch({ type: USERS_DATA_PROCESSING_SUCCESS })
        toast.success(
          'Import completed successfully!',
          { autoClose: 3000, }
        )
      } else {
        toast.error(
          'File format is not supported',
          { autoClose: 3000 }
        )
      }
    }

    reader.readAsText(selectedFiles[0])
  }

  importDataV2(data) {
    return Promise.all([
      ...data.channels.map(channelData => {
        return this.props.channelActions.createChannel({
          photoUrl: channelData.photoUrl,
          name: channelData.name,
          description: channelData.description,
          type: channelData.type,
          slug: channelData.slug,
        }, { createGetStartedData: false }).then(channel => {
          channelData.tags.forEach((tag, idx) => {
            this.props.tagActions.createTag({
              name: tag.name,
              order: idx,
            }, { channel })
          })

          channelData.ephies.forEach((ephy) => {
            this.props.ephyActions.createEphy({
              tags: ephy.tags,
              text: ephy.text,
            }, { channel })
          })
        })
      }),
      ...data.drafts.tags.map((tag, idx) => (
        this.props.tagActions.createTag({
          name: tag.name,
          order: idx,
        })
      )),
      ...data.drafts.ephies.map((ephy) => (
        this.props.ephyActions.createEphy({
          tags: ephy.tags,
          text: ephy.text,
        })
      ))
    ])
  }

  exportData() {
    this.props.dispatch({ type: USERS_DATA_PROCESSING_START })

    const unsubscribeFromUserChannels = this.props.userActions.subscribeToChannels(async channels => {
      unsubscribeFromUserChannels()

      // exporting channels
      const channelsData = await Promise.all(
        channels.map(async channel => {
          const ephiesSubscription = this.props.channelActions.subscribeToChannelEphies(channel.id)

          let hasMore = true
          while (hasMore) hasMore = await ephiesSubscription.loadMore()
          ephiesSubscription.unsubscribe()

          channel.ephies = [...this.props.channelEphies]

          channel.tags = await new Promise(resolve => {
            const unsubscribeFromTags = this.props.channelActions.subscribeToChannelTags(channel.id, tags => {
              unsubscribeFromTags()
              resolve(tags)
            })
          })

          return channel
        })
      )

      // exporting drafts
      const draftsData = await Promise.all([
        new Promise(async (resolve) => {
          const privateEphiesSubscription = this.props.userActions.subscribeToPrivateEphies()

          let hasMore = true
          while (hasMore) hasMore = await privateEphiesSubscription.loadMore()
          privateEphiesSubscription.unsubscribe()

          resolve([...this.props.privateEphies])
        }),
        new Promise((resolve) => {
          const unsubscribeFromTags = this.props.userActions.subscribeToPrivateTags(tags => {
            unsubscribeFromTags()
            resolve(tags)
          })
        }),
      ])

      // export to file
      const data = {
        version: 2,
        channels: channelsData,
        drafts: {
          ephies: draftsData[0],
          tags: draftsData[1],
        }
      }

      const saveData = (function () {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        return (data, fileName) => {
          const json = JSON.stringify(data)
          const blob = new Blob([json], { type: 'octet/stream' })
          const url = window.URL.createObjectURL(blob)

          a.href = url
          a.download = fileName
          a.click()
          window.URL.revokeObjectURL(url)
        }
      }())

      saveData(data, 'ephy-export.json')

      this.props.dispatch({ type: USERS_DATA_PROCESSING_SUCCESS })
      toast.success(
        'Export completed successfully!',
        { autoClose: 3000, }
      )
    })
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({
      importFile: e.target.files[0]
    })
  }

  render() {
    return (
      <>
        <MetaTags>
          <title>{this.props.title} – settings</title>
        </MetaTags>
        <h1>{this.props.title}</h1>
        <div className={styles.body}>
          <div>
            <p>
              <span role="img" aria-label="Export">⬆️</span> Exports all channels, topics and posts to a file in JSON format.
              Can be used for data backup and further import
            </p>
            <Button
              kind="primary"
              isBlocky={true}
              onClick={this.exportData.bind(this)}
              disabled={this.props.dataProcessingInProgress}
            >
              Export to file
            </Button>
          </div>

          <div>
            <p>
              <span role="img" aria-label="Import">⬇️</span> Imports channels, topics and posts from a file in JSON format
              (the one you received by clicking Export above).
              Does not overwrite existing channels, topics and posts
            </p>
            <label className={styles.fileButton} >
              <input type="file" id="import_file" onChange={this.handleChange} />
              Choose file
            </label>
            {this.state.importFile && <p>{this.state.importFile.name}</p>}
            <Button
              kind="primary"
              isBlocky={true}
              onClick={this.importData.bind(this)}
              disabled={this.props.dataProcessingInProgress}
            >
              Import from file
            </Button>
          </div>

          <div>
            <p>
              Creates a new channel and fills it with topics and posts
              from <a href="https://patrickcollison.com" target="_blank" rel="noopener noreferrer">patrickcollison.com</a>.
              Check how your knowledge and opinion may look like 🙂
            </p>
            <Button
              kind="primary"
              isBlocky={true}
              onClick={this.importFromPatrickCollison.bind(this)}
              disabled={this.props.dataProcessingInProgress}
            >
              Import from Patrick Collison
            </Button>
          </div>

          <div>
            <p>
              <span role="img" aria-label="Delete">🚮</span> Deletes all your channels, topics and posts giving you a fresh start.
              Make sure to backup first
            </p>

            <Button
              kind="dangerous"
              isBlocky={true}
              onClick={() => window.confirm('Delete all channels, topics and posts?') && this.deleteContent()}
              disabled={this.props.dataProcessingInProgress}
            >
              Delete all content
            </Button>
          </div>

          <div>
            <p>
              <span role="img" aria-label="Delete">❌</span> Deletes your account completely
            </p>

            <Button
              kind="dangerous"
              isBlocky={true}
              onClick={() => {
                window.confirm('Delete all channels, topics, posts and your account?')
                  && window.confirm('Click OK to proceed with deletion')
                  && this.deleteAccount()
              }}
              disabled={this.props.dataProcessingInProgress}
            >
              Delete account
            </Button>
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  layout: state.layout,
  dataProcessingInProgress: state.user.dataProcessingInProgress,
  channelEphies: state.channel.ephies,
  privateEphies: state.user.ephies,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  channelActions: bindActionCreators(channelActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
  ephyActions: bindActionCreators(ephyActions, dispatch),
  tagActions: bindActionCreators(tagActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DataManagement)
