import styles from './FeedView.module.scss'

import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import MainLayout from 'layout/MainLayout'
import SubscriptionsList from './components/SubscriptionsList'
import EphiesList from 'modules/ephy/components/EphiesList'
import { PulsingLoader } from 'components/Loader'
import * as feedActions from 'modules/feed/feedActions'
import NoContentMessage from 'components/NoContentMessage'
import { useQueryParams } from 'hooks'
// import { fetchUserReactions } from 'modules/user/userActions'

export default function FeedView() {
  const dispatch = useDispatch()

  const ephies = useSelector(({feed}) => feed.ephies)
  const subscriptions = useSelector(({feed}) => feed.subscriptions)

  const [hasMoreEphies, setHasMoreEphies] = useState(true)
  const [activeSubscription, setActiveSubscription] = useState(null)
  const onSubscriptionClick = useCallback(
    (id) => {
      id === activeSubscription ? setActiveSubscription(null) : setActiveSubscription(id)
    }, [activeSubscription]
  )

  // EXAMPLE for fetching reactions
  // useEffect(() => {
    // if (ephies.length > 0) {
      // dispatch(fetchUserReactions(ephies.map(ephy => ephy.id)))
    // }
  // }, [ephies, dispatch])

  const history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname === '/') setActiveSubscription(null)
    })
  }, [history])

  useEffect(
    () => {
      setHasMoreEphies(true)
    }, [activeSubscription]
  )

  const [userFeedSubscription, setUserFeedSubscription] = useState(null)
  useEffect(() => {
    const userFeedSubscription = dispatch(
      feedActions.subscribeToUserFeed({ channelId: activeSubscription })
    )
    setUserFeedSubscription(userFeedSubscription)

    return () => userFeedSubscription.unsubscribe()
  }, [dispatch, activeSubscription])

  const {
    subscribe_to_channel: subscribeToChannelId,
    subscribe_to_tag: subscribeToTagId
  } = Object.fromEntries(useQueryParams())
  useEffect(() => {
    if (subscribeToChannelId) {
      if (subscribeToTagId) {
        dispatch(feedActions.subscribeUserToChannelTag({ id: subscribeToChannelId }, { id: subscribeToTagId }))
      } else {
        dispatch(feedActions.subscribeUserToChannel({ id: subscribeToChannelId }))
      }
    }
  }, [dispatch, subscribeToChannelId, subscribeToTagId])

  const loadMoreEphies = useCallback(
    () => {
      if (!userFeedSubscription) return

      userFeedSubscription.loadMore().then((lastEphy) => {
        const hasMore = !!lastEphy
        if (hasMore !== hasMoreEphies) {
          setHasMoreEphies(hasMore)
        }
      })
    }, [userFeedSubscription, hasMoreEphies]
  )

  const emptyMessage = () => (
    <div className={styles.noContent}>
      <NoContentMessage
        title={
          <>
            Search and subscribe for channels to see interesting content and engage with
            new people <i>or</i> <Link to="/discover">discover new channels</Link>
          </>
        }
      />
    </div>
  )

  const profile = useSelector(({firebase}) => firebase.profile)
  const subscriptionsFetchInProgress = useSelector(({feed}) => feed.fetchInProgress)
  const feedFetchInProgress = useSelector(({feed}) => feed.feedFetchInProgress)

  if (!profile.isLoaded || subscriptionsFetchInProgress)
    return <PulsingLoader />

  return (
    <>
      <MetaTags>
        <title>Feed – Lightspot</title>
        <meta property="og:title" content="Feed – Lightspot" />
        <meta name="description" content="Feed – Lightspot" />
        <meta property="og:description" content="Feed – Lightspot" />
      </MetaTags>
      <MainLayout variant="feed">
        <MainLayout.LeftSidebar />

        <MainLayout.MainContent>
          {feedFetchInProgress
            ? <PulsingLoader position="fixed" />
            : <EphiesList
                ephies={ephies}
                emptyMessage={emptyMessage}
                isChannelAdmin={false}
                loadMoreEphies={loadMoreEphies}
                hasMoreEphies={hasMoreEphies}
                showChannelAvatar={true}
              />
          }
        </MainLayout.MainContent>
        <MainLayout.RightSidebar>
          {subscriptions.length > 0 &&
            <SubscriptionsList
              subscriptions={subscriptions}
              activeSubscription={activeSubscription}
              onSubscriptionClick={onSubscriptionClick}
            />
          }
        </MainLayout.RightSidebar>
      </MainLayout>
    </>
  )
}
