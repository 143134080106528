import styles from './Menu.module.scss'

import React from 'react'
// import PropTypes from 'prop-types'
import classnames from 'classnames'

const atLeastOneChildHasProp = (children, propName) => {
  for (const child of React.Children.toArray(children)) {
    if (child.props[propName]) return true
  }
  return false
}

export function Menu({
  className,
  variety,
  style={},
  children,
  menuWidth = 140,
  ...restProps
}) {

  const menuWithIcons = atLeastOneChildHasProp(children, 'icon')

  className = classnames(
    variety ? styles[variety] : styles['menu'],
    {
      [styles.noIcon]: !menuWithIcons
    },
    className
  )

  style = {...style, width: `${menuWidth}px`}

  return (
    <ul className={className} style={style} {...restProps}>
      {children}
    </ul>
  )
}

Menu.Item = MenuItem
Menu.Divider = MenuDivider

Menu.propTypes = {

}

// export function MenuContainer(props) {
//   return <div {...props} /></div>
// }

export function MenuItem(props) {
  let {
    className,
    style = {},
    isActive,
    isHighlighted,
    children,
    icon,
    iconPosition = 'left',
    ...restProps} = props

  className = classnames(
    styles.menuItem,
    {
      [styles.active]: isActive,
      [styles.highlighted]: isHighlighted,
      [styles.iconRight]: iconPosition === 'right'
    },
    className
  )

  return (
    <li className={className} style={style} {...restProps}>
      <div className={styles.itemIcon}>{icon}</div>
      <div className={styles.itemTitle}>{children}</div>
    </li>
  )
}

export function MenuDivider(props) {
  return (
    <hr className={styles.menuDivider} />
  )
}
