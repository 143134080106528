import styles from './RenameBanner.module.scss'

import { useState } from 'react'

import Button from 'components/Button'
import { AccentLineBanner } from './AccentLineBanner'
import { useQueryParams } from 'hooks'

export function RenameBanner() {
  const {from_ephy} = Object.fromEntries(useQueryParams())

  if (from_ephy === "1" && localStorage.closedRenameBanner !== "1") {
    localStorage.showRenameBanner = "1"
  }

  const [needShow, setNeedShow] = useState(localStorage.showRenameBanner === "1")

  const bannerSize = 35

  function hideBanner() {
    localStorage.closedRenameBanner = "1"
    localStorage.showRenameBanner = "0"
    document.documentElement.style.setProperty('--banner-height', `${0}px`)
    setNeedShow(false)
  }

  if (!needShow) return null

  if (needShow) {
    document.documentElement.style.setProperty('--banner-height', `${bannerSize}px`)
  }

  return (
    <AccentLineBanner style={{height: bannerSize}}>
      <div className={styles.container}>
        <span>
          Ephy becomes Lightspot. <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/lightspot/Ephy-becomes-Lightspot-990f8a2803384a898a6b2d8ca8fddc70"
            onClick={hideBanner}
          >
            Read more.
          </a>
        </span>
        <Button
          kind="secondary"
          size="20, 70"
          onClick={hideBanner}
        >
          Ok, cool!
        </Button>
      </div>
    </AccentLineBanner>
  )
}
