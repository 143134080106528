import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import { ReactComponent as CreateIcon } from './PlusS.svg'
import { featureUsed } from 'utils'
import TypebarDispatch from 'modules/ephy/components/Typebar/typebarContext'

const CreateEphyMenuItem = () => {
  const typebarDispatch = useContext(TypebarDispatch)
  return (
    <Button
      kind="iconBordered"
      size="40"
      onClick={() => {
        window.requestAnimationFrame(() => typebarDispatch({type: 'ephy/clickedNew'}))
        featureUsed({ action: 'create_ephy_from_header_bar', category: 'ephy', label: 'Create ephy from header bar' })
      }}
      title="Create post"
    >
      <CreateIcon />
    </Button>
  )
}

CreateEphyMenuItem.propTypes = {
  onEphyCreatePressed: PropTypes.func
}

export default CreateEphyMenuItem
