import { ProductTour } from 'components/ProductTour'
// import ProductTourDefalt from './tourTemplates/ProductTourDefault'
import { useDispatch, useSelector } from 'react-redux'
import ProductTourAccented from './tourTemplates/ProductTourAccented'
import {ROOKIE_OWN_CHANNEL} from './tourDefinitions/rookieOwnChannel'
import { acknowledgeHint } from 'modules/user/userActions'
import { featureUsed } from 'utils'
// import { useState } from 'react'


export default function RookieOwnChannelTour() {
  const dispatch = useDispatch()

  const hintsSeenStatus = useSelector(state => state.firebase.profile.hints)

  return (
    <ProductTour
      hints={ROOKIE_OWN_CHANNEL}
      hintsSeenStatus={hintsSeenStatus}
      onSkipAllClick={(hints) => {
        hints.forEach(hint => dispatch(acknowledgeHint(hint.name)))
        featureUsed({ action: 'skip_hints', category: 'channel', label: 'Skip channel tour' })
      }}
      onNextClick={(hint) => {
        dispatch(acknowledgeHint(hint.name))
        featureUsed({ action: 'hint_acknowledged', category: 'channel', label: `Hint ${hint.name} acknowledged` })
      }}
      onHintTargetNotFound={(hint) => dispatch(acknowledgeHint(hint.name))}
      HintTemplateComponent={ProductTourAccented}
    />
  )
}

/* export default function RookieOwnChannelTour() {
  const [hintsSeenStatus, setHintsSeenStatus] = useState({})

  return (
    <ProductTour
      hints={ROOKIE_OWN_CHANNEL}
      hintsSeenStatus={hintsSeenStatus}
      onSkipAllClick={(hints) => {
        hints.forEach(hint => setHintsSeenStatus(prevState => ({...prevState, [hint.name]: true})))
      }}
      onNextClick={(hint) => {
        setHintsSeenStatus(prevState => ({...prevState, [hint.name]: true}))
      }}
      onHintTargetNotFound={(hint) => {
        setHintsSeenStatus(prevState => ({...prevState, [hint.name]: true}))
      }}
      HintTemplateComponent={ProductTourAccented}
    />
  )
} */
