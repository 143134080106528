import styles from './HelloUser.module.scss'
import PromoImg from './Promo.png'

import React, {
} from 'react'

import SplitPaneLayout from 'layout/SplitPaneLayout'
import { OnboardingForm } from './OnboardingForm'
import { PromoContent } from './PromoContent'

const PrivacyPolicy = () => (
  <div className={styles.legal}>
    <span>By signing up you accept</span>

    <br/>

    <a
      href="https://www.notion.so/lightspot/Lightspot-Privacy-Policy-ba406e2f3e404ae4a896048d2e6c0d10"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
    <span>&nbsp;and&nbsp;</span>
    <a
      href="https://www.notion.so/lightspot/Lightspot-Terms-of-Use-f56a351cc92a4a85b72647f71aaea320"
      target="_blank"
      rel="noreferrer"
    >
      Terms of Use
    </a>
  </div>
)

// const WatchVideo = () => {
//   const [playVideo, setPlayVideo] = useState(false)

//   if (!playVideo) return (
//     <Button kind="text">
//       <PlayIcon style={{ width: "22px", height: "22px" }} onClick={() => setPlayVideo(true)} />
//       <span onClick={() => setPlayVideo(true)}>Watch video</span>
//     </Button>
//   )

//   return (
//     <iframe
//       width="100%"
//       src="https://www.youtube.com/embed/C-1KEzO5pEI?autoplay=1"
//       frameBorder="0"
//       allow="accelerometer;
//               autoplay;
//               clipboard-write;
//               encrypted-media;
//               gyroscope;
//               picture-in-picture"
//       allowFullScreen
//       title="Lightspot Promo"
//     />
//   )
// }

export const HelloUser = ({showPolicy, children}) => {
  // const [playVideo, setPlayVideo] = useState(false)

  return (
    <>
    <SplitPaneLayout
      leftPane={
        <OnboardingForm
          title='Welcome to Lightspot'
          subtitle='Bring your insights online!'
          // mobilePromo={<WatchVideo />}
          footer={showPolicy ? <PrivacyPolicy /> : undefined}>
            {children}
        </OnboardingForm>
      }
      rightPane={
        <PromoContent>
          <div className={styles.container}>
            <img src={PromoImg} alt="Promo" />
            {/* <div>

            {playVideo &&
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/C-1KEzO5pEI?autoplay=1"
                frameBorder="0"
                allow="accelerometer;
                       autoplay;
                       clipboard-write;
                       encrypted-media;
                       gyroscope;
                       picture-in-picture"
                allowFullScreen
                title="Lightspot Promo"
              />
            }
            <img src={promoVideoPlaceholder} alt="Lightspot Promo" />
            {!playVideo && <PlayIcon onClick={() => setPlayVideo(true)} />}
            </div> */}
          </div>
        </PromoContent>
      }
    />
    </>
  )
}
