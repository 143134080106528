import { useLayoutEffect } from 'react'

export function useLockBodyScroll(
  controller = {current: true}
) {
  useLayoutEffect(() => {
    if (!controller.current) return
    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = 'hidden'
    return () => document.body.style.overflow = originalStyle
  }, [controller])
}
