import {
  CHANNELS_EPHY_LIMIT,
  CHANNELS_FETCH_EPHIES_START,
  CHANNELS_FETCH_EPHIES_SUCCESS,
  CHANNELS_FETCH_EPHIES_ERROR,
} from '../channelConstants'

import { createRecordsLoader, SNAPSHOT_PROCESSOR_EPHY } from 'utils/infiniteScroll'

export function subscribeToChannelEphies(channelId, params = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: CHANNELS_FETCH_EPHIES_START })

    const { id, tag, bumpImpressionsCount: bumpImpressions = false } = params

    const createQuery = ({ startAfter, endBefore } = {}) => {
      let query = getFirebase()
        .firestore()
        .doc(`channels/${channelId}`)
        .collection('ephies')

      if (id) {
        query = query
          .where(getFirebase().firestore.FieldPath.documentId(), '==', id)
          .limit(1)
      } else {
        if (tag) {
          query = query.where('tags', 'array-contains', tag)
        }

        query = query
          .orderBy('pinned', 'desc')
          .orderBy('createdAt', 'desc')
          .limit(CHANNELS_EPHY_LIMIT)
      }

      if (startAfter) {
        query = query.startAfter(startAfter)
      }

      if (endBefore) {
        query = query.endBefore(endBefore)
      }

      return query
    }

    const { load, loadMore, unsubscribe } = createRecordsLoader({
      createQuery,
      dispatch,
      limit: CHANNELS_EPHY_LIMIT,
      successAction: CHANNELS_FETCH_EPHIES_SUCCESS,
      errorAction: CHANNELS_FETCH_EPHIES_ERROR,
      snapshotProcessor: SNAPSHOT_PROCESSOR_EPHY,
      bumpEphyImpressions: bumpImpressions,
    })

    load()

    return {
      unsubscribe,
      loadMore
    }
  }
}
