import React from 'react'

export default function nl2br(text) {
  return ('' + text)
    .split(/(?:\r\n|\r|\n)/)
    .reduce(
      (res, frag, i, arr) => [
        ...res,
        frag,
        ...(i < arr.length - 1 ? [React.createElement('br', { key: i })] : [])
      ],
      []
    )
}
