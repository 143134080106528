import styles from './SettingsMenu.module.scss'
import { ReactComponent as LockIcon } from 'icons/Lock.svg'

import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { useQueryParams } from 'hooks'
import Menu from 'components/Menu'
import Avatar from 'components/Avatar'

export function SettingsMenu({ menuItems, onClick }) {
  const channels = useSelector(state => state.user.channels)
  const queryParams = useQueryParams()
  const {
    section: sectionQueryParam = menuItems[0].name,
    id: idQueryParam
  } = Object.fromEntries(queryParams)
  const menuWidth = 228

  return (
    <div onClick={onClick}>
      <Menu {...{menuWidth}} variety="settingsSectionMenu">
        {menuItems.map((menuItem, index) => (
          <Menu.Item key={index} isActive={menuItem.name === sectionQueryParam}>
            <Link to={`?section=${menuItem.name}`}>{menuItem.title}</Link>
          </Menu.Item>
        ))}
      </Menu>
      {/* <Menu.Divider></Menu.Divider> */}
      <Menu {...{menuWidth}} variety="settingsChannelsMenu">
        {channels.map(channel => (
          <Menu.Item
            className={classnames({[styles.private]: channel.private})}
            title={channel.private ? 'Private channel' : ''}
            isActive={channel.id === idQueryParam}
            icon={<Avatar
              photoUrl={channel.photoUrl}
              linkTo={`?section=channel&id=${channel.id}`}
              name={channel.name}
              size={28} />}
            key={channel.id}>
            {channel.name}
            {channel.private && <LockIcon />}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  )
}
