// import styles from './EphyChannelAvatar.module.scss'

import React from 'react'

import Avatar from 'components/Avatar'
// import { useBreakpoints } from 'hooks'

export const EphyChannelAvatar = ({
  channel,
  // showOnDesktop
}) => {
  // const {isMobile} = useBreakpoints()
  return (
    <>
      {/* {(showOnDesktop || isMobile) && */}
      <Avatar
        // className={styles.channelAvatar}
        photoUrl={channel.photoUrl}
        linkTo={`/${channel.slug}`}
        name={channel.name}
        size={25}
        // border={true}
      />
      {/* } */}
    </>
  )
}
