import styles from './Tooltip.module.scss'

import React, { useState } from 'react'

import { Overlay } from 'components/Overlay'
import { usePopper } from 'react-popper'

export function Tooltip({children, content, placement}) {
  // children = React.children.only(children)
  const [visible, setVisible] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  // const [arrowElement, setArrowElement] = useState(null)

  const {
    styles: popperStyles,
    attributes: popperAttributes,
  } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: 'offset', options: {offset: [0, 8]} },
      // { name: 'arrow', options: {element: arrowElement} },
    ],
  })
  return (
    <>
    {React.cloneElement(children, {
      ref: setReferenceElement,
      onMouseEnter: () => setVisible(true),
      onMouseLeave: () => setVisible(false),
      onTouchStart: () => setVisible(true),
      onTouchEnd: () => setTimeout(() => setVisible(false), 2000)
    })}
    {visible &&
    <Overlay>
      <div
        className={styles.container}
        ref={setPopperElement}
        style={{
          ...popperStyles.popper,
        }}
        {...popperAttributes.popper}
      >
        {content}
        {/* <div
          className={styles.arrow}
          ref={setArrowElement}
          style={{...popperStyles.arrow}}
        /> */}
      </div>
    </Overlay>
    }
    </>
  )
}
