import { useSelector } from 'react-redux'
import { Switch, Route, useLocation } from 'react-router'

import { Logout, LoginView, SignupView, PasswordResetView, ConfirmPasswordResetView } from 'modules/auth'
import { PrivateEphiesView, SettingsView } from 'modules/user'
import { ProfileView } from 'modules/profile'
import { CreateChannelView, ChannelView, JoinPrivateChannelView } from 'modules/channel'
import { FeedView } from 'modules/feed'
import { EphyRedirectView, EphyView } from 'modules/ephy'
import { DiscoverView } from 'modules/discover'
import { Error404View } from 'modules/error'
import { HelpView } from 'modules/help'
import { PrivacyPolicyView } from 'modules/policies'
import { LandingPageView } from 'modules/landing'
import { AppLayout } from 'layout'
import { notLoggedIn, requireAuthentication } from 'utils/auth'
import DiscoverOnboardingView from 'modules/discover/DiscoverOnboardingView'
import ModalView from 'components/ModalView'

// share a single AppLayout instance between routes
// using <AppLayout><Route /></AppLayout> directly will create a new
// AppLayout instance each time
const AppRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={routeProps => <AppLayout><Component {...routeProps} /></AppLayout>} />
)

const ModalRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={routeProps => <ModalView component={Component} routeProps={routeProps} />} />
)

// applying HOCs here to prevent instance recreation of components
const LoginViewNonAuthenticated = notLoggedIn(LoginView)
const SignupViewNonAuthenticated = notLoggedIn(SignupView)
const PasswordResetViewNonAuthenticated = notLoggedIn(PasswordResetView)
const ConfirmPasswordResetViewNonAuthenticated = notLoggedIn(ConfirmPasswordResetView)
const CreateChannelViewAuthenticated = requireAuthentication(CreateChannelView)
const DiscoverOnboardingViewAuthenticated = requireAuthentication(DiscoverOnboardingView)
const SettingsViewAuthenticated = requireAuthentication(SettingsView)
const PrivateEphiesViewAuthenticated = requireAuthentication(PrivateEphiesView)
const JoinPrivateChannelViewAuthenticated = requireAuthentication(JoinPrivateChannelView)

export default function Routes() {
  const isAuthenticated = !useSelector(state => state.firebase.auth.isEmpty)

  const fgLocation = useLocation()
  const bgLocation = fgLocation.state?.bgLocation

  return (
    <>
    <Switch location={bgLocation || fgLocation}>
      {/* Feed and Landing */}
      {/* cannot use connected react router utils */}
      {/* since FeedView and LandingPageView require different layout wrappers */}
      {isAuthenticated
        ? <AppRoute exact path="/" component={FeedView} />
        : <Route exact path="/" component={LandingPageView} />}

      {/* Auth */}
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/login" component={LoginViewNonAuthenticated} />
      <Route exact path="/signup" component={SignupViewNonAuthenticated} />
      <Route exact path="/reset_password" component={PasswordResetViewNonAuthenticated} />
      <Route exact path="/reset_password/confirm" component={ConfirmPasswordResetViewNonAuthenticated} />

      {/* Onboarding */}
      <Route exact path="/welcome" component={DiscoverOnboardingViewAuthenticated} />
      <Route exact path="/welcome/create_channel" component={CreateChannelViewAuthenticated} />

      {/* Account */}
      <AppRoute exact path="/settings" component={SettingsViewAuthenticated} />
      <AppRoute exact path="/drafts/:tag?" component={PrivateEphiesViewAuthenticated} />

      {/* Users */}
      <AppRoute exact path="/users/:username" component={ProfileView} />

      {/* Search */}
      <AppRoute exact path="/search" component={null} />

      {/* Discover */}
      <AppRoute exact path="/discover" component={DiscoverView} />

      {/* Static */}
      <AppRoute exact path="/help" component={HelpView} />
      <AppRoute exact path="/privacy" component={PrivacyPolicyView} />

      {/* Channels */}
      <AppRoute exact path="/p/:channel_id/:ephy_id" component={EphyRedirectView} />
      <AppRoute exact path="/join_channel/:invite_token" component={JoinPrivateChannelViewAuthenticated} />
      <AppRoute exact path="/:channel_slug/p/:ephy_slug" component={ChannelView} />
      <AppRoute exact path="/:channel_slug/:tag?" component={ChannelView} />

      {/* Default 404 */}
      <Route component={Error404View} />
    </Switch>

    {bgLocation && <ModalRoute path="/:channel_slug/p/:ephy_slug" component={EphyView} />}
    </>
  )
}
