import {
  EPHIES_SEARCH_START,
  // EPHIES_SEARCH_ERROR,
  EPHIES_SEARCH_SUCCESS,
} from '../ephyConstants'

import algoliasearch from 'algoliasearch'

export function searchEphies(params = {}) {
  return (dispatch, getState, getFirebase) => {
    const { searchString, channel: { id: channelId } = {}} = params

    if (!searchString) {
      return new Promise((resolve) => {
        resolve ([])

        dispatch({
          type: EPHIES_SEARCH_SUCCESS,
          payload: {
            data: []
          }
        })
      })
    }

    dispatch({ type: EPHIES_SEARCH_START })

    const searchFilter = {}
    const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY)
    const index = client.initIndex('ephies')

    if (channelId) {
      searchFilter.filters = `channel.id:${channelId}`
    }

    return new Promise((resolve) => {
      index
        .search(searchString, searchFilter)
        .then(responses => {
          // Response from Algolia:
          // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
          // console.log(responses.hits)

          const ephiesList = []

          responses.hits.forEach((hit) => {
            ephiesList.push(Object.assign(hit, {
              id: hit.objectID,
              text: hit._highlightResult.text.value,
              createdAt: {
                seconds: hit.createdAt._seconds,
                nanoseconds: hit.createdAt._nanoseconds,
              }
            }))
          })

          resolve(ephiesList)

          dispatch({
            type: EPHIES_SEARCH_SUCCESS,
            payload: {
              data: ephiesList
            }
          })
        })
    })
  }
}
