import 'react-toastify/dist/ReactToastify.css'
import 'styles/ReactToastify-override.scss'

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MetaTags from 'react-meta-tags'
import { ToastContainer } from 'react-toastify'

import { subscribeToChannels, subscribeToNotifications } from 'modules/user/userActions'
import { subscribeToUserSubscriptions } from 'modules/feed/feedActions'
import * as appActions from 'app/appActions'
import RenameBanner from 'modules/banners'
// import CookiesBanner from 'modules/user/components/CookiesBanner'

export default function AppWrapper({ children }) {
  const dispatch = useDispatch()

  const isAuthenticated = !useSelector(state => state.firebase.auth.isEmpty)
  const userId = useSelector(state => state.firebase.auth.uid)
  const userEmail = useSelector(state => state.firebase.auth.email)
  const isEphyUser = /@(ephy\.co|lightspot\.io)/.test(userEmail)
  useEffect(() => {
    gtag('set', 'user_properties', {
      user_type: isEphyUser ? 'ephy' : 'external',
      user_email: userEmail,
    })

  }, [dispatch, isAuthenticated, userEmail, isEphyUser])

  useEffect(() => {

    if (!userId) return

    const unsubscribeFromUserChannels = dispatch(subscribeToChannels())
    const unsubscribeFromUserSubscriptions = dispatch(subscribeToUserSubscriptions())
    const userNotifications = dispatch(subscribeToNotifications())

    return () => {
      unsubscribeFromUserChannels()
      unsubscribeFromUserSubscriptions()
      userNotifications.unsubscribe()
    }

  }, [dispatch, userId])

  useEffect(() => {
    let currentTheme
    const systemThemeSync = localStorage.systemThemeSync === 'true'

    if (systemThemeSync) {
      currentTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : null
    } else {
      currentTheme = localStorage.theme
    }
    if (currentTheme) {
      dispatch(appActions.switchTheme(currentTheme))
    }
  }, [dispatch])

  const getOGUrl = () =>
    process.env.REACT_APP_PUBLIC_URL + window.location.pathname + window.location.search

  return (
    <>
      <MetaTags>
        <meta property="og:url" content={getOGUrl()} />
      </MetaTags>

      <RenameBanner />

      {children}

      {/* {!profile.cookiesAccepted && <CookiesBanner />} */}

      <ToastContainer
        position="bottom-left"
        autoClose={2500}
        closeButton={false}
        hideProgressBar={true}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
