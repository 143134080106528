export const TAGS_NEW_TAG            = 'TAGS_NEW_TAG'
export const TAGS_REPLACE_TAG        = 'TAGS_REPLACE_TAG'
export const TAGS_REMOVE_TAG         = 'TAGS_REMOVE_TAG'
export const TAGS_CREATE_TAG_START   = 'TAGS_CREATE_TAG_START'
export const TAGS_CREATE_TAG_SUCCESS = 'TAGS_CREATE_TAG_SUCCESS'
export const TAGS_CREATE_TAG_ERROR   = 'TAGS_CREATE_TAG_ERROR'
export const TAGS_FETCH_TAG_START    = 'TAGS_FETCH_TAG_START'
export const TAGS_FETCH_TAG_SUCCESS  = 'TAGS_FETCH_TAG_SUCCESS'
export const TAGS_FETCH_TAG_ERROR    = 'TAGS_FETCH_TAG_ERROR'
export const TAGS_UPDATE_TAG_START   = 'TAGS_UPDATE_TAG_START'
export const TAGS_UPDATE_TAG_SUCCESS = 'TAGS_UPDATE_TAG_SUCCESS'
export const TAGS_UPDATE_TAG_ERROR   = 'TAGS_UPDATE_TAG_ERROR'
export const TAGS_DELETE_TAG_START   = 'TAGS_DELETE_TAG_START'
export const TAGS_DELETE_TAG_SUCCESS = 'TAGS_DELETE_TAG_SUCCESS'
export const TAGS_DELETE_TAG_ERROR   = 'TAGS_DELETE_TAG_ERROR'
export const TAGS_REORDER_START      = 'TAGS_REORDER_START'
export const TAGS_REORDER_SUCCESS    = 'TAGS_REORDER_SUCCESS'
export const TAGS_REORDER_ERROR      = 'TAGS_REORDER_ERROR'
