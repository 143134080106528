import React, { Component } from 'react'
import { getFirebase } from 'react-redux-firebase'
import { toast } from 'react-toastify'

import Button from 'components/Button'

class Logout extends Component {
  static navItems = [
    { name: 'account', title: 'Account' },
    { name: 'import_export', title: 'Import and export' },
    { name: 'change_password', title: 'Change password' },
    { name: 'logout', title: 'Logout' },
  ]

  constructor(props) {
    super(props)

    this.firebase = getFirebase()
  }

  logout(e) {
    e.preventDefault()

    this.firebase.logout()
    toast.success('Logged out')
  }

  render () {
    return (
      <Button
        kind="dangerousText"
        isBlocky={true}
        onClick={this.logout.bind(this)}
      >
        Logout
      </Button>
    )
  }
}

export default Logout
