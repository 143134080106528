import React from 'react'
import { getFirebase } from 'react-redux-firebase'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

class Logout extends React.Component {
  componentDidMount() {
    getFirebase().logout()
    toast.success('Logged out')
    this.props.dispatch(push('/'))
  }

  render() {
    return null
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatch
})

export default connect(mapDispatchToProps)(Logout)
