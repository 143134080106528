import styles from 'modules/channel/components/TagListItem/TagListItem.module.scss'

import React from 'react'
import classnames from 'classnames'
// import PropTypes from 'prop-types'
import Dropdown from 'components/Dropdown'
import SubscriptionPill from '../SubscriptionPill'
import SubscriptionInfo from '../SubscriptionInfo'
import Button from 'components/Button'

import { ReactComponent as MenuIcon } from 'icons/Dots.svg'

export function SubscriptionListItem({
  item: subscription,
  showActionsButton = false,
  pillStyle,
  isOverlay,
  onSubscriptionClick,
  activeSubscription,
  isMobile,
}) {
  const {
    name,
    photoUrl,
    // slug,
    private: privat3
  } = subscription.channel

  return (
    <li className={classnames(styles.tagGroup, {[styles.overlay]: isOverlay})}>
      {showActionsButton ? (
        <>
          <SubscriptionPill
            {...{name, photoUrl, pillStyle}}
            onClick={() => onSubscriptionClick(subscription.channelId)}
            isActive={subscription.channelId === activeSubscription} />
          <Dropdown
            className={styles.tagMenu}
            button={
              <Button
                kind={isOverlay ? "iconDark" : "icon"}
                size="36"
              >
                <MenuIcon />
              </Button>
            }
            dropdown={<SubscriptionInfo {...{subscription}} />}
            trigger="click" />
        </>
      ) : (
        <>
        {isMobile ? (
          <SubscriptionPill
            {...{name, private: privat3, photoUrl, pillStyle}}
            onClick={() => onSubscriptionClick(subscription.channelId)}
            isActive={subscription.channelId === activeSubscription} />
        ) : (
          <Dropdown
            button={
              <SubscriptionPill
                {...{ name, private: privat3, photoUrl, pillStyle }}
                onClick={() => onSubscriptionClick(subscription.channelId)}
                isActive={subscription.channelId === activeSubscription} />
            }
            dropdown={<SubscriptionInfo {...{ subscription }} />}
            trigger="hover" />
        )}
        </>
      )}
    </li>
  )
}

SubscriptionListItem.propTypes = { }
