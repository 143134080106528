import {
  EPHIES_CREATE_EPHY_START,
  EPHIES_CREATE_EPHY_SUCCESS,
  EPHIES_CREATE_EPHY_ERROR,
} from '../ephyConstants'

import { getCachableChannelData } from 'utils/ephy'

export function createEphy(ephy, { channel = null } = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_CREATE_EPHY_START })

    let ephyData = {
      tags: ephy.tags,
      text: ephy.text,
      pinned: ephy.pinned || false,
      impressions: 1,
      commentsCount: 0,
      // TODO split into createdAt and date - the last one will be used for sorting
      createdAt: ephy.createdAt || getFirebase().firestore.Timestamp.now(),
      // TODO move this to server
      createdBy: getState().firebase.auth.uid,
    }

    let ephiesCollectionRef

    if (channel?.id) {
      ephiesCollectionRef = getFirebase()
        .firestore()
        .collection('channels')
        .doc(channel.id)
        .collection('ephies')

      ephyData.channel = getCachableChannelData(channel)
    } else {
      ephiesCollectionRef = getFirebase()
        .firestore()
        .collection('users')
        .doc(getState().firebase.auth.uid)
        .collection('ephies')
    }

    return ephiesCollectionRef
      .add(ephyData)
      .then(docRef => {
        ephyData.id = docRef.id

        dispatch({
          type: EPHIES_CREATE_EPHY_SUCCESS,
          payload: {
            data: ephyData
          }
        })

        return ephyData
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: EPHIES_CREATE_EPHY_ERROR,
          error,
        })
      })
  }
}
