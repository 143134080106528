function createHandler(component, key) {
  return (e) => {
    const el = e.target
    const value = el.type === 'checkbox' ? el.checked : el.value
    const keys = key.split('.')

    // TODO: handle n-level nesting
    if (keys.length === 1) {
      component.setState({ [keys[0]]: value })
    } else if (keys.length === 2) {
      component.setState(prevState => {
        return ({ [keys[0]]: Object.assign(prevState[keys[0]], { [keys[1]]: value }) })
      })
    // } else if (keys.length === 3) {
      // component.setState({ [keys[0]]: { [keys[1]]: { [keys[2]]: value } }})
    } else {
      console.error('linkState doesn\'t support key nesting level more than 2. Please, refactor the code! :)')
    }
  }
}

export default function linkState(component, key) {
  const cache = component.__linkStateHandlers ||
    (component.__linkStateHandlers = {})

  return cache[key] || (cache[key] = createHandler(component, key))
}
