const getStartedData = {
  tags: [
    { name: 'Getting started 🎈', order: 0 },
  ],
  ephies: [
    {
      text: '<div><h3><strong>👋 Welcome! Let\'s get you started</strong></h3><p>Here is a brief list of what you can do on <strong>Lightspot</strong>:&nbsp;</p><ul><li>🙌 This <strong>your go-to place</strong> to share and discuss new innovations in AI, data science, and more in <strong>private or public channels</strong>.</li><li>👾 Use it instead of blogs or chats</li><li>👨&zwj;👦 Click <a href="/settings">Settings</a> to <strong>Co-host</strong> your channel with other peers, colleagues, or friends. It\'s more fun to do it together.</li><li>👨&zwj;💻 Send an <a href="/signup">Invite link</a><strong> </strong>to let people join OR share your content on other platforms.</li><li>🌐 <a href="/discover">Discover</a> interesting channels to participate in discussions and learn new things.</li><li>💲 Get tips for your insights <i>(coming soon...)</i></li><li>😊 This is an early version of our product and more is coming - reach out to us with your ideas and feedback.</li></ul></div>',
      tags: ['Getting started 🎈'],
    },
  ]
}

export default getStartedData
