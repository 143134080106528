import styles from './ChannelInfoHorizontal.module.scss'
import { ReactComponent as MenuIcon } from 'icons/Dots.svg'

import React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  ChannelCard,
  ChannelAvatar,
} from 'components/ChannelInfo'
import { Dropdown } from 'components/Dropdown'
import Button from 'components/Button'
import Modal from 'components/Modal/Modal'
import InviteToChannel from '../InviteToChannel/InviteToChannel'
import { ChannelInfoMore } from './ChannelInfoMore'

export const ChannelInfoHorizontal = React.memo(({
  channel,
  tags,
  subscriptions,
  allowSubscription,
  isUserChannelAdmin,
  onCopyLinkClick,
}) => {
  const [showAddCohostModal, setShowAddCohostModal] = useState(false)

  const {
    photoUrl,
    name,
    slug,
    private: privat3,
    subscribersCount,
    viewsCount,
  } = channel

  return (
    <div className={styles.container}>
      <ChannelAvatar {...{name, slug, photoUrl}} />
      <ChannelCard {...{name, private: privat3, viewsCount, subscribersCount}} />
      <Dropdown
        button={<Button id="more_info" kind="icon" size="36"><MenuIcon /></Button>}
        menu={
          <ChannelInfoMore {...{
            channel,
            tags,
            subscriptions,
            allowSubscription,
            isUserChannelAdmin,
            onAddCohostClick: () => setShowAddCohostModal(true),
            onCopyLinkClick,
          }} />
        }
      />
      {showAddCohostModal &&
        <Modal onClose={() => setShowAddCohostModal(false)}>
          <InviteToChannel
            channel={channel}
            isUserChannelAdmin={isUserChannelAdmin}
            onClose={() => setShowAddCohostModal(false)}
          />
        </Modal>
      }
    </div>
  )
})

ChannelInfoHorizontal.propTypes = {
  channel: PropTypes.object.isRequired,
  allowSubscription: PropTypes.bool,
}
