import React from 'react'
import MainLayout from 'layout/MainLayout'
import TagList from 'modules/channel/components/TagList'
import EphiesList from 'modules/ephy/components/EphiesList'
import data from './data'
import DummyChannelPreview from '../DummyChannelPreview/DummyChannelPreview'

const { channel, tags, ephies } = data
const originalName = channel.name
const originalPhotoUrl = channel.photoUrl

const DummyPersonalChannel = ({ name = '', photoUrl = ''}) => {
  channel.name = (name.trim() && name) || originalName
  channel.photoUrl = photoUrl || originalPhotoUrl

  return (
    <DummyChannelPreview>
      <MainLayout>
        {/* <MainLayout.LeftSidebar> */}
          {/* <ChannelInfo */}
            {/* type='actionsBottom' */}
            {/* {...{channel, isSubscribed, isUserChannelAdmin}} */}
            {/* onSubscribe = {subscribeUserToChannel.bind(this, channel)} */}
            {/* onUnsubscribe = {unsubscribeUserFromChannel.bind(this, channel)} */}
          {/* /> */}
        {/* </MainLayout.LeftSidebar> */}
        <MainLayout.MainContent>
          <EphiesList
            ephies={ephies}
            showChannelAvatarOnDesktop={true}
            emptyMessage={''}
            editable={false}
            hasMoreEphies={false}
            loadMoreEphies={()=>{}}
          />
        </MainLayout.MainContent>

        <MainLayout.RightSidebar>
          <TagList
            channel={channel}
            tags={tags}
            activeTag={'Future of learning'}
            editable={false}
          />
        </MainLayout.RightSidebar>
      </MainLayout>
    </DummyChannelPreview>
  )
}

export default DummyPersonalChannel
