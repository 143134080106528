import { getFirebase } from 'react-redux-firebase'
import slugify from 'slugify'

import { uniqueid } from 'utils'

// TODO remove duplication in firebase functions
const generateNewPrivateSlugSuffix = () => `-${uniqueid({ length: 12}).toLowerCase()}`

export const generateUniqueChannelSlug = async ({ id, slug, private: privat3 }) => {
  const firebase = getFirebase()
  slug = slugify(slug || uniqueid(), { strict: true}).toLowerCase()
  let slugSuffix = privat3 && !/-[a-zA-Z0-9]{12}$/.test(slug) ? generateNewPrivateSlugSuffix() : ''
  let unique = false

  while (!unique) {
    let existingChannelQuery = firebase
      .firestore()
      .collection('channels')
      .where('deleted', '==', false)
      .where('private', '==', false)
      .where('slug', '==', slug + slugSuffix)

    if (id) {
      existingChannelQuery = existingChannelQuery
        .where(firebase.firestore.FieldPath.documentId(), '!=', id)
    }

    const existingChannelSnapshot = await existingChannelQuery.get()
    if (!existingChannelSnapshot.empty) {
      if (privat3) {
        slugSuffix = generateNewPrivateSlugSuffix()
      } else {
        slugSuffix = slugSuffix !== '' ? (parseInt(slugSuffix, 10) + 1).toString() : '1'
      }
    } else {
      unique = true
    }
  }

  slug += slugSuffix

  if (slug.length < 6) {
    slug += `-${uniqueid({ length: 6 - slug.length })}`
  }

  return slug
}
