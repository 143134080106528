import styles from './DiscoverOnboardingView.module.scss'

import { Link } from 'react-router-dom'
import * as channelActions from 'modules/channel/channelActions'

import { OnboardingForm, PromoContent } from 'components/Onboarding'
import { SplitPaneLayout } from 'layout'
import { MetaTags } from 'react-meta-tags'
import Button from 'components/Button'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import EphiesList from 'modules/ephy/components/EphiesList'
import ChannelPillList from './components/ChannelPillList'
import { ChannelPill } from './components/ChannelPills/ChannelPill'
import useDiscoverChannelList from './useDiscoverChannelList'
import DummyChannelPreview from 'modules/channel/components/DummyChannelPreview/DummyChannelPreview'
import { PulsingLoader } from 'components/Loader'
import { featureUsed } from 'utils'
import isCallable from 'is-callable'

export default function DiscoverOnboardingView() {
  const {
    activeChannelId,
    setActiveChannelId,
    selectedChannelsIds,
    handleChannelClick,
    subscribeToSelected,
    channels,
    dispatch,
    isLoading,
  } = useDiscoverChannelList()

  const channel = useSelector(state => state.channel)

  if (channels.length > 0 && !activeChannelId) setActiveChannelId(channels[0].id)

  useEffect(() => {
    let unsubscribe
    if (activeChannelId)
      unsubscribe = dispatch(channelActions.subscribeToChannelEphies(activeChannelId)).unsubscribe

    return () => isCallable(unsubscribe) && unsubscribe()
  }, [dispatch, activeChannelId ])

  useEffect(() => {
    featureUsed({ action: 'onboarding_discovery', category: 'onboarding', label: 'User visited discovery page during onboarding' })
  }, [])

  return (
    <>
      <MetaTags>
        <title>Explore Lightspot</title>
        <meta property="og:title" content={'Explore Lightspot'} />
        <meta name="description" content={'Discover channels you\'re interested in  – Lightspot'} />
        <meta property="og:description" content={'Discover channels you\'re interested in  – Lightspot'} />
        {/* <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
      <SplitPaneLayout
        leftPane={
          <OnboardingForm
            title='Select channels you may be interested in'
            footer={undefined}
          >
            <div className={styles.container}>
              <ChannelPillList channels={channels}>
                {
                  (id, name, photoUrl) => (
                    <ChannelPill
                      name={name}
                      photoUrl={photoUrl}
                      isActive={id === activeChannelId}
                      isSelected={selectedChannelsIds.includes(id)}
                      onChannelClick={() => handleChannelClick(id)}
                    />
                  )
                }
              </ChannelPillList>
            </div>
            <br />
            {!isLoading &&
            <Button
              kind="primary"
              isBlocky={true}
              component={Link}
              to="/welcome/create_channel"
              onClick={subscribeToSelected}
            >
              Next
            </Button>}
          </OnboardingForm>
        }
        rightPane={
          <PromoContent>
            <div className={styles.channelPreview}>
              <DummyChannelPreview title={false}>
                {channel.ephiesFetchInProgress || !activeChannelId
                  ? <PulsingLoader />
                  : <EphiesList
                      ephies={channel.ephies}
                      showChannelAvatarOnDesktop={true}
                      emptyMessage={''}
                      editable={false}
                      hasMoreEphies={false}
                      loadMoreEphies={() => { }}
                    />
                }
              </DummyChannelPreview>
            </div>
          </PromoContent>
        }
      />
    </>
  )
}
