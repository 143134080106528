import styles from './ChannelPillList.module.scss'
import { useBreakpoints } from 'hooks'

export const ChannelPillList = ({
  channels,
  children
}) => {
  const { isMobile } = useBreakpoints()

  return (
    <div className={styles.container}>
      {channels.map(channel => {
        const {id, name, photoUrl} = channel
        return (
          <div key={id}>
            {children(id, name, photoUrl, channel, isMobile)}
          </div>
        )
      })}
    </div>
  )
}
