import styles from './ChannelDescription.module.scss'

import React from 'react'
import sanitizeHtml  from 'sanitize-html'
import { nl2br } from 'utils'

export const ChannelDescription = ({ text }) => {
  const filteredText = sanitizeHtml(text, {
    allowedTags: [],
    allowedAttributes: {}
  })

  return (
    <div className={styles.description}>{nl2br(filteredText.trim())}</div>
  )
}
