import request from 'request'

/**
 * Sends email invitations to the specified email addresses
 *
 * @param {String} channelId
 * @param {String} emails
 * @returns {Promise} promise will resolve into a bool result
 */
export function inviteMembersByEmail(channelId, emails) {
  return (dispatch, getState, getFirebase) => {

    if (getState().firebase.auth.isEmpty) {
      return Promise.resolve(false)
    }

    return getFirebase()
      .auth()
      .currentUser
      .getIdToken(true)
      .then((idToken) => {
        return new Promise((resolve, reject) => {
          const options = {
            // prod url
            url: `${window.location.protocol}//${window.location.host}/api/v1/invite_members_by_email`,

            // test url
            // don't forget about disabling CORS
            // https://mybrowseraddon.com/access-control-allow-origin.html
            // url: `https://dev.lightspot.io/api/v1/invite_members_by_email`, // for dev frontend
            // url: `http://localhost:9090/api/v1/invite_members_by_email`, // for local frontend
            method: 'POST',
            headers: {
              'AuthToken': idToken
            },
            json: {
              channelId,
              emails,
            }
          }

          request(options, (error, response, body) => {
            if (!error && response.statusCode === 200) {
              resolve(body.emailsSent)
            } else {
              error && console.error(error)

              reject()
            }
          })
        })
      })
    }
}
