import React from 'react'

import Button from 'components/Button'

import { ReactComponent as CreateButton } from 'icons/Plus.svg'

const TagCreateButton = ({onClick, isVerbose, iconStyle='light', ...restProps}) => (
  <>
    {isVerbose ? (
      <Button
        kind="flexWithIcon"
        isBlocky={true}
        onClick={onClick}
        icon={<CreateButton />}
        {...restProps}
      >
        Create topic
      </Button>
    ) : (
      <Button
        kind={iconStyle === 'light' ? 'icon' : 'iconDark'}
        onClick={onClick}
        {...restProps}
      >
        <CreateButton />
      </Button>
    )}
  </>
)

export default TagCreateButton
