import styles from './Notifications.module.scss'
import { ReactComponent as NotificationIcon } from './Notification.svg'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { fetchPublicUserProfiles, markAllNotificationsAsRead } from 'modules/user/userActions'

import Dropdown from 'components/Dropdown'
import Button from 'components/Button'
import { RotatingLoader } from 'components/Loader'

const NotificationsMenuItem = (props) => {
  const dispatch = useDispatch()

  const notifications = useSelector(({user}) => user.notifications)
  const publicUserProfiles = useSelector(({user}) => user.publicUserProfiles)
  const userChannels = useSelector(({user}) => user.channels)
  const notificationsFetchInProgress = useSelector(({user}) => user.notificationsFetchInProgress)

  const [pendingProfilesLoad, setPendingProfilesLoad] = useState(true)

  useEffect(function fetchMentionedProfiles() {
    const isNotFetched = userId => !publicUserProfiles.find(user => user.id === userId)

    const profilesToFetch = notifications
      .map(notification => notification.data.userId)
      .filter(Boolean) // filter out undefined values that we got from notifs without actor like granted admin access
      .filter(isNotFetched)

    if (profilesToFetch.length === 0) {
      setPendingProfilesLoad(false)
      return
    }

    setPendingProfilesLoad(true)

    dispatch(fetchPublicUserProfiles(profilesToFetch))

  }, [dispatch, notifications, publicUserProfiles])

  let notificationsElement = (notificationsFetchInProgress || pendingProfilesLoad) ?
    <RotatingLoader /> : <p>You have no notifications yet</p>

  if (notifications.length > 0) {
    const notificationElements = []

    notifications.forEach(notification => {
      const {
        id,
        type,
        data,
        read,
        createdAt,
      } = notification

      let userName, notificationElement

      if (data.userId && publicUserProfiles.length > 0) {
        userName = publicUserProfiles.find(user => data.userId === user.id)?.name
      }

      const channel = userChannels.find(channel => data.channelId === channel.id)
      if (!channel) return

      switch (type) {
        case 'admin_granted':
          notificationElement = (
            <div>
              <span className={styles.name}>You've</span>
              {" "}
              <span>been granted admin access to</span>
              {" "}
              <Link to={`/${channel.slug}`}>{channel.name}</Link>
              {" "}
              <span className={styles.time}>{moment.unix(createdAt.seconds).fromNow()}</span>
            </div>
          )
          break

        case 'user_subscribed':
          notificationElement = (
            <div>
              <span className={styles.name}>{userName}</span>
              {" "}
              <span>started following</span>
              {" "}
              <Link to={`/${channel.slug}`}>{channel.name}</Link>
              {" "}
              <span className={styles.time}>{moment.unix(createdAt.seconds).fromNow()}</span>
            </div>
          )
          break

        case 'reacted_to_post':
          const postPreview = getFirstNChars(data.ephyText, 24).trim()
          notificationElement = (
            <div>
              <span className={styles.name}>{userName}</span>
              {" "}
              <span>reacted with {data.reaction}</span>
              {" "}
              <span>to</span>
              {" "}
              <Link to={`/p/${data.channelId}/${data.ephyId}`}>
                {postPreview.length > 0 ? postPreview + '...' : 'post'}
              </Link>
              {" "}
              <span className={styles.time}>{moment.unix(createdAt.seconds).fromNow()}</span>
            </div>
          )
          break

        case 'liked_comment':
          const commentPreview = getFirstNChars(data.commentText, 24).trim()
          notificationElement = (
            <div>
              <span className={styles.name}>{userName}</span>
              {" "}
              <span>likes your comment</span>
              {" "}
              <Link to={`/p/${data.channelId}/${data.ephyId}`}>
                {commentPreview.length > 0 ? commentPreview + '...' : 'comment'}
              </Link>
              {" "}
              <span className={styles.time}>{moment.unix(createdAt.seconds).fromNow()}</span>
             </div>
           )
           break

        case 'commented_post':
        case 'commented_post_admin':
          const postPreview2 = getFirstNChars(data.ephyText, 24).trim()
          notificationElement = (
            <div>
              <span className={styles.name}>{userName}</span>
              {" "}
              <span>commented on</span>
              {" "}
               <Link to={`/p/${data.channelId}/${data.ephyId}`}>
                 {postPreview2.length > 0 ? postPreview2 + '...' : 'the post'}
               </Link>
              {" "}
              <span className={styles.time}>{moment.unix(createdAt.seconds).fromNow()}</span>
             </div>
           )
           break

        default:
          console.log(`This notification is not supported yet ${type}`, notification)
      }

      const notificationElementListItem = (
        <li key={id} className={!read? styles.highlighted : ''}>
          {notificationElement}
        </li>
      )

      notificationElements.push(notificationElementListItem)
    })

    const notificationElementsList = (
      <ul >
        {notificationElements}
      </ul>
    )

    if (notificationElements.length > 0) notificationsElement = notificationElementsList
  }

  const unreadCount = notifications.reduce((acc, notification) => notification.read? acc : ++acc, 0)
  const unreadCountAbbr = unreadCount > 999? `${Math.floor(unreadCount / 1000)}k` : unreadCount

  const notificationsBtn = (
    <Button
      kind={unreadCount > 0? "iconText" : "icon"}
      size="32">
        {unreadCount > 0? unreadCountAbbr : <NotificationIcon />}
    </Button>
  )

  const markAllRead = () => {
    if (unreadCount > 0) {
      dispatch(markAllNotificationsAsRead())
    }
  }

  return (
    <Dropdown
      dropdown={
        <div className={styles.notifications}>
          {notificationsElement}
        </div>
      }
      button={notificationsBtn}
      offset="15"
      maxHeight="50"
      onHide={markAllRead}
    />
  )
}

export default NotificationsMenuItem

function getFirstNChars(html, charCount) {
  const htmlEl = document.createElement('div')
  htmlEl.innerHTML = html

  return htmlEl.textContent.substring(0, charCount - 1)
}
