/* eslint-disable react-hooks/exhaustive-deps*/
import styles from './Content.module.scss'
import 'react-medium-image-zoom/dist/styles.css'

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import sanitizeHtml from 'sanitize-html'
import Zoom from 'react-medium-image-zoom'
import { getIframelyEmbed } from 'utils/iframely'
import Button from 'components/Button'

const ALLOWED_HTML_TAGS = [
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'a',
  'img', 'figure', 'ul', 'ol', 'li', 'i',
  'em', 's', 'u', 'strong', 'blockquote',
  'br', 'div', 'iframe', 'oembed'
]
const ALLOWED_HTML_ATTRIBUTES = false
// {
  // '*': [ 'href', 'name', 'target', 'src', 'class', 'style' ]
// }

export const Content = ({ content, truncate = true }) => {
  const [showFullPost, setShowFullPost] = useState(!truncate)

  const processedContent = useMemo(() => {
    const html = sanitizeHtml(content, {
      allowedTags: ALLOWED_HTML_TAGS,
      allowedAttributes: ALLOWED_HTML_ATTRIBUTES
    })

    const htmlWithEmbedly = html.replaceAll(/<oembed\s+url="([^"]+?)"\s*>\s*<\/oembed>/ig, (_match, p1) => {
      return getIframelyEmbed(p1)
    })

    return htmlWithEmbedly
  }, [content])

  const [isContentTruncated, postRef] = useContentTruncation(600, showFullPost)


  return (
    <>
      <div className={showFullPost ? styles.post : styles.truncatedPost}>
        {/* we need this inner element because firefox and safari line clamping algorithm can't handle
        multiple children correctly */}
        <div
          // style={{display: 'inline-block'}}
          key={showFullPost}
          ref={postRef}
          dangerouslySetInnerHTML={{__html: processedContent}}
        />
        <ZoomedImages postRef={postRef} />
      </div>
      {isContentTruncated &&
        <div className={styles.afterPost}>
          <Button kind="text" onClick={() => setShowFullPost(true)}>Show More</Button>
        </div>
      }
    </>
  )
}

function ZoomedImages({ postRef }) {
  const [imageFigures, setImageFigures] = useState([])

  const figures = postRef.current
    ? postRef.current.getElementsByTagName('figure')
    : []

  useEffect(() => {
    const imgFigures = []
    for (let figure of figures) {
      if (figure.classList.contains('image')) {
        imgFigures.push(figure)
      }
    }

    setImageFigures(imgFigures)
  }, [figures])

  return imageFigures.map((imageFigure, index) => {
    const image = imageFigure.getElementsByTagName('img')[0]
    image.style.display = 'none'

    return ReactDOM.createPortal(
      <Zoom
        key={index}
        overlayBgColorEnd='rgba(255, 255, 255, 0.6)'
        portalEl={document.getElementById('root')}
      >
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={image.src} style={{maxWidth:'100%', maxHeight:'100%'}}/>
      </Zoom>,
      imageFigure
    )
  })
}

function useContentTruncation(heightLimit, pass) {
  const [truncated, setTruncated] = useState(false)
  const [truncatedFallback, setTruncatedFallback] = useState(false)
  // eslint-disable-next-line
  const [forceRender, setForceRender] = useState(0)

  const elementRef = useRef()

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries, observer) => {
        setForceRender(forceRender => ++forceRender)
    })

    resizeObserver.observe(elementRef.current)

    return () => resizeObserver.disconnect()
  }, [])

  useLayoutEffect(() => {

    if (pass) {
      setTruncated(false)
      return
    }

    const element = elementRef.current

    // if element is overflown then a browser has done its job
    if (element.scrollHeight > element.clientHeight) {
      setTruncated(true)
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      // if (isSafari && element.getElementsByTagName('ul').length === 0) {
      if (isSafari && element.clientHeight === 0) {
        element.parentElement.style.display = 'inline-block'
      }
    } else {
      if (truncatedFallback) return
      setTruncated(false)
    }

    if (element.offsetHeight < heightLimit) return

    // if browser failed its job and content too tall then let's remove redundant elements

    if (element.childElementCount === 1 && element.firstElementChild.tagName === 'FIGURE') return

    let totalChildrenHeight = 0
    let truncated = false
    const children = Array.from(element.children)

    children.forEach(child => {

      if (truncated) {
        element.removeChild(child)
        return
      }

      totalChildrenHeight += child.offsetHeight

      if (totalChildrenHeight > heightLimit) {
        truncated = true

        if (child.tagName === 'UL') {
          // clampList(child, totalChildrenHeight - heightLimit)
        } else {
          clampElement(child, totalChildrenHeight - heightLimit)
        }

        setTruncated(true)
        setTruncatedFallback(true)
      }

    })
  })

  return [truncated, elementRef]
}

function clampElement(element, excessHeight = 0) {
  const style = window.getComputedStyle(element)
  const lineHeight = Number(style.lineHeight.slice(0, -2))
  let allowedLinesCount = Math.floor((element.clientHeight - excessHeight) / lineHeight)

  if (excessHeight === 0) allowedLinesCount -= 1

  element.style.display = '-webkit-box'
  element.style['-webkit-box-orient'] = 'vertical'
  element.style['-webkit-line-clamp'] = (allowedLinesCount || 1) + ''
  element.style.overflow = 'hidden'
}

// function clampList(listElement, excessHeight = 0) {
//   console.log(listElement)
//   const children = Array.from(listElement.children)
// }
