export const FEED_EPHY_LIMIT                  = 15
export const FEED_FETCH_SUBSCRIPTIONS_START   = 'FEED_FETCH_SUBSCRIPTIONS_START'
export const FEED_FETCH_SUBSCRIPTIONS_SUCCESS = 'FEED_FETCH_SUBSCRIPTIONS_SUCCESS'
export const FEED_FETCH_SUBSCRIPTIONS_ERROR   = 'FEED_FETCH_SUBSCRIPTIONS_ERROR'
export const FEED_FETCH_FEED_START            = 'FEED_FETCH_FEED_START'
export const FEED_FETCH_FEED_SUCCESS          = 'FEED_FETCH_FEED_SUCCESS'
export const FEED_FETCH_FEED_ERROR            = 'FEED_FETCH_FEED_ERROR'
export const SUBSCRIPTION_UPDATE_START        = 'SUBSCRIPTION_UPDATE_START'
export const SUBSCRIPTION_UPDATE_SUCCESS      = 'SUBSCRIPTION_UPDATE_SUCCESS'
export const SUBSCRIPTION_UPDATE_ERROR        = 'SUBSCRIPTION_UPDATE_ERROR'
export const SUBSCRIPTION_ALL                 = 'all'
export const SUBSCRIPTION_SELECTED            = 'selected'
export const SUBSCRIPTION_NEW                 = 'new'
