import styles from './ChannelLink.module.scss'

import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import copyToClipboard from 'clipboard-copy'
import { featureUsed } from 'utils'

export const ChannelLink = ({ channel }) => {
  const copyLink = (event) => {
    event.preventDefault()

    const resourceLink = new URL(`/${channel.slug}`, document.location).href

    copyToClipboard(resourceLink).then(() => {
      toast.success('Link copied to clipboard')
      featureUsed({ action: 'channel_copy_link_to_clipboard', category: 'channel', label: 'Copy Channel link to clipboard' })
    })
  }

  return (
    <div className={styles.container}>
      <Link to={`/${channel.slug}`} onClick={copyLink} title="Click to copy">{window.location.host}/{channel.slug}</Link>
    </div>
  )
}
