const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
const charsLength = chars.length

const uniqueid = ({ length = 18, prefix = '' } = {}) => {
  let s = ''
  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * charsLength)
    s += chars[index]
  }

  return prefix + s
}

export default uniqueid
