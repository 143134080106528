import React from 'react'
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getFirebase } from 'react-redux-firebase'
import classnames from 'classnames'
import MetaTags from 'react-meta-tags'

// import AppLayout from 'layout/AppLayout'
import Button from 'components/Button'
import { HelloUser } from 'components/Onboarding'

import formStyles from './Form.module.scss'
import styles from './Auth.module.scss'
import { ReactComponent as FBIcon } from './icons/Fb.svg'
import { ReactComponent as GoogleIcon } from './icons/Google.svg'

class SignupView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      password: '',
      showErrorMessage: false,
      showPassword: false,
    }

    this.firebase = getFirebase()
  }

  signup() {
    if (this.state.name.length < 1 || this.state.password.length < 6) {
      this.setState({ showErrorMessage: true })
      return
    }

    this.firebase
      .createUser({
        email: this.state.email,
        password: this.state.password
      }, {
        name: this.state.name
      })
      .catch(e => {
        this.setState({ showErrorMessage: true })
      })
  }

  loginWithGoogle() {
    this.firebase.login({ provider: 'google', type: 'redirect' })
  }

  loginWithFacebook() {
    this.firebase.login({ provider: 'facebook', type: 'redirect' })
  }

  showPassword(e) {
    e.preventDefault()
    this.setState({ showPassword: !this.state.showPassword })
  }

  render () {
    const nameIsInvalid = this.state.showErrorMessage && this.state.name.length < 1
    const passwordIsInvalid = this.state.showErrorMessage && this.state.password.length < 6
    const emailIsInvalid = !nameIsInvalid && !passwordIsInvalid && this.state.showErrorMessage && this.props.authError

    return (
      <>
        <MetaTags>
          <title>Signup – Lightspot</title>
          <meta name="description" content="Lightspot is a community platform that gives a unique voice to your knowledge, expertise and passions. Join now!" />
          <meta property="og:title" content="Signup – Lightspot" />
          <meta property="og:description" content="Lightspot is a community platform that gives a unique voice to your knowledge, expertise and passions. Join now!" />
        </MetaTags>

        <HelloUser showPolicy={true}>
          <form className={formStyles.form}>
            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: nameIsInvalid })}>
              <input
                type="text"
                name="name"
                maxLength="100"
                autoComplete="name"
                className={formStyles.input}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value, showErrorMessage: false })}
                placeholder="Name"
                autoFocus
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Please, enter your name
                </small>
              </div>
            </div>
            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: emailIsInvalid })}>
              <input
                type="text"
                name="email"
                maxLength="200"
                autoComplete="email"
                className={formStyles.input}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value, showErrorMessage: false })}
                placeholder="Email"
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  {this.props.authError && this.props.authError.code === 'auth/email-already-in-use'
                    ? 'The address is already in use'
                    : 'Please, enter a valid email address'}
                </small>
              </div>
            </div>
            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: passwordIsInvalid })}>
              <input
                type={ this.state.showPassword ? 'text' : 'password'}
                name="password"
                maxLength="100"
                autoComplete="new-password"
                className={formStyles.input}
                id="loginPassword"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value, showErrorMessage: false })}
                placeholder="Password"
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Please, use 6 or more characters
                </small>
                <small className={styles.forgot}>
                  <Button kind="text"
                    onClick={this.showPassword.bind(this)}>
                    { this.state.showPassword ? 'Hide' : 'Show' }
                  </Button>
                </small>
              </div>
            </div>
            <Button
              kind="primary"
              isBlocky={true}
              onClick={e => { e.preventDefault(); this.signup() }}>
              Sign up
            </Button>
            <div className={formStyles.alternatives}>
              <p>
                <Link to="/login">Login with email</Link><span>or login/signup with social media</span>
              </p>
              <Button kind="iconBordered" onClick={e => { e.preventDefault(); this.loginWithGoogle() }}>
                <GoogleIcon />
              </Button>
              <Button kind="iconBordered" onClick={e => { e.preventDefault(); this.loginWithFacebook() }}>
                <FBIcon />
              </Button>
            </div>
          </form>
        </HelloUser>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authError: state.firebase.authError,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SignupView)
