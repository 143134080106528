import {
  EPHIES_LIKE_COMMENT_START,
  EPHIES_LIKE_COMMENT_SUCCESS,
  EPHIES_LIKE_COMMENT_ERROR,
} from '../ephyConstants'

/**
 * @param {String} channelId
 * @param {String} ephyId
 * @param {String} commentId
 * @param {Boolean} like
 */
export function likeComment(commentId, like = true) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_LIKE_COMMENT_START })

    return getFirebase()
      .firestore()
      .collection('comment_likes')
      .where('commentId', '==', commentId)
      .where('userId', '==', getState().firebase.auth.uid)
      .limit(1)
      .get()
      .then(likesSnapshot => {
        if (likesSnapshot.empty) {
          if (!like)
            return

          return getFirebase()
            .firestore()
            .collection('comment_likes')
            .add({
              commentId,
              userId: getState().firebase.auth.uid
            })
        } else {
          if (like)
            return

          return likesSnapshot.docs[0].ref.delete()
        }
      })
      .then(() => {
        return dispatch({
          type: EPHIES_LIKE_COMMENT_SUCCESS,
          payload: {
            data: {
              commentId,
              userId: getState().firebase.auth.uid,
              like,
            }
          }
        })
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: EPHIES_LIKE_COMMENT_ERROR,
          payload: {
            error
          }
        })
      })
  }
}
