import { generateUniqueChannelSlug } from 'utils/channel'
import { uniqueid } from 'utils'

export function TelegramImporter({ fileList, firebase, createChannel, uploadChannelPhoto, createEphy }) {
  const parseMessageTextArr = (textArr) => {
    const newTextArr = ['']

    textArr.forEach(text => {
      if (typeof text === 'string') {
        let splitTextArr = text.split(/\n{2,}/)
        newTextArr[newTextArr.length - 1] += splitTextArr[0].replace('\n', '<br/>')
        splitTextArr.slice(1).map(t => newTextArr.push(t.replace('\n', '<br/>')))
      } else {
        switch (text.type) {
          case 'link':
            newTextArr[newTextArr.length - 1] +=
              `<a href="${text.text}" target="_blank" rel="noopener noreferrer">${text.text}</a>`
            break

          case 'text_link':
            newTextArr[newTextArr.length - 1] +=
              `<a href="${text.href}" target="_blank" rel="noopener noreferrer">${text.text}</a>`
            break

          case 'email':
            newTextArr[newTextArr.length - 1] += `<a href="mailto:${text.text}">${text.text}</a>`
            break

          case 'phone':
            newTextArr[newTextArr.length - 1] += `<a href="phone:${text.text}">${text.text}</a>`
            break

          case 'hashtag':
          case 'bold':
            // TODO full hashtag support
            newTextArr[newTextArr.length - 1] += `<strong>${text.text.replace('\n', '<br/>')}</strong>`
            break

          case 'mention':
            newTextArr[newTextArr.length - 1] +=
              `<a href="https://t.me/${text.text.slice(1)}" target="_blank" rel="noopener noreferrer">${text.text}</a>`
            break

          default:
            newTextArr[newTextArr.length - 1] += text.text.replace('\n', '<br/>')
            break
        }
      }
    })

    // TODO guess H1
    // TODO embed youtube
    // TODO empty text_links attach to post (img, video)

    return '<p>' + newTextArr.join('</p><p>') + '</p>'
  }

  /**
   * Uploads a file and returs url to it
   *
   * @param {File} file
   * @param {String} path
   */
  const uploadFile = async (file, path) => {
    const metadata = {
      customMetadata: {
        contentType: file.type,
        originalName: file.name,
        lastModified: file.lastModified,
      }
    }

    return firebase.uploadFile(
      path,
      file,
      null,
      { metadata }
    ).then(({ uploadTaskSnapshot }) => {
      return uploadTaskSnapshot.ref.getDownloadURL()
    })
  }

  const getFileByPath = (path) => {
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].webkitRelativePath.endsWith(path)) {
        return fileList[i]
      }
    }

    return false
  }

  this.import = () => new Promise((resolve) => {
    const messagesListReader = new FileReader()

    messagesListReader.onload = async (loadedEvent) => {
      const data = JSON.parse(loadedEvent.target.result)

      // channel
      let channelPhotoPath = ''
      const channelData = {
        id: uniqueid(),
        name: data.name,
        slug: await generateUniqueChannelSlug({ slug: data.name, private: data.private }),
      }

      const ephiesData = []
      // data.messages.slice(0, 25) // TMP
      for (const message of data.messages) {
        switch (message.type) {
          case 'service':
            if (message.action === 'edit_group_photo') {
              channelPhotoPath = message.photo
            }
            break

          case 'message':
            // TODO support polls
            // TODO file uploads
            // TODO stickers
            // TODO video uploads
            if (message.poll || (message.file && !message.text)) {
              break
            }

            const ephyData = {
              tags: [],
              text: '',
              createdAt: new Date(message.date),
            }

            // TODO file async upload
            if (message.photo) {
              const imageUrl = await uploadFile(getFileByPath(message.photo), `ephies/${uniqueid()}`)
              ephyData.text = `<figure class="image"><img src="${imageUrl}" /></figure>`
            }

            // if (message.file && message.media_type === 'video_file') {
              // TODO video upload
              //  "media_type": "animation",
              // "media_type": "sticker",
            // }

            if (message.text) {
              if (typeof message.text === 'string') {
                message.text = [message.text]
              }

              ephyData.text += parseMessageTextArr(message.text)
            }

            ephiesData.push(ephyData)
          break

          default:
        }
      }

      // create channel
      channelData.photoUrl = channelPhotoPath ? await uploadChannelPhoto(channelData, getFileByPath(channelPhotoPath)) : ''
      const channel = await createChannel(channelData, { createGetStartedData: false })

      // create ephies
      resolve(Promise.all(ephiesData.map(ephyData => createEphy(ephyData, { channel }))))
    }

    messagesListReader.readAsText(getFileByPath('/result.json'))
  })
}
