import { uniqueid } from 'utils'

const channel = {
  id: uniqueid(),
  name: 'Impact Investing',
  slug: 'impact-investing',
  photoUrl: 'https://firebasestorage.googleapis.com/v0/b/ephy-dev.appspot.com/o/users%2FwFe8vf0TsPQIjW9ylyvfRNCjzbY2%2Fphoto-tcigayN4HrweRM87Ht.jpeg?alt=media&token=ad78a239-5c07-4aac-a7fd-3c27c1c15f39',
}

const dummyOtherChannelData = {
  channel,
  tags: [
    { id: uniqueid(), name: 'Fireside chats'},
    { id: uniqueid(), name: 'Insights'},
    { id: uniqueid(), name: 'Investments'},
    { id: uniqueid(), name: 'Climate'},
    { id: uniqueid(), name: 'What’s next?'},
  ],
  ephies: [
    {
      id: uniqueid(),
      channel,
      text: '<p>My thoughts about Impact Investing. How it can shape education, climate and more.</p><div class="image"><img src="https://firebasestorage.googleapis.com/v0/b/ephy-dev.appspot.com/o/ephies%2Fnew-ephy-2xXB8k5rgEUpTwsVu6%2Faudio3.png?alt=media&token=b266bff9-794b-4f78-ba8f-ed72d8dc068e"/></div>',
      tags: ['Investments', 'Fireside chats'],
      impressions: 98,
      pinned: false,
      createdAt: {
        seconds: 1608109163,
        nanoseconds: 813000000
      },
    },
    {
      id: uniqueid(),
      channel,
      text: '<p>Sir Ronald Cohen, Chairman, Global Steering Group, on Impact Investing</p><figure class="media"><div data-oembed-url="https://www.youtube.com/watch?v=EcXMzkRLB3M"><div style="position:relative;padding-bottom:100%;height:0;padding-bottom:56.2493%"><iframe src="https://www.youtube.com/embed/EcXMzkRLB3M" style="position:absolute;width:100%;height:100%;top:0;left:0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div></div></figure>',
      tags: ['Investments', 'Climate'],
      impressions: 235,
      pinned: false,
      createdAt: {
        nanoseconds: 544000000,
        seconds: 1608013788

      },
    },
    {
      id: uniqueid(),
      channel,
      text: '<p>Is sustainable investing just a marketing ploy?</p><figure class="media"><div data-oembed-url="https://www.youtube.com/watch?v=Qd2D-0lQ36E"><div style="position:relative;padding-bottom:100%;height:0;padding-bottom:56.2493%"><iframe src="https://www.youtube.com/embed/Qd2D-0lQ36E" style="position:absolute;width:100%;height:100%;top:0;left:0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div></div></figure>',
      tags: ['Investments', 'Climate'],
      impressions: '1.7K',
      pinned: false,
      createdAt: {
        seconds: 1606812069,
        nanoseconds: 348000000
      },
    }
  ]
}

export default dummyOtherChannelData
