import styles from './EphiesList.module.scss'

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'

import EphiesListItem from '../EphiesListItem'
import { PulsingLoader } from 'components/Loader'
import { fetchReactions } from 'modules/user/userActions'

export default function EphiesList({
  ephies,
  // showChannelAvatarOnDesktop = true,
  showChannelAvatar = true,
  showAuthor = false,
  activeTag,
  emptyMessage,
  editable,
  loadMoreEphies,
  hasMoreEphies,
}) {
  const dispatch = useDispatch()

  useEffect(function fetchUserReactions() {
    dispatch(fetchReactions(ephies.map(ephy => ephy.id)))
  }, [dispatch, ephies])

  const renderEmptyMessage = () => {
    let message = 'Nothing here yet.'

    if (typeof emptyMessage === 'function') {
      message = emptyMessage()
    } else if (typeof emptyMessage === 'string') {
      message = emptyMessage
    }

    return <>{message}</>
  }

  if (ephies.length === 0) {
    return renderEmptyMessage()
  }

  return (
    // <div className={showChannelAvatarOnDesktop ? styles.containerWide : styles.container}>
    <div id="ephies_list" className={styles.container}>
      <InfiniteScroll
        hasMore={hasMoreEphies}
        loadMore={loadMoreEphies}
        loader={
          <div key={0} style={{ position: 'relative', marginTop: '50px' }}>
            <PulsingLoader />
          </div>
        }
      >
        {ephies.map(ephy => (
          <EphiesListItem
            key={ephy.id}
            ephy={ephy}
            activeTag={activeTag}
            // showChannelAvatarOnDesktop={showChannelAvatarOnDesktop}
            showChannelAvatar={showChannelAvatar}
            showAuthor={showAuthor}
            canManageEphies={editable}
          />
        ))}
      </InfiniteScroll>
      <div style={{ height: '20vh' }}></div>
    </div>
  )
}
