import styles from './Import.module.scss'

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFirebase } from 'react-redux-firebase'
import { toast } from 'react-toastify'
import Button from 'components/Button'
import * as channelActions from 'modules/channel/channelActions'
import * as userActions from 'modules/user/userActions'
import * as ephyActions from 'modules/ephy/ephyActions'
import * as tagActions from 'modules/tag/tagActions'
import { MetaTags } from 'react-meta-tags'
import { TelegramImporter } from './TelegramImporter'
import { featureUsed } from 'utils'

import {
  USERS_DATA_PROCESSING_START,
  USERS_DATA_PROCESSING_SUCCESS,
  USERS_DATA_PROCESSING_ERROR,
} from 'modules/user/userConstants'

class Import extends Component {
  constructor(props) {
    super(props)

    this.state = {
      telegramDirectory: null,
    }

    // TMP for testing
    // const importer = new TelegramImporter({
      // fileList: this.state.telegramDirectory,
      // firebase: getFirebase(),
      // createChannel: this.props.channelActions.createChannel,
      // uploadChannelPhoto: this.props.channelActions.uploadChannelPhoto,
      // createEphy: this.props.ephyActions.createEphy,
    // })
  }

  importTelegramData() {
    if (!this.state.telegramDirectory) {
      return window.alert('Please, select a directory with Telegram files for import')
    }

    this.props.dispatch({ type: USERS_DATA_PROCESSING_START })

    featureUsed({ action: 'import_from_telegram', category: 'import', label: 'Import from Telegram' })

    const importer = new TelegramImporter({
      fileList: this.state.telegramDirectory,
      firebase: getFirebase(),
      createChannel: this.props.channelActions.createChannel,
      uploadChannelPhoto: this.props.channelActions.uploadChannelPhoto,
      createEphy: this.props.ephyActions.createEphy,
    })

    importer
      .import()
      .then(() => {
        this.props.dispatch({ type: USERS_DATA_PROCESSING_SUCCESS })

        toast.success('Import from Telegram has been completed')
        this.setState({ telegramDirectory: null })
      })
      .catch(() => {
        this.props.dispatch({ type: USERS_DATA_PROCESSING_ERROR })

        toast.error('Import from Telegram failed. Did you pick a correct directory?')
      })
  }

  handleTelegramFilesChange(e) {
    e.preventDefault()

    this.setState({
      telegramDirectory: e.target.files
    })
  }

  render() {
    return (
      <>
        <MetaTags>
          <title>{this.props.title} – settings</title>
        </MetaTags>

        <h1>{this.props.title}</h1>
        <div className={styles.body}>
          {/* <div>
            <p>Imports your posts from Wordpress site</p>
            <Button kind="primary" isBlocky={true} onClick={() => {}}>Import from Wordpress</Button>
          </div> */}

          <div>
            <p>Import your posts from Telegram channel</p>
            <p>First, you need to export your posts from Telegram</p>
            <ol>
              <li>Open a Telegram channel in desktop app</li>
              <li>Click on a hamburger menu and select "Export chat history"</li>
              <li>In the export dialog tick all checkboxes (Posts, Photos, Videos, etc), change format to "Machine readable JSON" and set max size to 140 Mb</li>
              <li>Click Export</li>
              <li>Your data is saved in a directory named similarly to <i>"ChatExport_2021-02-16"</i></li>
            </ol>
            <p>Use with caution - import still in <strong><i>beta</i></strong></p>

            <label className={styles.fileButton}>
              <input
                type="file"
                webkitdirectory="true"
                directory="true"
                onChange={this.handleTelegramFilesChange.bind(this)} />
              Click here to select exported directory<br/>(e.g. "ChatExport_XXXX-YY-ZZ")
            </label>

            {this.state.telegramDirectory && <p><br/>Files to be imported: {this.state.telegramDirectory.length}</p>}

            <Button kind="primary" isBlocky={true} onClick={this.importTelegramData.bind(this)} disabled={this.props.dataProcessingInProgress}>
              {this.props.dataProcessingInProgress ? 'Import in progress...' : 'Start import'}
            </Button>
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  layout: state.layout,
  dataProcessingInProgress: state.user.dataProcessingInProgress,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  channelActions: bindActionCreators(channelActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
  ephyActions: bindActionCreators(ephyActions, dispatch),
  tagActions: bindActionCreators(tagActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Import)
