// import {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'

// import './Overlay.module.scss'

const overlayRoot = document.getElementById('overlay-root')

export function Overlay({
  children,
  // className
}) {
/*   const [overlay] = useState(() => {
    const overlay = document.createElement('div')
    if (className) overlay.className = className
    return overlay
  })
  useEffect(
    () => {
      overlayRoot.appendChild(overlay)
      return () => overlayRoot.removeChild(overlay)
    }
  , [overlay]) */
  return ReactDOM.createPortal(children, overlayRoot)
}
