import {
  EPHIES_UPDATE_COMMENT_START,
  EPHIES_UPDATE_COMMENT_SUCCESS,
  EPHIES_UPDATE_COMMENT_ERROR,
} from '../ephyConstants'

/**
 * @param {String} id
 * @param {String} text
 */
export function updateComment({ id, text, deleted }) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_UPDATE_COMMENT_START })

    const commentData = {
      text,
      updatedAt: getFirebase().firestore.Timestamp.now(),
    }

    return getFirebase()
      .firestore()
      .collection('comments')
      .doc(id)
      .update(commentData)
      .then(() => {
        const data = { id, ...commentData }

        dispatch({
          type: EPHIES_UPDATE_COMMENT_SUCCESS,
          payload: {
            data
          }
        })

        return data
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: EPHIES_UPDATE_COMMENT_ERROR,
          error,
        })
      })
  }
}
