export const getIframelyEmbed = (url) => {
  const iframeUrl = `//cdn.iframe.ly/api/iframe?app=1&api_key=${process.env.REACT_APP_IFRAMELY_API_KEY}&url=${encodeURIComponent(url)}`
  // alternatively, use &key= instead of &api_key with the MD5 hash of your api_key
  // more about it: https://iframely.com/docs/allow-origins

  return (
    // If you need, set maxwidth and other styles for 'iframely-embed' class - it's yours to customize
    '<div class="iframely-embed">' +
      '<div class="iframely-responsive">' +
        `<iframe src="${iframeUrl}" ` +
          'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
        '</iframe>' +
      '</div>' +
    '</div>'
  )
}
