import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

import { feedReducer } from 'modules/feed'
import { discoverReducer } from 'modules/discover'
import { channelReducer } from 'modules/channel'
import { ephyReducer } from 'modules/ephy'
import { tagReducer } from 'modules/tag'
import { userReducer } from 'modules/user'
import { profileReducer } from 'modules/profile'
import { appReducer } from 'app'

const createRootReducer = (history) => combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  router: connectRouter(history),
  feed: feedReducer,
  discover: discoverReducer,
  channel: channelReducer,
  ephy: ephyReducer,
  tag: tagReducer,
  user: userReducer,
  profile: profileReducer,
  app: appReducer,
})

export default createRootReducer
