import {
  EPHIES_UPDATE_EPHY_START,
  EPHIES_UPDATE_EPHY_SUCCESS,
} from '../ephyConstants'

import { getCachableChannelData } from 'utils/ephy'
import { createEphy } from './createEphy'
import { deleteEphy } from './deleteEphy'

export function updateEphy(ephy) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_UPDATE_EPHY_START })

    return (async () => {
      const state = getState()
      const channelEphies = state.channel.ephies
      const pocketEphies = state.user.ephies
      const allEphies = [...channelEphies, ...pocketEphies]

      // getting existing version of Ephy from the state
      const oldEphy = allEphies.find(eph => eph.id === ephy.id)

      let ephiesCollectionRef

      // if Ephy is moved to another channel / Pocket
      // deleting the old version and creating a new one
      if (ephy.channel?.id !== oldEphy.channel?.id) {
        [ephy] = await Promise.all([
          dispatch(createEphy({
            text: ephy.text,
            tags: ephy.tags,
            pinned: ephy.pinned,
          }, { channel: getCachableChannelData(ephy.channel) })),
          dispatch(deleteEphy(oldEphy)),
        ])
      // regular Ephy update
      } else {
        if (ephy.channel) {
          ephiesCollectionRef = getFirebase()
            .firestore()
            .collection('channels')
            .doc(ephy.channel.id)
            .collection('ephies')
        } else {
          ephiesCollectionRef = getFirebase()
            .firestore()
            .collection('users')
            .doc(getState().firebase.auth.uid)
            .collection('ephies')
        }

        await ephiesCollectionRef
          .doc(ephy.id)
          .update({
            text: ephy.text,
            tags: ephy.tags,
            pinned: ephy.pinned,
            // createdAt: ephy.createdAt
            // TODO move this to server
            updatedAt: getFirebase().firestore.Timestamp.now(),
            // TODO move this to server
            updatedBy: getState().firebase.auth.uid,
          })
      }

      dispatch({
        type: EPHIES_UPDATE_EPHY_SUCCESS,
        payload: {
          data: ephy
        }
      })

      return ephy
    })()
  }
}
