import {
  PROFILE_FETCH_PROFILE_START,
  PROFILE_FETCH_PROFILE_SUCCESS,
  PROFILE_FETCH_PROFILE_ERROR,
  PROFILE_FETCH_CHANNELS_START,
  PROFILE_FETCH_CHANNELS_SUCCESS,
  PROFILE_FETCH_CHANNELS_ERROR,
} from './profileConstants'

import isCallable from 'is-callable'

export function subscribeToProfile({ username }, callback) {
  return (dispatch, getState, getFirebase) => {

    dispatch({ type: PROFILE_FETCH_PROFILE_START })

    return getFirebase()
      .firestore()
      .collection('users_public')
      .where('username', '==', username)
      .onSnapshot(querySnapshot => {
        let profile

        if (!querySnapshot.empty) {
          profile = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() }

          if (isCallable(callback)) {
            callback(profile)
          }

          dispatch({
            type: PROFILE_FETCH_PROFILE_SUCCESS,
            payload: {
              data: profile
            }
          })
        } else {
          dispatch({
            type: PROFILE_FETCH_PROFILE_ERROR,
            payload: {
              error: 'Profile not found'
            }
          })
        }
      }, error => {
        console.error(error)

        dispatch({
          type: PROFILE_FETCH_PROFILE_ERROR,
          payload: {
            error
          }
        })
      })
  }
}

export function subscribeToChannels({ userId }, callback) {
  return (dispatch, _getState, getFirebase) => {

    dispatch({ type: PROFILE_FETCH_CHANNELS_START })

    return getFirebase()
      .firestore()
      .collection('channels')
      .where('deleted', '==', false)
      .where('private', '==', false)
      .where('admins', 'array-contains', userId)
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const channels = []

        querySnapshot.forEach(channelSnap => {
          channels.push({ id: channelSnap.id, ...channelSnap.data() })
        })

        if (isCallable(callback)) {
          callback(channels)
        }

        dispatch({
          type: PROFILE_FETCH_CHANNELS_SUCCESS,
          payload: {
            data: channels
          }
        })
      }, error => {
        console.error(error)

        dispatch({
          type: PROFILE_FETCH_CHANNELS_ERROR,
          payload: {
            error
          }
        })
      })
  }
}
