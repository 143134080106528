import formStyles from 'modules/auth/Form.module.scss'
import settingsStyles from './Settings.module.scss'

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFirebase } from 'react-redux-firebase'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import * as appActions from 'app/appActions'
import * as userActions from 'modules/user/userActions'
import { MetaTags } from 'react-meta-tags'

import {
  USERS_SHOW_EPHY_AUTHOR_NEVER,
  USERS_SHOW_EPHY_AUTHOR_MULTIPLE_HOSTS,
  USERS_SHOW_EPHY_AUTHOR_ALWAYS,
  USERS_DEFAULT_USER_SETTINGS,
} from 'modules/user/userConstants'
import ThemeSelector from 'components/ThemeSelector'

const showEphyAuthorSettings = [
  {
    showEphyAuthor: USERS_SHOW_EPHY_AUTHOR_NEVER,
    title: 'Never',
    description: 'Do not show authors for posts',
  },
  {
    showEphyAuthor: USERS_SHOW_EPHY_AUTHOR_MULTIPLE_HOSTS,
    title: 'Co-hosted channels',
    description: 'Show authors only when a channel is co-hosted – multiple users post to it',
  },
  {
    showEphyAuthor: USERS_SHOW_EPHY_AUTHOR_ALWAYS,
    title: 'Always',
    description: 'Always show authors for all posts',
  }
]

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showEphyAuthor: this.props.profile.settings?.showEphyAuthor || USERS_DEFAULT_USER_SETTINGS.showEphyAuthor,
      theme: this.props.app.theme,
      showErrorMessage: false,
    }

    this.firebase = getFirebase()
    this.updateSettings = this.updateSettings.bind(this)
  }

  updateSettings() {
    const formValidation = this.validateForm()
    if (!formValidation.theme || !formValidation.showEphyAuthor) {
      return this.setState({
        showErrorMessage: true
      })
    }

    const settings = {
      theme: this.state.theme,
      showEphyAuthor: this.state.showEphyAuthor,
    }

    this
      .firebase
      .updateProfile({...this.props.profile, settings })
      .then(() => {
        toast.success('Settings saved')
      })
  }

  validateForm() {
    return {
      showEphyAuthor: [
        USERS_SHOW_EPHY_AUTHOR_NEVER,
        USERS_SHOW_EPHY_AUTHOR_MULTIPLE_HOSTS,
        USERS_SHOW_EPHY_AUTHOR_ALWAYS
      ].includes(this.state.showEphyAuthor),
      theme: true,
    }
  }

  render() {
    const formValidation = this.validateForm()

    return (
      <>
        <MetaTags>
          <title>{`${this.props.title} – Lightspot`}</title>
        </MetaTags>

        <h1>{this.props.title}</h1>

        <section>
          <form className={formStyles.form}>
            <div className={formStyles.formControl}>
              <label>Theme</label>

              <ThemeSelector onThemeChange={this.updateSettings} />
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: this.state.showErrorMessage && !formValidation.showEphyAuthor } )}>
              <label>Show post authors</label>

              <div className={settingsStyles.showEphyAuthor}>
                {showEphyAuthorSettings.map(({ showEphyAuthor, title, description }) => (
                  <div className={classnames(formStyles.radioControl, settingsStyles.radioControl)} key={showEphyAuthor}>
                    <input
                      type="radio"
                      name="showEphyAuthor"
                      id={`showEphyAuthor_${showEphyAuthor}`}
                      className={formStyles.input}
                      checked={showEphyAuthor === this.state.showEphyAuthor}
                      value={showEphyAuthor}
                      onChange={e => this.setState({ showEphyAuthor: e.target.value }, () => this.updateSettings())}
                    />
                    <label className={formStyles.richLabel} htmlFor={`showEphyAuthor_${showEphyAuthor}`}>
                      <div className={formStyles.labelTitle}>{title}</div>
                      <div className={formStyles.labelDescription}>{description}</div>
                    </label>
                  </div>
                ))}
              </div>

              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Please, select one of the items above
                </small>
              </div>
            </div>
          </form>
        </section>
      </>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  app: state.app,
  fetchInProgress: state.user.fetchInProgress,
})

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
