import styles from './HelpView.module.scss'

import React from 'react'

import { MainLayout } from 'layout'

export default function HelpView() {
  return (
    <MainLayout variant="onlyMain">
      <MainLayout.LeftSidebar />
      <MainLayout.MainContent>
        <div className={styles.container}>
          <h1>Help</h1>

          <h2>What is Lightspot?</h2>
          <iframe width="560" height="315" title="Lightspot promo" src="https://www.youtube.com/embed/C-1KEzO5pEI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

          <h2>Noticed an issue or have an idea?</h2>
          <p>Great! Please, reach out to us at <a href="mailto:lightspot@lightspot.io">lightspot@lightspot.io</a></p>
        </div>
      </MainLayout.MainContent>
      <MainLayout.RightSidebar />
    </MainLayout>
  )
}
