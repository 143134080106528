export const ROOKIE_OWN_CHANNEL = [
  {
    name: 'ephy_menu',
    selectors: [
      '#delete_ephy',
      (document) => {
        const posts = document.querySelectorAll('#ephies_list > div > *')
        const post = [...posts].filter(a => a.textContent.includes('Here is a brief list of what you can do on Lightspot:')).pop()
        return post?.querySelector('#ephy_menu')
      }
    ],
    content: '<h1>Welcome!</h1><p>Here is all you need to kickstart your channel. Delete this Getting Started post anytime here<p>',
    placement: 'bottom'
  },
  {
    name: 'create_topic',
    selectors: ['#create_topic', "#topic_menu"],
    content: '<h1>Create a topic</h1><p>Add Topics to your channel to organize your flows</p>',
    placement: 'bottom'
  },
  {
    name: 'add_cohost',
    selectors: ['#add_cohost', "#more_info"],
    content: '<h1>Invite others</h1><p>Send Invites to peers or colleagues to subscribe to your channel</p>',
    placement: 'bottom'
  },
  {
    name: 'create_post',
    selectors: ['#typebar'],
    content: '<h1>Start posting</h1><p>Share and discuss anything with a simple Typebar</p>',
    placement: 'top'
  }
]
