import {
  USERS_UPLOAD_USER_PHOTO_START,
  USERS_UPLOAD_USER_PHOTO_SUCCESS,
  USERS_UPLOAD_USER_PHOTO_ERROR,
} from '../userConstants'

import isCallable from 'is-callable'
import uniqueid from 'utils/uniqueid'

export function uploadUserPhoto(file, callback) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: USERS_UPLOAD_USER_PHOTO_START })

    const path = `users/${getState().firebase.auth.uid}`
    const name = `photo-${uniqueid()}.${file.name.split('.').slice(-1)[0].toLowerCase()}`
    const metadata = {
      customMetadata: {
        contentType: file.type,
        originalName: file.name,
        lastModified: file.lastModified,
      }
    }

    getFirebase()
      .uploadFile( path, file, null, { name, metadata })
      .then(({ uploadTaskSnapshot }) => {

        uploadTaskSnapshot.ref.getDownloadURL().then(function(photoUrl) {
          if (isCallable(callback)) {
            callback(photoUrl)
          }

          dispatch({
            type: USERS_UPLOAD_USER_PHOTO_SUCCESS,
            payload: {
              photoUrl
            }
          })
        })
      }).catch(error => {
        dispatch({
          type: USERS_UPLOAD_USER_PHOTO_ERROR,
          payload: {
            error
          }
        })
      })
  }
}
