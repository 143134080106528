import React from 'react'
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getFirebase } from 'react-redux-firebase'
import classnames from 'classnames'
import MetaTags from 'react-meta-tags'

// import AppLayout from 'layout/AppLayout'
import Button from 'components/Button'
import { HelloUser } from 'components/Onboarding'
import formStyles from './Form.module.scss'
import styles from './Auth.module.scss'

class PasswordResetView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      emailSent: false,
      showErrorMessage: false,
    }

    this.firebase = getFirebase()
  }

  resetPassword(e) {
    e.preventDefault()

    this.firebase
      .resetPassword(this.state.email)
      .then(() => {
        this.setState({ email: '', emailSent: true, showErrorMessage: false })
      })
      .catch(e => {
        this.setState({ showErrorMessage: true })
      })
  }

  renderEmailSent() {
    return (
      <div className={ styles.text }>
        Reset password email was sent!<br/>
        Please, follow the link in your inbox.
      </div>
    )
  }

  renderForm() {
    return (
      <form className={formStyles.form}>
        <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: this.state.showErrorMessage && this.props.authError })}>
          <input
            type="text"
            name="email"
            maxLength="200"
            autoComplete="username"
            className={formStyles.input}
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value, showErrorMessage: false })}
            placeholder="Email"
            autoFocus
          />
          <div className={formStyles.additionalControlInfo}>
            <small className={formStyles.invalidFeedback}>
              Couldn't find the email provided
            </small>
          </div>
        </div>
        <Button
          kind="primary"
          isBlocky={true}
          onClick={this.resetPassword.bind(this)}
          >
          Reset password
        </Button>
        <div className={formStyles.alternatives}>
          {/* <p><BackIcon style={{ width: '6px', height: 'auto'}} /><span>Back to </span><Link to="/login">Login</Link></p> */}
          <p><span>&larr;</span><span>Back to </span><Link to="/login">Login</Link></p>
        </div>
      </form>
    )
  }

  render () {
    return (
      <>
        <MetaTags>
          <title>Reset password – Lightspot</title>
          <meta name="description" content="Reset password on Lightspot" />
          <meta property="og:title" content="Reset password – Lightspot" />
        </MetaTags>

        <HelloUser showPolicy={false}>
          {this.state.emailSent ? this.renderEmailSent() : this.renderForm()}
        </HelloUser>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authError: state.firebase.authError,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetView)
