import styles from './ChannelInfoMore.module.scss'
import {ReactComponent as SettingsIcon} from 'icons/Settings.svg'
import {ReactComponent as ShareLinkIcon} from 'icons/ShareLink.svg'

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  ChannelInfoButtons,
  ChannelDescription,
  // ChannelInfoTagList,
  // ChannelLink,
  ChannelAdminList,
  SubscribeButton,
  UnSubscribeButton,
  AddCohostButton,
  // MessageButton
} from 'components/ChannelInfo'
// import { featureUsed } from 'utils'
import { useChannelInfo } from 'modules/channel/hooks/useChannelInfo'
import Button from 'components/Button'
import { Link } from 'react-router-dom'
// import isCallable from 'is-callable'

export const ChannelInfoMore = ({
  channel,
  tags,
  subscriptions,
  allowSubscription,
  isUserChannelAdmin,
  onAddCohostClick,
  onCopyLinkClick,
}) => {

  const {
    description,
    admins,
    private: privat3,
  } = channel

  const {
    isSubscribed,
    // subscribedTags,
    // otherTags,
    onSubscribe,
    onUnsubscribe,
    // onSubscribeToTag,
    // onUnsubscribeFromTag,
  } = useChannelInfo(channel, subscriptions, tags)

  const publicUserProfiles = useSelector(({ user }) => user.publicUserProfiles)
  const adminProfiles = useMemo(() => {
    return publicUserProfiles.filter(profile => admins.includes(profile.id))
  }, [publicUserProfiles, admins])

  // const onMessage = () => (
  //   featureUsed(
  //     {
  //       action: 'channel_send_message',
  //       category: 'channel',
  //       label: 'Send message to channel owner'
  //     },
  //     'Messaging is in progress...'
  //   )
  // )

  return (
    <div className={styles.container}>
      <ChannelInfoButtons>
        {allowSubscription &&
          <>
            {isSubscribed
              ? <UnSubscribeButton onClick={onUnsubscribe} />
              : <SubscribeButton onClick={onSubscribe} />
            }
          </>
        }
        {!(privat3 && !isUserChannelAdmin) && <AddCohostButton id="add_cohost" onClick={onAddCohostClick} />}
        {/* {isCallable(onMessage) && allowSubscription && <MessageButton onClick={onMessage} />} */}
        <Button kind="iconBordered" size="40" onClick={onCopyLinkClick}>
          <ShareLinkIcon style={{width: '26px', height: '26px'}}/>
        </Button>
        {isUserChannelAdmin &&
          <Button
            kind="iconBordered"
            component={Link}
            to={{
              pathname: "/settings",
              search: `?section=channel&id=${channel.id}`,
            }}
            size="40"
          >
            <SettingsIcon style={{width: '17px', height: '17px'}} />
          </Button>
        }
      </ChannelInfoButtons>
      {/* <ChannelLink channel={channel} /> */}
      <ChannelDescription text={description} />

      {/* {allowSubscription && tags.length > 0 && <hr />}
      {allowSubscription && subscribedTags.length > 0 &&
      <ChannelInfoTagList
        title="Subscribed topics"
        tags={subscribedTags}
        channel={channel}
        onTagClick={onUnsubscribeFromTag}
      />
      }

      {allowSubscription && otherTags.length > 0 &&
      <ChannelInfoTagList
        title="Not subscribed topics"
        tags={otherTags}
        channel={channel}
        onTagClick={onSubscribeToTag}
      />
      } */}

      {/* TODO extract admins.length > 1 to a component parameter showAdminsList */}
      {admins.length > 1 && <hr />}
      {admins.length > 1 &&
      <ChannelAdminList
        admins={adminProfiles}
      />
      }
    </div>
  )
}

ChannelInfoMore.propTypes = {
  channel: PropTypes.object.isRequired,
  allowSubscription: PropTypes.bool,
}
