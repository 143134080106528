import { ReactComponent as MenuIcon } from 'icons/Dots.svg'

import React from 'react'

import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Menu, { MenuItem } from 'components/Menu'

export const EphyMenu = React.memo(({
  items,
}) => {
  const menuItems = []
  items.forEach((item, index) => {
    const {id, title, icon, onClick, condition} = item
    if (condition) menuItems.push(
      <MenuItem key={index} icon={icon}>
        <Button id={id} onClick={onClick}>{title}</Button>
      </MenuItem>
    )
  })

  if (menuItems.length === 0) return null

  return (
    <Dropdown
      dropdown={
        <Menu>
          {menuItems}
        </Menu>
      }
      button={<Button id="ephy_menu" kind="icon"><MenuIcon /></Button>}
    />
  )
})
