import { toast } from 'react-toastify'

/**
 * Track Feature Used with an optional message
 *
 * @param feature Object { action, category, label }
 * @param message String
 */
export const featureUsed = ({ action, category = '', label = '' }, message = '') => {
  if (!action) {
    return
  }

  gtag(
    'event',
    action,
    {
      'event_category': category,
      'event_label': label,
      'event_callback': () => {
        if (message) {
          toast.success(message, { autoClose: 4000 })
        }
      }
    }
  )
}
