import {
  USERS_UPDATE_USER_PASSWORD_START,
  USERS_UPDATE_USER_PASSWORD_SUCCESS,
} from '../userConstants'

import isCallable from 'is-callable'

export function updatePassword({ currentPassword, newPassword }, callback) {
  return (dispatch, _getState, getFirebase) => {

    dispatch({ type: USERS_UPDATE_USER_PASSWORD_START })

    const user = getFirebase().auth().currentUser
    const credential = getFirebase().auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    )

    user.reauthenticateWithCredential(credential).then(() => {
      user.updatePassword(newPassword).then(() => {
        if (isCallable(callback)) {
          callback()
        }

        dispatch({
          type: USERS_UPDATE_USER_PASSWORD_SUCCESS,
        })
      })
    })
  }
}
