import {
  CHANNELS_UPLOAD_CHANNEL_PHOTO_START,
  CHANNELS_UPLOAD_CHANNEL_PHOTO_SUCCESS,
  CHANNELS_UPLOAD_CHANNEL_PHOTO_ERROR,
} from '../channelConstants'

import { uniqueid } from 'utils'

export function uploadChannelPhoto(channel, file) {
  return (dispatch, _getState, getFirebase) => {
    dispatch({ type: CHANNELS_UPLOAD_CHANNEL_PHOTO_START })

    return new Promise((resolve, reject) => {
      const path = `channels/${channel.id}`
      const name = `photo-${uniqueid()}.${file.name.split('.').slice(-1)[0].toLowerCase()}`
      const metadata = {
        customMetadata: {
          contentType: file.type,
          originalName: file.name,
          lastModified: file.lastModified,
        }
      }

      getFirebase()
        .uploadFile( path, file, null, { name, metadata })
        .then(({ uploadTaskSnapshot }) => {

          uploadTaskSnapshot.ref.getDownloadURL().then(function(photoUrl) {
            resolve(photoUrl)

            dispatch({
              type: CHANNELS_UPLOAD_CHANNEL_PHOTO_SUCCESS,
              payload: {
                photoUrl
              }
            })
          })
        }).catch(error => {
          reject(error)

          dispatch({
            type: CHANNELS_UPLOAD_CHANNEL_PHOTO_ERROR,
            payload: {
              error
            }
          })
        })
    })
  }
}
