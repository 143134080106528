import styles from './ChannelCard.module.scss'
import { ReactComponent as LockIcon } from 'icons/Lock.svg'

import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

export const ChannelCard = (channel) => {
  const {
    name,
    slug,
    private: privat3,
    subscribersCount,
    viewsCount,
  } = channel

  return (
    <div className={styles.info}>
      <h1 className={classnames(styles.name, {[styles.private]: privat3})} title={privat3 ? 'Private channel' : ''}>
        {slug
          ? <Link to={`/${slug}`}>{name}</Link>
          : name
        }
        {privat3 && <LockIcon />}
      </h1>

      {(subscribersCount !== false || viewsCount !== false) &&
      <ul className={styles.performance}>
        {(subscribersCount !== false) &&
        <li>
          <span>
            <span className={styles.number}>{subscribersCount || 0}</span>&nbsp;
            {!subscribersCount || subscribersCount > 1 ? 'subscribers' : 'subscriber'}
          </span>
        </li>
        }
        {(viewsCount !== false) &&
        <li>
          <span>
            <span className={styles.number}>{viewsCount || 0}</span>&nbsp;
            {!viewsCount || viewsCount > 1 ? 'views' : 'view'}
          </span>
        </li>
        }
      </ul>
      }
    </div>
  )
}
