export default function cls(strs, ...substs) {
  let result = strs[0].trim().replace(/\s+/gm, ' ')
  const n = substs.length
  let i
  for (i = 0; i < n; i++) {
    const subst = substs[i]
    if (typeof subst === 'string') result += ' ' + subst
    const nextStr = strs[i+1].trim().replace(/\s+/g, ' ')
    if (nextStr !== '') result += ' ' + nextStr 
  }
  return result.trim()
}