import {
  CHANNELS_FETCH_TAGS_START,
  CHANNELS_FETCH_TAGS_SUCCESS,
  CHANNELS_FETCH_TAGS_ERROR,
} from '../channelConstants'

import isCallable from 'is-callable'

export function subscribeToChannelTags(channelId, callback) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: CHANNELS_FETCH_TAGS_START })

    let callbackFired = false

    return getFirebase()
      .firestore()
      .collection('channels')
      .doc(channelId)
      .collection('tags')
      .orderBy('order', 'asc')
      .onSnapshot(
        querySnapshot => {
          const tagsList = []
          querySnapshot.forEach(tag => {
            tagsList.push({ id: tag.id, ...tag.data() })
          })

          if (!callbackFired && isCallable(callback)) {
            callbackFired = true
            callback(tagsList)
          }

          dispatch({
            type: CHANNELS_FETCH_TAGS_SUCCESS,
            payload: {
              channelId,
              data: tagsList
            }
          })
      },
      error => {
        console.error(error)

        dispatch({
          type: CHANNELS_FETCH_TAGS_ERROR,
          payload: {
            error
          }
        })
      }
    )
  }
}
