import { ReactComponent as EditIcon } from 'icons/Edit.svg'
import { ReactComponent as PinIcon } from 'icons/Pin.svg'
import { ReactComponent as ShareIcon } from 'icons/Share.svg'
import { ReactComponent as TrashIcon } from 'icons/Trash.svg'

import React, { useRef, useEffect, useMemo, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import copyToClipboard from 'clipboard-copy'

import * as actions from 'modules/ephy/ephyActions'
import { getEphyUrl, featureUsed } from 'utils'

import TypebarDispatch from 'modules/ephy/components/Typebar/typebarContext'
import { useUrlHash } from 'hooks'
import Button from 'components/Button'
import Spacer from 'components/Spacer'
import EphyLayout from '../../layout'
import Content from 'components/Content'
import { EphyTags } from '../EphyTags'
import EphyImpressions from '../EphyImpressions'
import EphyDatetime from '../EphyDatetime'
import EphyChannelName from '../EphyChannelName'
import EphyMenu from '../EphyMenu'
import EphyPin from '../EphyPin'
import EphyAuthor from '../EphyAuthor'
import EphyChannelAvatar from '../EphyChannelAvatar'
import EphyReactions from '../EphyReactions'
import { useLocation } from 'react-router-dom'
import EphyComments from '../EphyComments'

// import { createComment, likeComment, subscribeToComments } from 'modules/ephy/actions'
// import { fetchCommentLikes } from 'modules/user/actions'

export const EphiesListItem = ({
  ephy,
  activeTag,
  // showChannelAvatarOnDesktop = true,
  showChannelAvatar = true,
  showAuthor = false,
  canManageEphies = false,
  truncate = true,
}) => {
  const typebarDispatch = useContext(TypebarDispatch)
  const location = useLocation()
  const urlHash = useUrlHash()
  const dispatch = useDispatch()
  const ephyElRef = useRef()
  const ephyTags = useMemo(() => ephy.tags.map((tag, index) => ({ name: tag, id: index })), [ephy.tags])
  const isTagActiveFn = useCallback(tagName => tagName === activeTag, [activeTag])

  const author = useSelector(state => (
    state.user.publicUserProfiles.find(profile => profile.id === ephy.createdBy)
      || { name: '', photoURL: '' }
  ))

  const editEphy = useCallback(() => {
    window.requestAnimationFrame(() => typebarDispatch({type: 'ephy/clickedEdit', payload: {id: ephy.id}}))
  }, [ephy, typebarDispatch])
  const toggleEphyPin = useCallback(() => {
    dispatch(actions.togglePinned(ephy))
    window.scrollTo(0, 0)
  }, [dispatch, ephy])
  const copyEphyLink = useCallback(() => {
    const resourceLink = new URL(getEphyUrl(ephy), document.location).href
    copyToClipboard(resourceLink).then(() => {
      toast.success('Link copied to clipboard')
      featureUsed({ action: 'ephy_copy_link_to_clipboard', category: 'ephy', label: 'Copy Ephy link to clipboard' })
    })
  }, [ephy])
  const deleteEphy = useCallback(() => {
    if (window.confirm('Delete post?'))
      dispatch(actions.deleteEphy(ephy))
  }, [dispatch, ephy])

  const menuItems = useMemo(() => [
    {title: 'Edit', icon: <EditIcon />, onClick: editEphy, condition: !!canManageEphies},
    {title: ephy.pinned ? 'Unpin' : 'Pin', icon: <PinIcon />, onClick: toggleEphyPin, condition: !!canManageEphies},
    {title: 'Copy Link', icon: <ShareIcon />, onClick: copyEphyLink, condition: ephy.channel},
    {title: 'Delete', icon: <TrashIcon />, onClick: deleteEphy, condition: !!canManageEphies, id: 'delete_ephy'},
  ], [editEphy, toggleEphyPin, copyEphyLink, deleteEphy, canManageEphies, ephy.channel, ephy.pinned])

  useEffect(() => {
    if (ephy.id === urlHash)
      window.scroll({
        top: ephyElRef.current.getBoundingClientRect().top + window.scrollY - 62, // 62 = header's height + mainContent's padding-top
        // behavior: 'smooth'
      })
  }, [ephy.id, ephyElRef, urlHash])

  // TMP for comment testing
  // useEffect(() => {
  //   console.log(ephy)
  //   if (ephy.id) {
  //     dispatch(createComment({ ephyId: ephy.id, channelId: ephy.channel.id, text: 'new new new' }))
  //     dispatch(likeComment('hVPxLvu5BciiTBaJ4mS1'))
  //     dispatch(fetchCommentLikes(['hVPxLvu5BciiTBaJ4mS1']))
  //     const commentsSubscription = dispatch(subscribeToComments({ ephyId: ephy.id, channelId: ephy.channel.id }))
  //     return commentsSubscription.unsubscribe
  //   }
  // }, [dispatch, ephy])

  return (
    <EphyLayout ref={ephyElRef}>
      <EphyLayout.Header>
        {ephy.channel &&
        <>
          {showChannelAvatar &&
            <EphyChannelAvatar
              channel={ephy.channel}
              // showOnDesktop={showChannelAvatarOnDesktop}
            />
          }
          <EphyChannelName channel={ephy.channel} />
        </>
        }
        <EphyTags
          ephyTags={ephyTags}
          isTagActiveFn={isTagActiveFn}
          channel={ephy.channel}
        />
        <EphyPin pinned={ephy.pinned} />
        <Spacer />
        <EphyMenu
          items={menuItems}
        />
      </EphyLayout.Header>
      <EphyLayout.Body>
        <Content content={ephy.text} truncate={truncate} />
      </EphyLayout.Body>
      <EphyLayout.Footer>
        {ephy.channel &&
          <EphyComments
            link={!location.state?.bgLocation
              ? {
                pathname: getEphyUrl(ephy),
                state: {bgLocation: location}
              }
              : undefined
            }
            count={ephy.commentsCount}
          />
          }
          {!ephy.channel
            ? <Button kind="text" onClick={editEphy}>Edit</Button>
            : <EphyReactions
                channelId={ephy.channel.id}
                ephyId={ephy.id}
                reactions={ephy.reactions}
              />
          }

          <Spacer />

          {ephy.channel && <EphyImpressions count={ephy.impressions} />}
          {showAuthor && <EphyAuthor profile={author} />}
          <EphyDatetime datetime={ephy.createdAt} />
        </EphyLayout.Footer>
      </EphyLayout>
  )
}
