import styles from './SubscriptionPill.module.scss'
import { ReactComponent as LockIcon } from 'icons/Lock.svg'

import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/Button'
import Avatar from 'components/Avatar'
import Pill from 'components/Pill'
import { cls } from 'utils'

export const SubscriptionPill = React.forwardRef(({
  name,
  photoUrl,
  slug = '',
  private: privat3 = false,
  isActive = false,
  isSelected = false,
  size = 'large',
  pillStyle = 'light',
  onClick,
  ...restProps
}, ref) => {

  let combinedProps = {
    size,
    pillStyle,
    active: isActive,
    selected: isSelected,
    onClick,
    ref,
    ...restProps,
  }

  if (onClick) combinedProps = {
    ...combinedProps,
    component: Button,
  }

  if (slug) combinedProps = {
    ...combinedProps,
    component: Link,
    to: { pathname: slug },
  }

  return (
    <Pill {...combinedProps}>
      <div className={styles.container}>
        <div className={styles.avatar}>
          <Avatar
            photoUrl={photoUrl}
            name={name}
            size={28} />
        </div>
        <div className={cls`${styles.name} ${privat3 && styles.private}`}>
          {name}
          {privat3 && <LockIcon />}
        </div>
      </div>
    </Pill>
  )
})

SubscriptionPill.propTypes = {}
