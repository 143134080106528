import {
  USERS_FETCH_REACTIONS_START,
  USERS_FETCH_REACTIONS_SUCCESS,
  USERS_FETCH_REACTIONS_ERROR,
} from '../userConstants'

const _fetchReactions = (firebase, userId, ephyIds) => {
  const reactionIds10ItemsPerChunk = []
  // breaking reactionIds into 10 items per chunk
  // because Firestore doesn't support "in" clause
  // with more than 10 items
  for (let i = 0, n = ephyIds.length; i < n; i += 10) {
    reactionIds10ItemsPerChunk.push(ephyIds.slice(i, i + 10))
  }

  return Promise.all(
    reactionIds10ItemsPerChunk.map(ephyIds => (
      firebase
        .firestore()
        .collection('reactions')
        .where('userId', '==', userId)
        .where('ephyId', 'in', ephyIds)
        .limit(10)
        .get()
        .then(reactionsSnapshot => {
          return reactionsSnapshot.docs.map(reaction => ({ id: reaction.id, ...reaction.data() }))
      })
    ))
  ).then(reactions10ItemsPerChunk => (
    reactions10ItemsPerChunk.reduce((allReactions, reactions) => [...allReactions, ...reactions], [])
  ))
}

export function fetchReactions(ephyIds) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: USERS_FETCH_REACTIONS_START })

    return new Promise((resolve, reject) => {
      if (!ephyIds || ephyIds.length === 0 || !getState().firebase.auth.uid) {
        resolve([])

        return dispatch({
          type: USERS_FETCH_REACTIONS_SUCCESS,
          payload: {
            data: []
          }
        })
      }

      try {
        _fetchReactions(getFirebase(), getState().firebase.auth.uid, ephyIds)
          .then(reactions => {
            resolve(reactions)

            dispatch({
              type: USERS_FETCH_REACTIONS_SUCCESS,
              payload: {
                data: reactions
              }
            })
          })
      } catch (error) {
        console.error(error)

        reject(error)

        dispatch({
          type: USERS_FETCH_REACTIONS_ERROR,
          payload: {
            error
          }
        })
      }
    })
  }
}
