import React from 'react'
import capitalize from 'utils/capitalize'

import styles from './MainLayout.module.scss'


export function MainLayout({children, variant}) {
  return (
    <div className={styles['content' + capitalize(variant)]}>
      {children}
    </div>
  )
}

MainLayout.MainContent = MainContent
MainLayout.LeftSidebar = LeftSidebar
MainLayout.RightSidebar = RightSidebar

export function MainContent(props) {
  return (
    <div className={styles.mainContent}>
      <div className={styles.container}>
        {props.children}
      </div>
    </div>
  )
}

export function LeftSidebar(props) {
  return (
    <div className={styles.leftSidebar}>
      <div className={styles.container}>
        {props.children}
      </div>
    </div>
  )
}

export function RightSidebar(props) {
  return (
    <div className={styles.rightSidebar}>
      <div className={styles.container}>
        {props.children}
      </div>
    </div>
  )
}
