import {
  USERS_FETCH_PRIVATE_TAGS_START,
  USERS_FETCH_PRIVATE_TAGS_SUCCESS,
  USERS_FETCH_PRIVATE_TAGS_ERROR,
} from '../userConstants'

import isCallable from 'is-callable'

export function subscribeToPrivateTags(callback) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: USERS_FETCH_PRIVATE_TAGS_START })

    const userId = getState().firebase.auth.uid
    let callbackFired = false

    return getFirebase()
      .firestore()
      .collection('users')
      .doc(userId)
      .collection('tags')
      .orderBy('order', 'asc')
      .onSnapshot(
        querySnapshot => {
          const tagsList = []
          querySnapshot.forEach(tag => {
            tagsList.push(Object.assign({ id: tag.id }, tag.data()))
          })

          if (!callbackFired && isCallable(callback)) {
            callbackFired = true
            callback(tagsList)
          }

          dispatch({
            type: USERS_FETCH_PRIVATE_TAGS_SUCCESS,
            payload: {
              data: tagsList
            }
          })
      },
      error => {
        console.error(error)

        dispatch({
          type: USERS_FETCH_PRIVATE_TAGS_ERROR,
          payload: {
            error
          }
        })
      }
    )
  }
}
