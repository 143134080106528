import {
  USERS_EPHY_LIMIT,
  USERS_FETCH_PRIVATE_EPHIES_START,
  USERS_FETCH_PRIVATE_EPHIES_SUCCESS,
  USERS_FETCH_PRIVATE_EPHIES_ERROR,
} from '../userConstants'

import { createRecordsLoader, SNAPSHOT_PROCESSOR_EPHY } from 'utils/infiniteScroll'

export function subscribeToPrivateEphies(params = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: USERS_FETCH_PRIVATE_EPHIES_START })

    const { tag } = params

    const createQuery = ({ startAfter, endBefore } = {}) => {
      let query = getFirebase()
        .firestore()
        .collection('users')
        .doc(getState().firebase.auth.uid)
        .collection('ephies')

      if (tag) {
        query = query.where('tags', 'array-contains', tag)
      }

      query = query
        .orderBy('pinned', 'desc')
        .orderBy('createdAt', 'desc')
        .limit(USERS_EPHY_LIMIT)

      if (startAfter) {
        query = query.startAfter(startAfter)
      }

      if (endBefore) {
        query = query.endBefore(endBefore)
      }

      return query
    }

    const { load, loadMore, unsubscribe } = createRecordsLoader({
      createQuery,
      dispatch,
      limit: USERS_EPHY_LIMIT,
      successAction: USERS_FETCH_PRIVATE_EPHIES_SUCCESS,
      errorAction: USERS_FETCH_PRIVATE_EPHIES_ERROR,
      snapshotProcessor: SNAPSHOT_PROCESSOR_EPHY,
      bumpEphyImpressions: false,
    })

    load()

    return {
      unsubscribe,
      loadMore
    }
  }
}
