import React from 'react'

import styles from './SplitPaneLayout.module.scss'

export const SplitPaneLayout = ({leftPane, rightPane}) => (
  <div className={styles.container}>
    <div className={styles.leftPane}>
      {leftPane}
    </div>
    <div className={styles.rightPane}>
      {rightPane}
    </div>
  </div>
)