
import {
  APP_SWITCH_THEME,
} from './appConstants'

const initialState = {
  theme: 'light',
}

export default function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    case APP_SWITCH_THEME:
      switch (payload.theme) {
        case 'dark':
        case 'light':
        case 'sepia':
          return {...state,
            theme: payload.theme
          }

        default:
          return {...state,
            theme: 'light'
          }
      }

    default:
      return state
  }
}
