import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { UserAuthenticatedMenu, UserNotAuthenticatedMenu } from 'components/UserMenu'
// import useOnClickOutside from 'use-onclickoutside'
import { ReactComponent as ProfileIcon } from './Profile.svg'
import Dropdown from 'components/Dropdown'
import Button from 'components/Button'


const UserMenuItem = ({ auth }) => {

  return (
    <Dropdown
      dropdown={
        auth.isEmpty
          ? <UserNotAuthenticatedMenu />
          : <UserAuthenticatedMenu />}
      button={<Button kind="icon" size="32"><ProfileIcon /></Button>}
      offset="15"
      maxHeight="80"
    />
  )
}

UserMenuItem.propTypes = {
  channels: PropTypes.array,
  // showAddButton: PropTypes.bool,
  // activeChannelId: PropTypes.string,
  // onEphyCreatePressed: PropTypes.func
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
})

export default connect(mapStateToProps)(UserMenuItem)
