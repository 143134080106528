import styles from './Avatar.module.scss'
import {ReactComponent as AvatarIcon} from 'icons/NoAvatar.svg'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

export default class Avatar extends Component {
  static propTypes = {
    photoUrl: PropTypes.string,
    linkTo: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    border: PropTypes.bool,
    onClick: PropTypes.func
  }

  static defaultProps = {
    className: '',
    photoUrl: '',
    linkTo: '',
    name: '',
    size: 1,
    border: false,
    onClick: () => {}
  }

  render() {
    const {
      className,
      photoUrl,
      linkTo,
      name,
      size,
      border,
      onClick,
      ...restProps
    } = this.props

    const avatarContainerStyles = {
      width: this.props.size,
      height: this.props.size
    }

    const avatarStyles = {
      width: border ? size - 8 : size,
      height: border ? size - 8 : size,
    }

    const wrapperCombinedProps = {
      className,
      onClick,
      ...restProps
    }

    if (linkTo) {
      wrapperCombinedProps.to = linkTo
    }

    const avatar =
      <div className={classnames(styles.avatarContainer, {[styles.avatarBordered]: border})} style={avatarContainerStyles}>
        {!photoUrl && !name
          ? <span className={styles.avatar} style={avatarStyles}><AvatarIcon /></span>
            : photoUrl
              ? <img src={photoUrl} className={styles.avatar} style={avatarStyles} alt={name} title={name} />
              : <div className={styles.avatarNoImage} title={name}>{name && name[0].toUpperCase()}</div>}
      </div>

    const wrapper = linkTo ? Link : 'div'

    return React.createElement(wrapper, wrapperCombinedProps, avatar)
  }
}
