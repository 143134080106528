import {
  EPHIES_DELETE_COMMENT_START,
  EPHIES_DELETE_COMMENT_SUCCESS,
  EPHIES_DELETE_COMMENT_ERROR,
} from '../ephyConstants'

/**
 * @param {String} commentId
 */
export function deleteComment(commentId) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_DELETE_COMMENT_START })

    return getFirebase()
      .firestore()
      .collection('comments')
      .doc(commentId)
      .update({
        deleted: true
      })
      .then(() => {
        dispatch({
          type: EPHIES_DELETE_COMMENT_SUCCESS,
          payload: {
            data: {
              id: commentId
            }
          }
        })
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: EPHIES_DELETE_COMMENT_ERROR,
          error,
        })
      })
  }
}
