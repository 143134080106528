import styles from './ThemeSelector.module.scss'

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { switchTheme } from 'app/appActions'
import isCallable from 'is-callable'
import { cls } from 'utils'

export default function ThemeSelector({className, onThemeChange}) {
  const dispatch = useDispatch()
  const currentTheme = useSelector(({ app }) => app.theme)
  const themes = [
    {
      title: 'Light',
      theme: 'light',
      color: '#fafafb'
    },
    {
      title: 'Sepia',
      theme: 'sepia',
      color: '#f5f4f2'
    },
    {
      title: 'Dark',
      theme: 'dark',
      color: '#15171b'
    },
  ]
  return (
    <div className={cls`${styles.container} ${className}`}>
      {themes.map(({ title, theme, color }) => (
        <div className={styles.control} key={theme}>
          <input
            type="radio"
            name="theme"
            id={`theme_${theme}`}
            className={styles.input}
            checked={theme === currentTheme}
            value={theme}
            onChange={e => {
              dispatch(switchTheme(e.target.value))
              isCallable(onThemeChange) && onThemeChange()
            }}
          />
          <label
            className={styles.label}
            style={{'--data-color': color}}
            htmlFor={`theme_${theme}`}
          >
              {title}
          </label>
        </div>
      ))}
    </div>
  )
}
