import styles from './ChannelView.module.scss'

import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import MetaTags from 'react-meta-tags'
import { useParams } from 'react-router-dom'
import HashLinkObserver from 'react-hash-link'
import sanitizeHtml  from 'sanitize-html'
import { push } from 'connected-react-router'

import { MainLayout } from 'layout'
import ChannelInfo from 'modules/channel/components/ChannelInfo'
import TagList from './components/TagList'
import EphiesList from 'modules/ephy/components/EphiesList'
import { PulsingLoader } from 'components/Loader'
import { Error404View } from 'modules/error'
import * as actions from './channelActions'
import { fetchPublicUserProfiles } from 'modules/user/userActions'
import { OwnChannelNoContent, AnothersChannelNoContent} from './components/ChannelNoContent'

import {
  USERS_SHOW_EPHY_AUTHOR_NEVER,
  USERS_SHOW_EPHY_AUTHOR_MULTIPLE_HOSTS,
  USERS_SHOW_EPHY_AUTHOR_ALWAYS,
  USERS_DEFAULT_USER_SETTINGS,
} from 'modules/user/userConstants'
import RookieOwnChannelTour from 'modules/tours/RookieOwnChannelTour'
import EphiesListItem from 'modules/ephy/components/EphiesListItem'
import Comments from 'modules/ephy/components/Comments/Comments'

export default function ChannelView() {
  const dispatch = useDispatch()
  const { channel_slug, ephy_slug, tag } = useParams()
  const activeTag = useMemo(() => tag ? decodeURIComponent(tag) : null, [tag])
  const activeEphyId = useMemo(() => ephy_slug ? ephy_slug.split('-').pop() : null, [ephy_slug])

  const auth = useSelector(({ firebase }) => firebase.auth)
  const profile = useSelector(({ firebase }) => firebase.profile)
  const channel = useSelector(({ channel }) => channel.channel)
  const tags = useSelector(state => state.channel.tags[channel.id] || [])
  const ephies = useSelector(({ channel }) => channel.ephies)
  const isUserChannelAdmin = useMemo(() => channel.admins?.includes(auth.uid), [channel, auth])
  const userSettings = useMemo(() => ({...USERS_DEFAULT_USER_SETTINGS, ...profile.settings}), [profile])

  useEffect(() => {
    if (channel && channel.private && auth && auth.isLoaded && auth.isEmpty) {
      dispatch(push('/login'))
    }
  }, [dispatch, auth, channel])

  const channelFetchInProgress = useSelector(({ channel }) => channel.channelFetchInProgress)
  const ephiesFetchInProgress = useSelector(({ channel }) => channel.ephiesFetchInProgress)

  const title = useMemo(() => {
    if (activeEphyId && ephies[0]) {
      return `${ephies[0].tags.length > 0 ? `${ephies[0].tags[0]} – ` : ''}${channel.name || ''}`
    }

    return `${activeTag ? `${activeTag} – ` : ''}${channel.name || ''}`
  }, [channel.name, activeTag, activeEphyId, ephies])

  const description = useMemo(() => {
    if (activeEphyId && ephies[0]) {
      const textFiltered = sanitizeHtml(ephies[0].text, {
        allowedTags: [],
        allowedAttributes: {}
      })
      const firstParagraph = textFiltered.slice(0, 140)

      return firstParagraph + (firstParagraph.length === 140 ? '...' : '')
    }

    return channel.description || `${channel.name || ''} channel on Lightspot`
  }, [channel.name, channel.description, activeEphyId, ephies])

  const imageUrl = useMemo(() => {
    if (activeEphyId && ephies[0]) {
      // console.log(activeEphyId)
      const images = ephies[0].text.match(/<img.*?src\s*=\s*"(.+?)"/i)
      // console.log(ephies[0].text)
      // console.log(images)
      if (images && images.length > 0) {
        return images[1]
      }
    }

    return channel.photoUrl || (process.env.REACT_APP_PUBLIC_URL + '/ephy-og-default.png')
  }, [channel.photoUrl, activeEphyId, ephies])

  const [ephiesSubscription, setEphiesSubscription] = useState(null)
  const [hasMoreEphies, setHasMoreEphies] = useState(true)

  const showAuthor = useMemo(() => {
    if (userSettings.showEphyAuthor === USERS_SHOW_EPHY_AUTHOR_NEVER)
      return false

    if (userSettings.showEphyAuthor === USERS_SHOW_EPHY_AUTHOR_ALWAYS)
      return true

    if (userSettings.showEphyAuthor === USERS_SHOW_EPHY_AUTHOR_MULTIPLE_HOSTS && channel.admins?.length > 1)
      return true

    return false
  }, [userSettings, channel])

  useEffect(
    () => {
      setHasMoreEphies(true)
    }, [channel.id]
  )

  useEffect(() => {
    if (channel_slug)
      return dispatch(actions.subscribeToChannel({ slug: channel_slug }))
  }, [dispatch, channel_slug])

  useEffect(() => {
    if (channel.admins)
      dispatch(fetchPublicUserProfiles(channel.admins))
  }, [dispatch, channel.admins])


  useEffect(() => {
    if (channel.id && channel.slug === channel_slug)
      return dispatch(actions.subscribeToChannelTags(channel.id))
  }, [dispatch, channel.id, channel.slug, channel_slug])

  useEffect(() => {
    if (channel.id && channel.slug === channel_slug) {
      const ephiesSub = dispatch(actions.subscribeToChannelEphies(channel.id, {
        id: activeEphyId,
        tag: activeTag,
        bumpImpressionsCount: true,
      }))

      setEphiesSubscription(ephiesSub)

      return ephiesSub.unsubscribe
    }
  }, [dispatch, channel.id, channel.slug, activeTag, activeEphyId, channel_slug])

  const emptyMessage = useCallback(() => (
    <div className={styles.noContent}>
      {isUserChannelAdmin ? <OwnChannelNoContent /> : <AnothersChannelNoContent />}
    </div>
  ), [isUserChannelAdmin])

  const onLoadMoreEhpies = useCallback(
    () => {
      if (!ephiesSubscription) return
      ephiesSubscription.loadMore().then(lastEphy => {
        const hasMore = !!lastEphy
        if (hasMore !== hasMoreEphies) {
          setHasMoreEphies(hasMore)
        }
      })
    }, [ephiesSubscription, hasMoreEphies]
  )

  const allowSubscription = useMemo(() => {
    return channel.admins?.length > 1 || !isUserChannelAdmin
  }, [isUserChannelAdmin, channel.admins])

  if (!profile.isLoaded || channelFetchInProgress)
    return <PulsingLoader />

  if (!channel.id)
    return <Error404View />

  return (
    <>
      <MetaTags>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
      </MetaTags>

      <MainLayout>
        <MainLayout.LeftSidebar>
          <ChannelInfo
            channel={channel}
            tags={tags}
            allowSubscription={allowSubscription}
            isUserChanelAdmin={isUserChannelAdmin}
          />
        </MainLayout.LeftSidebar>

        <MainLayout.MainContent>
          {ephiesFetchInProgress
            ? <PulsingLoader position="fixed" />
            : activeEphyId
              ? <>
                  {ephies[0] &&
                    <>
                    <EphiesListItem
                      ephy={ephies[0]}
                      truncate={false}
                    />
                    <Comments
                      ephyId={ephies[0].id}
                      channelId={ephies[0].channel.id}
                      ephyCommentsCount={ephies[0].commentsCount}
                    />
                    </>
                  }
                </>
              : <EphiesList
                ephies={ephies}
                // showChannelAvatarOnDesktop={false}
                showChannelAvatar={false}
                showAuthor={showAuthor}
                activeTag={activeTag}
                emptyMessage={emptyMessage}
                editable={isUserChannelAdmin}
                loadMoreEphies={onLoadMoreEhpies}
                hasMoreEphies={hasMoreEphies}
              />
          }
        </MainLayout.MainContent>

        <MainLayout.RightSidebar>
          <TagList
            tags={tags}
            channel={channel}
            activeTag={activeTag}
            editable={isUserChannelAdmin}
          />
        </MainLayout.RightSidebar>
      </MainLayout>
      <HashLinkObserver />
      {isUserChannelAdmin && <RookieOwnChannelTour />}
    </>
  )
}
