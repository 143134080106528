import {useRef} from 'react'

const isOverflownFn = ({ clientWidth, clientHeight, scrollWidth, scrollHeight } = {}) => {
  // console.log(clientWidth, clientHeight, scrollWidth, scrollHeight)
  return scrollHeight > clientHeight || scrollWidth > clientWidth
}

export const useIsBoxOverflown = () => {
  const boxRef = useRef()
  // const [isOverflown, setIsOverflown] = useState(false)
  // const newIsOverflown = isOverflownFn(boxRef?.current)
  // if (isOverflown !== newIsOverflown) setIsOverflown(newIsOverflown)
  // useLayoutEffect(() => {})
  return [boxRef, isOverflownFn(boxRef?.current)]
}