import React from 'react'
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getFirebase } from 'react-redux-firebase'
import classnames from 'classnames'
import MetaTags from 'react-meta-tags'

// import AppLayout from 'layout/AppLayout'
import Button from 'components/Button'
import { HelloUser } from 'components/Onboarding'
import formStyles from './Form.module.scss'
import styles from './Auth.module.scss'
import { ReactComponent as FBIcon } from './icons/Fb.svg'
import { ReactComponent as GoogleIcon } from './icons/Google.svg'

class LoginView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      showErrorMessage: false,
    }

    this.firebase = getFirebase()
  }

  login() {
    this.firebase
      .login({ email: this.state.email, password: this.state.password })
      .catch(e => {
        this.setState({ showErrorMessage: true })
      })
  }

  loginWithGoogle() {
    this.firebase.login({ provider: 'google', type: 'redirect' })
  }

  loginWithFacebook() {
    this.firebase.login({ provider: 'facebook', type: 'redirect' })
  }

  render () {
    const emailClass = classnames(
      formStyles.formControl, {
        [formStyles.isInvalid]:
          this.state.showErrorMessage
            && this.props.authError
            && (this.props.authError.code === 'auth/user-not-found' || this.props.authError.code === 'auth/invalid-email')
      }
    )

    const passwordClass = classnames(
      formStyles.formControl, {
        [formStyles.isInvalid]:
          this.state.showErrorMessage
            && this.props.authError
            && this.props.authError.code === 'auth/wrong-password'
      }
    )

    return (
      <>
        <MetaTags>
          <title>Login – Lightspot</title>
          <meta property="og:title" content="Login – Lightspot" />
        </MetaTags>

        <HelloUser showPolicy={false}>
          <form className={formStyles.form}>
            <div className={emailClass}>
              <input
                type="text"
                name="email"
                maxLength="200"
                autoComplete="username"
                className={formStyles.input}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value, showErrorMessage: false })}
                placeholder="Email"
                autoFocus
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  {this.props.authError && this.props.authError.code === 'auth/user-not-found'
                    ? 'User not found'
                    : 'Email is not valid'}
                </small>
              </div>
            </div>
            <div className={passwordClass}>
              <input
                type="password"
                name="password"
                maxLength="100"
                autoComplete="current-password"
                className={formStyles.input}
                id="loginPassword"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value, showErrorMessage: false })}
                placeholder="Password"
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Password is not valid
                </small>
                <small className={styles.forgot}>
                  <Link to="/reset_password">Forgot?</Link>
                </small>
              </div>
            </div>
            <Button
              kind="primary"
              isBlocky={true}
              onClick={e => { e.preventDefault(); this.login() }}>
              Log in
            </Button>
            <div className={formStyles.alternatives}>
              <p>
                <Link to="/signup">Signup with email</Link><span>or login/signup with social media</span>
              </p>
              <Button kind="iconBordered" onClick={e => { e.preventDefault(); this.loginWithGoogle() }}>
                <GoogleIcon />
              </Button>
              <Button kind="iconBordered" onClick={e => { e.preventDefault(); this.loginWithFacebook() }}>
                <FBIcon />
              </Button>
            </div>
          </form>
        </HelloUser>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authError: state.firebase.authError,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
