import formStyles from 'modules/auth/Form.module.scss'
import styles from './ChannelVisibilitySettings.module.scss'
import { ReactComponent as LockIcon } from 'icons/Lock.svg'

import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import copyToClipboard from 'clipboard-copy'

import Button from 'components/Button'
// import UserList from 'modules/channel/components/UserList'
// import { featureUsed } from 'utils'
// import Button from 'components/Button'
import { updateChannel } from 'modules/channel/channelActions'
import { toast } from 'react-toastify'

const makePrivateMessage = '* All channel posts and topics will become private\n'
  + '* Subscribers will retain access to the channel\n'
  + '\nMake it private?'
const makePublicMessage = '* All channel posts and topics will become public\n'
  + '\nMake it public?'

const copyInviteLinkToClipboard = (inviteToken) => {
  const link = new URL(`/join_channel/${inviteToken}`, document.location).href
  copyToClipboard(link).then(() => {
    toast.success('Link copied to clipboard')
  })
}

const PrivateVisibilityDescription = ({ inviteToken }) => (
  <>
    <p>
      Your channel is <strong>private <LockIcon /></strong><br/>
      Posts and topics are available to members only.&nbsp;
      <Link to={`/join_channel/${inviteToken}`} onClick={e => { e.preventDefault(); copyInviteLinkToClipboard(inviteToken) }}>
        <strong>Click here</strong>
      </Link> to copy invitation link
    </p>
  </>
)

const PublicVisibilityDescription = () => (
  <>
    <p>
      Your channel is <strong>public</strong><br/>
      Posts and topics are available to everyone including search engines
    </p>
  </>
)


export function ChannelVisibilitySettings({ channel }) {
  const dispatch = useDispatch()
  const channelFetchInProgress = useSelector(({ channel }) => channel.channelFetchInProgress)
  // const currentUserId = useSelector(state => state.firebase.auth.uid)
  // const publicUserProfiles = useSelector(state => state.user.publicUserProfiles)

  // useEffect(() => {
    // dispatch(fetchPublicUserProfiles(channel.members))
  // }, [dispatch, channel.members])

  const isPrivate = useMemo(() => channel.private, [channel])

  const toggleChannelVisibility = (e) => {
    e.preventDefault()

    if (window.confirm(isPrivate ? makePublicMessage : makePrivateMessage)) {
      dispatch(updateChannel({ ...channel, private: !isPrivate }, () => {
        toast.success(`Channel became ${isPrivate ? 'public' : 'private'}`)
      }))
    }
  }

  return (
    <section className={styles.channelVisibilitySettings}>
      <form className={formStyles.form}>
        {isPrivate
          ? <PrivateVisibilityDescription
              inviteToken={channel.inviteToken}
            />
          : <PublicVisibilityDescription />
        }

        <Button
          onClick={toggleChannelVisibility}
          kind="primary"
          isBlocky={true}
          disabled={channelFetchInProgress}
        >
          Make {isPrivate ? 'public' : 'private'}
        </Button>
      </form>
    </section>
  )
}
