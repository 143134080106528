export function fetchEphy(channelId, ephyId) {
  return (_dispatch, _getState, getFirebase) => {
    return getFirebase()
      .firestore()
      .collection('channels')
      .doc(channelId)
      .collection('ephies')
      .doc(ephyId)
      .get()
      .then(ephySnapshot => {
        if (ephySnapshot.exists) {
          return { id: ephySnapshot.id, ...ephySnapshot.data() }
        } else {
          return false
        }
      })
  }
}
