import {
  CHANNELS_CREATE_CHANNEL_START,
  CHANNELS_CREATE_CHANNEL_SUCCESS,
  CHANNELS_CREATE_CHANNEL_ERROR,
} from '../channelConstants'

import { uniqueid } from 'utils'
import GetStartedData from '../data/GetStartedData'
import { createTag } from 'modules/tag/tagActions'
import { createEphy } from 'modules/ephy/ephyActions'

export function createChannel(channel, { createGetStartedData = true } = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({
      type: CHANNELS_CREATE_CHANNEL_START,
    })

    const channelData = {
      photoUrl: channel.photoUrl || '',
      name: channel.name.trim(),
      slug: channel.slug?.toLowerCase() || uniqueid(),
      description: channel.description || '',
      type: channel.type || '',
      private: !!channel.private,
      deleted: false,
      inviteToken: uniqueid({ length: 64 }),
      admins: [
        getState().firebase.auth.uid
      ],
      members: [],
      subscribersCount: 0,
      viewsCount: 0,
      createdBy: getState().firebase.auth.uid,
      createdAt: getFirebase().firestore.Timestamp.now(),
    }

    return new Promise((resolve, reject) => {
      getFirebase()
        .firestore()
        .collection('channels')
        .add(channelData)
        .then(channelSnapshot => {
          const channel = { id: channelSnapshot.id, ...channelData }

          resolve(channel)

          dispatch({
            type: CHANNELS_CREATE_CHANNEL_SUCCESS,
            payload: {
              data: channel
            }
          })

          if (createGetStartedData) {
            GetStartedData.tags.forEach(tag => {
              dispatch(createTag(tag, { channel }))
            })

            GetStartedData.ephies.forEach(ephy => {
              dispatch(createEphy(ephy, { channel }))
            })
          }
        })
        .catch(error => {
          console.error(error)

          reject(error)

          dispatch({
            type: CHANNELS_CREATE_CHANNEL_ERROR,
            payload: {
              error
            }
          })
        })
    })
  }
}
