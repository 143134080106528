import styles from './Pill.module.scss'

import React from 'react'
import classnames from 'classnames'
// import PropTypes from 'prop-types'

const Pill = React.forwardRef(({
  active = false,
  selected = false,
  size = 'medium', // small|medium|large
  pillStyle = 'light', // light|dark
  clickable = true,
  component = 'span',
  className,
  children,
  ...restProps
}, ref) => {
  const combinedProps = {
    className: classnames(
      styles[pillStyle],
      styles[size],
      {
        [styles.selected]: selected,
        [styles.active]: active,
        [styles.clickable]: clickable,
      },
      className
    ),
    ref,
    ...restProps
  }
  return React.createElement(component, combinedProps, children)
})

Pill.propTypes = {}

export default Pill
