import styles from './LandingPageView.module.scss'
import {ReactComponent as LogoIcon} from './Logo.svg'
import {ReactComponent as LogoSIcon} from './LogoS.svg'
// import {ReactComponent as DiscIcon} from './Disc.svg'
// import {ReactComponent as PixelIcon} from './Pixel5.svg'
// import {ReactComponent as FullIcon} from './Full.svg'
import FullSlide1Img from './Full1.png'
import FullSlide2Img from './Full2.png'
import FullSlide3Img from './Full3.png'

import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import Button from 'components/Button'
import { Slider } from 'components/Slider/Slider'
// import Spacer from 'components/Spacer'

export default function LandingPageView() {
  const history = useHistory()

  return (
    <>
      <MetaTags>
        <title>Lightspot</title>
        <meta property="og:title" content="Lightspot" />
        <meta name="keywords" content="ephy,effy,efi,share,insights,capture,knowledge,эфи,insights online" />
        <meta name="description" content="Get early access to a new place for sharing your insights the way you want to" />
        <meta property="og:description" content="Get early access to a new place for sharing your insights the way you want to" />
        <meta property="og:image" content={process.env.REACT_APP_PUBLIC_URL + '/ephy-og-default.png'} />
      </MetaTags>

      <div className={styles.container}>
        <header className={styles.header}>
          <LogoSIcon />
          <nav>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.notion.so/lightspot/Lightspot-About-us-ff6853e8678449519cb95d56ba1ef479">
                    About
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.notion.so/lightspot/Lightspot-Job-opportunities-b5247521074d47889ce5e341739f1745">
                    Jobs
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.notion.so/lightspot/Lightspot-Contact-us-30a738ef202a404c877ac70425b1d7f6">
                    Contact us
                </a>
              </li>
            </ul>
          </nav>
        </header>
          <Slider>
            <div className={styles.firstScreen}>
              <div className={styles.left}>
                <div className={styles.content}>
                  <LogoIcon />
                  <h1>Start and curate topic-based channels</h1>
                  <Slider.Slides animation="slide">
                    <p>👋 A community platform for STEM professionals and students to share and discuss new innovations </p>
                    <p>👾 More seamless than chats or blogs. Discuss advancements in AI, neuroscience and more</p>
                    <p>👨‍👦 Co-host it with peers, colleagues or friends. And get tips for your insights.</p>
                  </Slider.Slides>
                  <Slider.Nav className={styles.sliderDots} activeClass={styles.active}>
                    <div className={styles.dot} />
                  </Slider.Nav>
                  <Button
                    type="button"
                    kind="primary"
                    onClick={() => history.push('/signup')}
                  >
                    Get Started for Free
                  </Button>
                  <p className={styles.alternativeAction}>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
              <div className={styles.right}>
                {/* <div className={styles.content}> */}
                  {/* <Spacer /> */}
                  <Slider.Slides className={styles.mainSlider}>
                    <img src={FullSlide1Img} alt="Slide 1" />
                    <img src={FullSlide2Img} alt="Slide 2" />
                    <img src={FullSlide3Img} alt="Slide 3" />
                  </Slider.Slides>
                {/* </div> */}
              </div>
            </div>
          </Slider>
      </div>
    </>
  )
}
