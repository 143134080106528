import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { featureUsed } from 'utils'
import { toast } from 'react-toastify'
import copyToClipboard from 'clipboard-copy'

import { useBreakpoints, useScroll } from 'hooks'
import { ChannelInfoHorizontal } from './ChannelInfoHorizontal'
import { ChannelInfoVertical } from './ChannelInfoVertical'

export const ChannelInfo = ({
  channel,
  tags,
  allowSubscription,
  isUserChanelAdmin,
}) => {
  const { isDesktop } = useBreakpoints()
  const { scrollY, scrollDirection } = useScroll()

  const subscriptions = useSelector(state => state.feed.subscriptions)
  // const tags = useSelector(state => state.channel.get('tags'))

  function copyLink(event) {
    const resourceLink = new URL(`/${channel.slug}`, document.location).href

    copyToClipboard(resourceLink).then(() => {
      toast.success('Link copied to clipboard')
      featureUsed({ action: 'channel_copy_link_to_clipboard', category: 'channel', label: 'Copy Channel link to clipboard' })
    })
  }

  if (isDesktop) {
    return (
      <div style={{display: scrollY > 0 && scrollDirection === 'up' ? 'none' : 'block'}}>
        <ChannelInfoVertical
          channel={channel}
          tags={tags}
          subscriptions={subscriptions}
          allowSubscription={allowSubscription}
          isUserChannelAdmin={isUserChanelAdmin}
          onCopyLinkClick={copyLink}
        />
      </div>
    )
  }

  return <ChannelInfoHorizontal
    channel={channel}
    tags={tags}
    subscriptions={subscriptions}
    allowSubscription={allowSubscription}
    isUserChannelAdmin={isUserChanelAdmin}
    onCopyLinkClick={copyLink}
  />
}

ChannelInfo.propTypes = {
  channel: PropTypes.object.isRequired,
  allowSubscription: PropTypes.bool,
}
