import formStyles from 'modules/auth/Form.module.scss'

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import MetaTags from 'react-meta-tags'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import isCallable from 'is-callable'
import HashLinkObserver from "react-hash-link"

import { PulsingLoader } from 'components/Loader'
import Button from 'components/Button'
import Avatar from 'components/Avatar'
import * as actions from './channelActions'
import * as userActions from 'modules/user/userActions'
import * as tagActions from 'modules/tag/tagActions'
import { ChannelAdmins, ChannelMembers } from './components/ChannelUsers'
import { ChannelVisibilitySettings } from './components/ChannelVisibilitySettings'
import { ChannelInvitationSettings } from './components/ChannelInvitationSettings'
import { generateUniqueChannelSlug } from 'utils/channel'
import { Error404View } from 'modules/error'

class ChannelSettingsView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: '',
      name: '',
      slug: '',
      description: '',
      photoUrl: '',
      showErrorMessage: false,
    }

    this.filePickerRef = React.createRef()
  }

  componentDidMount() {
    this.subscribeToChannelData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.channelId !== this.props.channelId) {
      this.unsubscribeFromChannelData()
      this.subscribeToChannelData()
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromChannelData()
  }

  subscribeToChannelData() {
    this.unsubscribeFromChannel = this.props.actions.subscribeToChannel({ id: this.props.channelId }, channel => {
      this.setState({
        id: channel.id,
        name: channel.name,
        slug: channel.slug,
        description: channel.description,
        photoUrl: channel.photoUrl,
      })

      this.unsubscribeFromTags = this.props.actions.subscribeToChannelTags(channel.id)
    })
  }

  unsubscribeFromChannelData() {
    this.unsubscribeFromChannel()

    if (isCallable(this.unsubscribeFromTags)) {
      this.unsubscribeFromTags()
    }
  }

  async updateChannel(event) {
    event.preventDefault()

    const formValidation = this.validateForm()

    if (!formValidation.name || !formValidation.slug) {
      return this.setState({
        showErrorMessage: true
      })
    }

    const slug = await generateUniqueChannelSlug({
      id: this.state.id,
      slug: this.state.slug,
      private: this.props.channel.private,
    })

    this.setState({
      slug
    }, () => {
      const channelData = {
        id: this.state.id,
        name: this.state.name,
        description: this.state.description,
        slug: this.state.slug,
        photoUrl: this.state.photoUrl,
      }

      return this.props.actions.updateChannel(channelData, () => {
        toast.success('Channel updated')
      })
    })
  }

  validateForm() {
    return {
      name: this.state.name.trim().length > 0,
      slug: /^[a-zA-Z0-9-]{6,}$/.test(this.state.slug)
    }
  }

  uploadChannelPhoto(event) {
    if (event.target.files[0]) {
      this.props.actions.uploadChannelPhoto(this.props.channel, event.target.files[0])
        .then(photoUrl => {
          if (photoUrl) {
            toast.success('Channel photo uploaded')
            this.setState({ photoUrl })
          }
        })
    }
  }

  removeChannelPhoto(event) {
    event.preventDefault()

    this.setState({ photoUrl: '' }, () => toast.success('Channel photo removed'))
  }

  onDeleteClick(e) {
    e.preventDefault()

    const warningMessage = `Are you sure you want to delete the channel "${this.props.channel.name}"?\n`
      + 'All posts, topics and channel subscriptions will be removed.\n'
      + 'This cannot be undone'

    if (window.confirm(warningMessage)) {
      this.unsubscribeFromChannelData()

      this.props.actions.deleteChannel(this.props.channel).then(() => {
        toast.success('Channel deleted')

        this.props.dispatch(push('/settings'))
      })
    }
  }

  triggerFilePicker(e) {
    e.preventDefault()

    this.filePickerRef.current.click()
  }

  render() {
    if (!this.props.channel.id || this.props.channelFetchInProgress) {
      return <PulsingLoader />
    }

    if (!this.props.channel.admins.includes(this.props.auth.uid)) {
      return <Error404View />
    }

    const formValidation = this.validateForm()

    return (
      <>
        <MetaTags>
          <title>{this.props.channel.name} – Lightspot channel settings</title>
          {/* <meta property="og:title" content={this.getTitle()} /> */}
          {/* <meta name="description" content={this.getDescription()} /> */}
          {/* <meta property="og:image" content="path/to/image.jpg" /> */}
        </MetaTags>

        <section>
          <h1>Channel settings</h1>

          <form className={formStyles.form}>
            <div className={formStyles.fileInput}>
              <label
                id="channel-photo-file-label"
                htmlFor="channel-photo-file">
                <Avatar
                  photoUrl={this.state.photoUrl}
                  linkTo={`/${this.props.channel.slug}`}
                  onClick={e => e.preventDefault()}
                  name={this.state.name}
                  size={40} />
              </label>
                <input
                  id="channel-photo-file"
                  ref={this.filePickerRef}
                  type="file"
                  onChange={this.uploadChannelPhoto.bind(this)}
                  accept="image/*" />
                <Button
                  kind="text"
                  htmlFor="user-photo-file"
                  onClick={this.triggerFilePicker.bind(this)}
                >
                  Upload avatar
                </Button>
                <Button
                  kind="text"
                  onClick={this.removeChannelPhoto.bind(this)}
                >
                  Remove avatar
                </Button>
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: this.state.showErrorMessage && !formValidation.name } )}>
              <label>Channel name</label>
              <input
                type="text"
                name="name"
                maxLength="100"
                className={formStyles.input}
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value, showErrorMessage: false })}
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Hey, what's your channel name? :)
                </small>
              </div>
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: false })}>
              <label>About channel (optional)</label>
              <textarea
                name="description"
                maxLength="1000"
                style={{height: '70px'}}
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value, showErrorMessage: false })}
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Channel description :)
                </small>
              </div>
            </div>

            <div className={classnames(formStyles.formControl, {[formStyles.isInvalid]: this.state.showErrorMessage && !formValidation.slug } )}>
              <label>Channel address</label>
              <input
                type="text"
                name="channel-address"
                className={formStyles.input}
                value={this.state.slug}
                onChange={e => this.setState({ slug: e.target.value, showErrorMessage: false })}
                aria-describedby="slugHelp"
              />
              <div className={formStyles.additionalControlInfo}>
                <small className={formStyles.invalidFeedback}>
                  Your new place deserves a good address. Should be unique, containing letters, numbers or "-" with 6 or more characters long
                </small>
                <small>
                  <a target="_blank" rel="noopener noreferrer" href={`/${this.state.slug}`}>{window.location.host}/{this.state.slug}</a>
                </small>
              </div>
            </div>

            <Button
              onClick={this.updateChannel.bind(this)}
              kind="primary"
              isBlocky={true}
              disabled={this.props.channelFetchInProgress}>Update channel</Button>
          </form>
        </section>

        <section id="admins">
          <h1>Co-hosts &amp; members</h1>

          <ChannelAdmins
            channel={this.props.channel}
          />

          <ChannelMembers
            channel={this.props.channel}
          />

          {this.props.channel.private &&
          <ChannelInvitationSettings
            channel={this.props.channel}
          />
          }
        </section>

        <section>
          <h1>Channel visibility</h1>

          <ChannelVisibilitySettings
            channel={this.props.channel}
          />
        </section>

        <section style={{marginTop: '16px'}}>
          <Button kind="dangerousText" isBlocky={true} onClick={this.onDeleteClick.bind(this)}>Delete channel</Button>
        </section>
        <HashLinkObserver />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  channelFetchInProgress: state.channel.channelFetchInProgress,
  channel: state.channel.channel,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
  tagActions: bindActionCreators(tagActions, dispatch),
  dispatch: dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSettingsView)
