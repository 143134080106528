import styles from './Comments.module.scss'

import React, { useCallback } from 'react'
import * as ephyActions from 'modules/ephy/actions'
import * as userActions from 'modules/user/actions'
import { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Comment from './Comment'
import { Link } from 'react-router-dom'
import { PulsingLoader } from 'components/Loader'
import InfiniteScroll from 'react-infinite-scroller'
import { featureUsed } from 'utils'

const CommentEditor = React.lazy(() => import('modules/ephy/components/Comments/CommentEditor'))

const extractCommentsValue = (comments, property) => (
  comments.reduce((acc, comment) => {
    acc.push(comment[property])
    if (comment.children)
      acc.push(...extractCommentsValue(comment.children, property))

    return acc
  }, [])
)

export default function Comments({ ephyId, channelId, ephyCommentsCount, rootRef }) {
  const dispatch = useDispatch()

  const [commentsSubscription, setCommentsSubscription] = useState(null)
  const [hasMoreComments, setHasMoreComments] = useState(true)

  const isSignedIn = !useSelector(state => state.firebase.auth.isEmpty)
  const comments = useSelector(state => state.ephy.comments[ephyId])
  const commentsFetchInProgress = useSelector(state => state.ephy.commentsFetchInProgress)
  // const ephyCommentsCount = useMemo(() => {
  // return comments?.reduce((acc, c) => acc + 1 +(c.children ? c.children.length : 0), 0) || 0
  // }, [comments])

  useEffect(() => {
    const commentsFetcher = dispatch(ephyActions.subscribeToComments({ ephyId, channelId }))
    setCommentsSubscription(commentsFetcher)
    return () => commentsFetcher.unsubscribe()
  }, [dispatch, ephyId, channelId])

  const loadMoreComments = useCallback(() => {
    if (commentsSubscription) {
      commentsSubscription
        .loadMore()
        .then(r => setHasMoreComments(!!r))
    }
  }, [commentsSubscription])

  useEffect(() => {
    if (comments?.length > 0) {
      const commentsAuthorIds = extractCommentsValue(comments, 'userId')
      dispatch(userActions.fetchPublicUserProfiles(commentsAuthorIds))
    }
  }, [dispatch, comments])

  function deleteComment(id) {
    dispatch(ephyActions.deleteComment(id))
  }

  function likeComment(commentId, like) {
    dispatch(ephyActions.likeComment(commentId, like))
    featureUsed({ action: 'liked_ephy_comment', category: 'comment', label: 'Liked Ephy\'s comment' })
  }

  useEffect(() => {
    if (comments?.length > 0) {
      const commentsIds = extractCommentsValue(comments, 'id')
      dispatch(userActions.fetchCommentLikes(commentsIds))
    }
  }, [dispatch, comments])

  // be wary of fast unmount of comment editor because it causes ckeditor crash
  const [commentsLoadStatus, setCommentsLoadStatus] = useState('idle')
  useEffect(() => {
    if (!commentsFetchInProgress) {
      if (commentsLoadStatus === 'idle') return
      if (commentsLoadStatus === 'loading') setCommentsLoadStatus('loaded')
    }
    if (commentsFetchInProgress) setCommentsLoadStatus('loading')
  }, [commentsFetchInProgress, commentsLoadStatus])

  if (commentsLoadStatus !== 'loaded') return null

  return (
    <div className={styles.root}>
      <header>
        <h2>Discussion</h2>
        <p>
          <strong>{ephyCommentsCount || 0}</strong> {ephyCommentsCount === 1 ? 'comment' : 'comments'}
        </p>
      </header>

      {comments?.length > 0 &&
        <div className={styles.comments}>
          <InfiniteScroll
            hasMore={hasMoreComments}
            loadMore={loadMoreComments}
            loader={
              <div key={0} style={{ position: 'relative', margin: '20px 0', height: '30px' }}>
                <PulsingLoader />
              </div>
            }
            useWindow={rootRef ? false : true}
            getScrollParent={() => rootRef?.current}
          >
            {comments.map(comment => (
              <Comment
                key={comment.id}
                ephyId={ephyId}
                channelId={channelId}
                comment={comment}
                onLikeClick={likeComment}
                onDeleteClick={deleteComment}
              />
            ))}
          </InfiniteScroll>
        </div>
      }

      {isSignedIn
        ? (
          <div className={styles.editor}>
            <Suspense fallback={<PulsingLoader />}>
              <CommentEditor ephyId={ephyId} channelId={channelId} />
            </Suspense>
          </div>
        )
        : (
          <div className={styles.askSignIn}>
            <Link to="/login">Login</Link> or <Link to="/signup">signup</Link> to comment
          </div>
        )
      }

      <div style={{ height: '20vh' }} />
    </div>
  )
}
