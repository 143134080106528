import styles from './NoContentMessage.module.scss'

import React from 'react'

export default function NoContentMessage({title, subtitle}) {
  return (
    <>
      <header className={styles.message}>
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </header>
    </>
  )
}
