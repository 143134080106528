import {
  USERS_UPDATE_USER_PASSWORD_START,
  USERS_UPDATE_USER_PASSWORD_SUCCESS,
  USERS_FETCH_USER_CHANNELS_START,
  USERS_FETCH_USER_CHANNELS_SUCCESS,
  USERS_FETCH_USER_CHANNELS_ERROR,
  USERS_FETCH_PRIVATE_EPHIES_START,
  USERS_FETCH_PRIVATE_EPHIES_SUCCESS,
  USERS_FETCH_PRIVATE_EPHIES_ERROR,
  // USERS_FETCH_PRIVATE_TAGS_START,
  USERS_FETCH_PRIVATE_TAGS_SUCCESS,
  // USERS_FETCH_PUBLIC_USER_PROFILES_START,
  USERS_FETCH_PUBLIC_USER_PROFILES_SUCCESS,
  // USERS_FETCH_PUBLIC_USER_PROFILES_ERROR,
  // USERS_FETCH_PRIVATE_TAGS_ERROR,
  USERS_DATA_PROCESSING_START,
  USERS_DATA_PROCESSING_SUCCESS,
  USERS_DATA_PROCESSING_ERROR,
  USERS_FETCH_NOTIFICATIONS_START,
  USERS_FETCH_NOTIFICATIONS_SUCCESS,
  // USERS_FETCH_NOTIFICATIONS_ERROR,
  USERS_MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  USERS_FETCH_REACTIONS_SUCCESS,
  USERS_FETCH_COMMENT_LIKES_SUCCESS,
} from './userConstants'

import {
  EPHIES_UPDATE_EPHY_SUCCESS,
  EPHIES_DELETE_EPHY_SUCCESS,
  EPHIES_REACT_TO_EPHY_SUCCESS,
  EPHIES_LIKE_COMMENT_SUCCESS,
} from 'modules/ephy/ephyConstants'

import { sortEphies } from 'utils/ephy'

const initialState = {
  channels: [],
  reactions: [],
  commentLikes: [],
  ephies: [],
  tags: [],
  notifications: [],
  publicUserProfiles: [],
  fetchInProgress: false,
  privateEphiesFetchInProgress: false,
  notificationsFetchInProgress: false,
  dataProcessingInProgress: false,
}

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case '@@reactReduxFirebase/PROFILE_UPDATE_START':
    case USERS_UPDATE_USER_PASSWORD_START:
    case USERS_FETCH_USER_CHANNELS_START:
      return {...state, fetchInProgress: true}

    case USERS_FETCH_PRIVATE_EPHIES_START:
      return {...state,
        ephies: [],
        privateEphiesFetchInProgress: true
      }

    case USERS_FETCH_NOTIFICATIONS_START:
      return {...state,
        notifications: [],
        notificationsFetchInProgress: true
      }

    case USERS_FETCH_USER_CHANNELS_SUCCESS:
      return {...state,
        channels: payload.data,
        fetchInProgress: false,
      }

    case USERS_FETCH_REACTIONS_SUCCESS:
      return {...state,
        reactions: [
          ...(state.reactions.filter(reaction => (
            !payload.data.find(newReaction => newReaction.id === reaction.id)
          ))),
          ...payload.data
        ]
      }

    case USERS_FETCH_COMMENT_LIKES_SUCCESS:
      return {...state,
        commentLikes: [
          ...(state.commentLikes.filter(commentLike => (
            !payload.data.find(newCommentLike => newCommentLike.id === commentLike.id)
          ))),
          ...payload.data
        ]
      }

    case EPHIES_REACT_TO_EPHY_SUCCESS:
      const reactions = state.reactions.filter(reaction => reaction.id !== payload.data.id)

      if (payload.data.reaction) {
        reactions.push(payload.data)
      }

      return {...state, reactions}


    case EPHIES_LIKE_COMMENT_SUCCESS:
      return {...state,
        commentLikes:
          payload.data.like
            ? [...state.commentLikes, { commentId: payload.data.commentId, userId: payload.data.userId }]
            : state.commentLikes.filter(commentLike => commentLike.commentId !== payload.data.commentId)
      }

    case USERS_FETCH_PUBLIC_USER_PROFILES_SUCCESS:
      const publicUserProfiles = [
        ...(state.publicUserProfiles.filter(profile => (
          !payload.data.find(newProfile => newProfile.id === profile.id)
        ))),
        ...payload.data
      ]

      return {...state,
        publicUserProfiles
      }

    case USERS_FETCH_PRIVATE_EPHIES_SUCCESS:
      return {...state,
        ephies: sortEphies([
          ...(state.ephies.filter(ephy => !payload.data.find(newEphy => newEphy.id === ephy.id))),
          ...payload.data
        ]),
        privateEphiesFetchInProgress: false,
      }

    case USERS_FETCH_NOTIFICATIONS_SUCCESS:
      return {...state,
        notifications: [
          ...(state.notifications.filter(notif => !payload.data.find(newNotif => newNotif.id === notif.id))),
          ...payload.data
        ].sort((n1, n2) => n2.createdAt.seconds - n1.createdAt.seconds),
        notificationsFetchInProgress: false,
      }

    case USERS_MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
      return {...state,
        notifications: state.notifications.map(notif => ({ ...notif, read: true }))
      }

    case EPHIES_UPDATE_EPHY_SUCCESS:
      if (!payload.data.channel) {
        return {...state,
          ephies: sortEphies(state.ephies.map(ephy => (
            ephy.id === payload.data.id ? payload.data : ephy
          )))
        }
      }

      return state

    case EPHIES_DELETE_EPHY_SUCCESS:
      if (!payload.data.channel) {
        return {...state,
          ephies: state.ephies.filter(ephy => ephy.id !== payload.data.id)
        }
      }

      return state

    case USERS_FETCH_PRIVATE_TAGS_SUCCESS:
      return {...state,
        tags: payload.data,
        // fetchInProgress: false,
      }

    case '@@reactReduxFirebase/PROFILE_UPDATE_SUCCESS':
    case USERS_UPDATE_USER_PASSWORD_SUCCESS:
      return {...state, fetchInProgress: false}

    case USERS_FETCH_USER_CHANNELS_ERROR:
      return {...state, fetchInProgress: false}

    case USERS_FETCH_PRIVATE_EPHIES_ERROR:
      return {...state, privateEphiesFetchInProgress: false}

    // data processing
    case USERS_DATA_PROCESSING_START:
      return {...state, dataProcessingInProgress: true}

    case USERS_DATA_PROCESSING_SUCCESS:
    case USERS_DATA_PROCESSING_ERROR:
      return {...state, dataProcessingInProgress: false}

    default:
      return state
  }
}
