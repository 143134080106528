import React from 'react'
import { Link } from 'react-router-dom'

import Menu from 'components/Menu'

const UserNotAuthenticatedMenu = () => (
  <Menu variety="userMenu" menuWidth="228">
    <Menu.Item><Link to="/help">Help</Link></Menu.Item>
    <Menu.Item><Link to="/login">Login</Link></Menu.Item>
    {/* <Menu.Item><Link to="/signup">Signup</Link></Menu.Item> */}
  </Menu>
)

export default UserNotAuthenticatedMenu
