import {
  EPHIES_CREATE_COMMENT_START,
  EPHIES_CREATE_COMMENT_SUCCESS,
  EPHIES_CREATE_COMMENT_ERROR,
} from '../ephyConstants'

/**
 * @param {String} channelId
 * @param {String} ephyId
 * @param {String} parentId = null
 * @param {String} commentText
 */
export function createComment({ channelId, ephyId, parentId = null, text }) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: EPHIES_CREATE_COMMENT_START })

    const commentData = {
      channelId,
      ephyId,
      parentId,
      text,
      likesCount: 0,
      createdAt: getFirebase().firestore.Timestamp.now(),
      updatedAt: getFirebase().firestore.Timestamp.now(),
      userId: getState().firebase.auth.uid,
      deleted: false,
    }

    return getFirebase()
      .firestore()
      .collection('comments')
      .add(commentData)
      .then(commentSnapshot => {
        const data = { id: commentSnapshot.id, ...commentData }

        dispatch({
          type: EPHIES_CREATE_COMMENT_SUCCESS,
          payload: {
            data
          }
        })

        return data
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: EPHIES_CREATE_COMMENT_ERROR,
          error,
        })
      })
  }
}
