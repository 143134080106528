import thunk from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import { getFirebase } from 'react-redux-firebase'

const createMiddleware = (browserHistory) => {
  const middleware = [
    thunk.withExtraArgument(getFirebase),
    apiMiddleware,
    routerMiddleware(browserHistory)
  ]

  return middleware
}


export default createMiddleware
