import styles from './Switch.module.scss'

// import { } from 'react'
import { cls } from 'utils'

export default function Switch({
  className,
  id,
  name,
  title,
  subtitle,
  checked,
  onChange,
  onClick,
  ...restProps
}) {
  // const labelProps = {
  //   // className: cn('switch', className),
  // }

  const inputProps = {
    checked,
    onChange,
    onClick,
    ...restProps
  }

  return (
    <div className={cls`${styles.container} ${className}`}>
      <label htmlFor={id} className={styles.label}>
        <div>{title}</div>
        <div>{subtitle}</div>
      </label>
      <input id={id} {...inputProps} type="checkbox" />
    </div>
  )
}
