import {
  SUBSCRIPTION_ALL,
  SUBSCRIPTION_SELECTED,
  SUBSCRIPTION_UPDATE_START,
  SUBSCRIPTION_UPDATE_SUCCESS,
  // SUBSCRIPTION_UPDATE_ERROR,
  FEED_EPHY_LIMIT,
  FEED_FETCH_SUBSCRIPTIONS_START,
  FEED_FETCH_SUBSCRIPTIONS_SUCCESS,
  FEED_FETCH_SUBSCRIPTIONS_ERROR,
  FEED_FETCH_FEED_START,
  FEED_FETCH_FEED_SUCCESS,
  FEED_FETCH_FEED_ERROR,
} from './feedConstants'

import isCallable from 'is-callable'
import { createRecordsLoader, SNAPSHOT_PROCESSOR_FEED } from 'utils/infiniteScroll'

export function subscribeUserToChannel(channel) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: SUBSCRIPTION_UPDATE_START })

    const subscription = {
      channelId: channel.id,
      type: SUBSCRIPTION_ALL,
      subTagIds: [],
      unsubTagIds: []
    }

    return new Promise((resolve, reject) => {
      getFirebase()
        .firestore()
        .collection('users')
        .doc(getState().firebase.auth.uid)
        .collection('subscriptions')
        .where('channelId', '==', channel.id)
        .get()
        .then(subscriptionsSnapshot => {
          if (subscriptionsSnapshot.empty) {
            getFirebase()
              .firestore()
              .collection('users')
              .doc(getState().firebase.auth.uid)
              .collection('subscriptions')
              .add(subscription)
              .then(() => {
                resolve(subscription)

                dispatch({
                  type: SUBSCRIPTION_UPDATE_SUCCESS,
                  payload: {
                    data: subscription
                  }
                })
              })
          } else {
            subscriptionsSnapshot.docs[0]
              .ref
              .set(subscription)
              .then(() => {
                resolve(subscription)

                dispatch({
                  type: SUBSCRIPTION_UPDATE_SUCCESS,
                  payload: {
                    data: subscription
                  }
                })
              })
          }
        })

    })
  }
}

export function unsubscribeUserFromChannel(channel) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: SUBSCRIPTION_UPDATE_START })

    return new Promise((resolve, reject) => {
      getFirebase()
        .firestore()
        .collection('users')
        .doc(getState().firebase.auth.uid)
        .collection('subscriptions')
        .where('channelId', '==', channel.id)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.size > 0) {
            querySnapshot.docs[0].ref
              .delete()
              .then(() => {
                resolve()

                dispatch({
                  type: SUBSCRIPTION_UPDATE_SUCCESS,
                })
              })
          }
        })
    })
  }
}


export function subscribeUserToChannelTag(channel, tag) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: SUBSCRIPTION_UPDATE_START })

    const subscriptionsCollectionRef = getFirebase()
      .firestore()
      .collection('users')
      .doc(getState().firebase.auth.uid)
      .collection('subscriptions')

    return new Promise((resolve, reject) => {
      subscriptionsCollectionRef
        .where('channelId', '==', channel.id)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.size > 0) {
            const subscriptionData = querySnapshot.docs[0].data()

            subscriptionData.subTagIds.push(tag.id)
            subscriptionData.subTagIds = [...new Set(subscriptionData.subTagIds)]
            subscriptionData.unsubTagIds.splice(subscriptionData.unsubTagIds.indexOf(tag.id), 1)

            querySnapshot.docs[0].ref
              .set(subscriptionData)
              .then(() => {
                resolve()

                dispatch({
                  type: SUBSCRIPTION_UPDATE_SUCCESS,
                })
              })
          } else {
            const subscriptionData = {
              channelId: channel.id,
              type: SUBSCRIPTION_SELECTED,
              subTagIds: [tag.id],
              unsubTagIds: []
            }

            subscriptionsCollectionRef
              .add(subscriptionData)
              .then(() => {
                resolve()

                dispatch({
                  type: SUBSCRIPTION_UPDATE_SUCCESS,
                })
              })
          }
        })
    })
  }
}


export function unsubscribeUserFromChannelTag(channel, tag) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: SUBSCRIPTION_UPDATE_START })

    const subscriptionsCollectionRef = getFirebase()
      .firestore()
      .collection('users')
      .doc(getState().firebase.auth.uid)
      .collection('subscriptions')

    return new Promise((resolve, reject) => {
      subscriptionsCollectionRef
        .where('channelId', '==', channel.id)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.size > 0) {
            const subscriptionData = querySnapshot.docs[0].data()

            // if last tag & type=selected - remove item
            if (subscriptionData.type === SUBSCRIPTION_SELECTED
                  && subscriptionData.subTagIds.length === 1
                  && subscriptionData.subTagIds[0] === tag.id
            ) {
              querySnapshot.docs[0].ref
                .delete()
                .then(() => {
                  resolve()

                  dispatch({
                    type: SUBSCRIPTION_UPDATE_SUCCESS,
                  })
                })
            } else {
              subscriptionData.unsubTagIds.push(tag.id)
              subscriptionData.unsubTagIds = [...new Set(subscriptionData.unsubTagIds)]
              subscriptionData.subTagIds.splice(subscriptionData.subTagIds.indexOf(tag.id), 1)

              querySnapshot.docs[0].ref
                .set(subscriptionData)
                .then(() => {
                  resolve()

                  dispatch({
                    type: SUBSCRIPTION_UPDATE_SUCCESS,
                  })
                })
            }
          }
        })
    })
  }
}

export function subscribeToUserSubscriptions(callback) {
  return (dispatch, getState, getFirebase) => {

    dispatch({ type: FEED_FETCH_SUBSCRIPTIONS_START })

    let callbackFired = false
    return getFirebase()
      .firestore()
      .collection('users')
      .doc(getState().firebase.auth.uid)
      .collection('subscriptions')
      .onSnapshot(
        querySnapshot => {
          Promise.all(
            querySnapshot.docs.map(subscription => {
              const subscriptionData = Object.assign({ id: subscription.id }, subscription.data())

              return Promise.all([
                getFirebase()
                  .firestore()
                  .collection('channels')
                  .doc(subscriptionData.channelId)
                  .get()
                  .then(channelSnapshot => {
                    return { id: channelSnapshot.id, ...channelSnapshot.data() }
                  })
                  .catch(error => {
                    console.warn(error)
                    return false
                  }),
                getFirebase()
                  .firestore()
                  .collection('channels')
                  .doc(subscriptionData.channelId)
                  .collection('tags')
                  .get()
                  .then(tagsSnapshot => {
                    const tags = []
                    tagsSnapshot.forEach(tagRef => {
                      tags.push({ id: tagRef.id, ...tagRef.data() })
                    })

                    return tags
                  })
                  .catch(error => {
                    console.warn(error)
                    return false
                  })
              ]).then(channelsData => {
                // console.log(channelsData)
                subscriptionData.channel = channelsData[0]
                subscriptionData.tags = channelsData[1]

                return subscriptionData
              })
            })
          ).then(subscriptionsList => {
            const subscriptionsListFiltered = subscriptionsList.filter(
              subscription => subscription.channel && subscription.tags
            )

            if (!callbackFired && isCallable(callback)) {
              callbackFired = true
              callback(subscriptionsListFiltered)
            }

            dispatch({
              type: FEED_FETCH_SUBSCRIPTIONS_SUCCESS,
              payload: {
                data: subscriptionsListFiltered
              }
            })
          })
      },
      error => {
        console.error(error)

        dispatch({
          type: FEED_FETCH_SUBSCRIPTIONS_ERROR,
          payload: {
            error
          }
        })
      }
    )
  }
}


export function subscribeToUserFeed(options = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: FEED_FETCH_FEED_START })

    const { channelId } = options

    const createQuery = ({ startAfter, endBefore } = {}) => {
      let query = getFirebase()
        .firestore()
        .collection('users')
        .doc(getState().firebase.auth.uid)
        .collection('feed')

      if (channelId) {
        query = query.where('channelId', '==', channelId)
      }

      query = query
        .orderBy('createdAt', 'desc')
        .limit(FEED_EPHY_LIMIT)

      if (startAfter) {
        query = query.startAfter(startAfter)
      }

      if (endBefore) {
        query = query.endBefore(endBefore)
      }

      return query
    }

    const { load, loadMore, unsubscribe } = createRecordsLoader({
      createQuery,
      dispatch,
      limit: FEED_EPHY_LIMIT,
      successAction: FEED_FETCH_FEED_SUCCESS,
      errorAction: FEED_FETCH_FEED_ERROR,
      snapshotProcessor: SNAPSHOT_PROCESSOR_FEED,
      bumpEphyImpressions: true,
    })

    load()

    return {
      unsubscribe,
      loadMore
    }
  }
}
