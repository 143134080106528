import {
  EPHIES_SEARCH_START,
  EPHIES_SEARCH_SUCCESS,
  EPHIES_SEARCH_ERROR,
  EPHIES_FETCH_COMMENTS_START,
  EPHIES_FETCH_COMMENTS_SUCCESS,
  EPHIES_FETCH_COMMENTS_ERROR,
  EPHIES_LIKE_COMMENT_SUCCESS,
  EPHIES_UPDATE_COMMENT_SUCCESS,
  EPHIES_DELETE_COMMENT_SUCCESS,
} from './ephyConstants'

const initialState = {
  ephies: [],
  comments: {},
  searchInProgress: false,
  commentsFetchInProgress: false,
}

export default function ephyReducer(state = initialState, { type, payload }) {
  switch (type) {

    case EPHIES_SEARCH_START:
      return {...state,
        searchInProgress: true
      }

    case EPHIES_SEARCH_SUCCESS:
      return {...state,
        searchInProgress: false,
        ephies: payload.data,
      }

    case EPHIES_SEARCH_ERROR:
      return {...state,
        searchInProgress: false
      }

    case EPHIES_FETCH_COMMENTS_START:
      return {...state,
        commentsFetchInProgress: true
      }

    case EPHIES_FETCH_COMMENTS_SUCCESS:
      return {...state,
        commentsFetchInProgress: false,
        comments: {
          ...state.comments,
          [payload.ephyId]: updateCommentsTree(state.comments[payload.ephyId] || [], payload.data)
        }
      }

    case EPHIES_LIKE_COMMENT_SUCCESS:
      const { ephyId, comment } = getCommentFromTree(state.comments, payload.data.commentId)

      if (!ephyId || !comment)
        return state

      comment.likesCount += payload.data.like ? 1 : -1

      return {...state,
        comments: {
          ...state.comments,
          [ephyId]: updateCommentInTree(state.comments[ephyId], comment)
        }
      }

    case EPHIES_UPDATE_COMMENT_SUCCESS:
      const { ephyId: ephyId2, comment: comment2 } = getCommentFromTree(state.comments, payload.data.id)

      if (!ephyId2 || !comment2)
        return state

      return {...state,
        comments: {
          ...state.comments,
          [ephyId2]: updateCommentInTree(state.comments[ephyId2], {
            ...comment2,
            ...payload.data
          })
        }
      }

    case EPHIES_DELETE_COMMENT_SUCCESS:
      const { ephyId: ephyId3, comment: comment3 } = getCommentFromTree(state.comments, payload.data.id)

      if (!ephyId3 || !comment3)
        return state

      return {...state,
        comments: {
          ...state.comments,
          [ephyId3]: updateCommentInTree(state.comments[ephyId3], {
            ...comment3,
            text: '',
            deleted: true,
          })
        }
      }

    case EPHIES_FETCH_COMMENTS_ERROR:
      return {...state,
        commentsFetchInProgress: false
      }

    default:
      return state
  }
}

/**
 * Updates a comments tree for a single ephy
 *
 * Data strcuture:
 * [ // lvl1 comments
 *  {
 *    ...commentData,
 *    children: [ // lvl2 comments
 *      ...
 *    ]
 *  },
 *  ...{}
 * ]
 *
 * @param {Array} tree
 * @param {Array} newComments
 * @returns {Array} updatedTree
 */
 function updateCommentsTree(tree, newComments) {
  const level1NewComments = newComments.filter(comment => !comment.parentId)
  const level2NewComments = newComments.filter(comment => comment.parentId)

  // insering lvl1 comments
  const newTree = [
    ...tree.filter(comment => !level1NewComments.find(newComment => newComment.id === comment.id)),
    ...level1NewComments
  ]

  // insering lvl2 comments
  for (let newLevel2Comment of level2NewComments) {
    const parentIndex = newTree.findIndex(comment => comment.id === newLevel2Comment.parentId)
    if (parentIndex === -1)
      continue

    if (!newTree[parentIndex].children) {
      newTree[parentIndex].children = [newLevel2Comment]
    } else {
      newTree[parentIndex].children = [
        ...newTree[parentIndex].children.filter(comment => comment.id !== newLevel2Comment.id),
        newLevel2Comment
      ].sort((c1, c2) => c1.createdAt.seconds - c2.createdAt.seconds)
    }
  }

  return newTree.sort((c1, c2) => c1.createdAt.seconds - c2.createdAt.seconds)
}

function getCommentFromTree(trees, commentId) {
  for (const [ephyId, tree] of Object.entries(trees)) {
    for (const comment of tree) {
      if (comment.id === commentId)
        return { ephyId, comment }

      if (comment.children) {
        for (const commentLvl2 of comment.children) {
          if (commentLvl2.id === commentId)
            return { ephyId, comment: commentLvl2 }
        }
      }
    }
  }

  return { ephyId: false, comment: false }
}

function updateCommentInTree(tree, newCommentData) {
  return [...tree.map(comment => {
    if (comment.id === newCommentData.id) {
      return newCommentData
    } else {
      return {...comment, children: [...(comment.children || []).map(commentLvl2 => {
        if (commentLvl2.id === newCommentData.id) {
          return newCommentData
        } else {
          return commentLvl2
        }
      })]}
    }
  })]
}
