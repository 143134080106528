import styles from './Dropdown.module.scss'

import React, { useEffect, useState, useRef } from 'react'
import { usePopper } from 'react-popper'

import { Overlay } from 'components/Overlay'
import { useDebounce, useOnClickOutsideCallback } from 'hooks'
import isCallable from 'is-callable'

const Dropdown = ({
  button,
  dropdown,
  menu = dropdown,
  placement = 'bottom-end',
  offset = 0,
  trigger = 'click',
  borderRadius = 0,
  maxHeight = '100',
  onHide,
  onShow,
}) => {
  const [instantShowMenu, setInstantShowMenu] = useState(false)
  const debouncedShowMenu = useDebounce(instantShowMenu, 300)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const dropdownContainerRef = useOnClickOutsideCallback(() => setInstantShowMenu(false))

  const hoverHandlers = {}
  const clickHandlers = {}

  if (trigger === 'hover') {
    hoverHandlers.onMouseEnter = (e) => setInstantShowMenu(true)
    hoverHandlers.onMouseLeave = (e) => setInstantShowMenu(false)
  } else {
    clickHandlers.onClick = (e) => {
      // way to prevent dropdown hide on menu click
      // console.log(popperElement?.contains(e.target))
      // e.stopPropagation()
      if (trigger === 'click') setInstantShowMenu(!instantShowMenu)
    }
  }

  const showMenuPrevious = useRef()
  const showMenu = trigger === 'click' ? instantShowMenu : debouncedShowMenu

  useEffect(() => {
    if (!showMenu && showMenuPrevious.current) isCallable(onHide) && onHide()
    if (showMenu && !showMenuPrevious.current) isCallable(onShow) && onShow()

    showMenuPrevious.current = showMenu
  })

  const {
    styles: popperStyles,
    attributes: popperAttributes
  } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, offset],
        },
      },
    ],
  })

  return (
    <div
      className={styles.container}
      ref={dropdownContainerRef}
      {...hoverHandlers}
      {...clickHandlers}
    >
      {React.cloneElement(button, {ref: setReferenceElement})}
      {showMenu &&
        <Overlay className={styles.dropdown}>
          <div
            className={styles.menu}
            ref={setPopperElement}
            style={{
              ...popperStyles.popper,
              maxHeight: `${maxHeight}vh`,
              borderRadius: `${borderRadius}px`
            }}
            {...popperAttributes.popper}
          >
            {menu}
          </div>
        </Overlay>
      }
    </div>
  )
}

export default Dropdown
