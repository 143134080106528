import styles from './DiscoverView.module.scss'

import MetaTags from 'react-meta-tags'

import { MainLayout } from 'layout'
import { PulsingLoader } from 'components/Loader'
import { Link } from 'react-router-dom'
import useDiscoverChannelList from './useDiscoverChannelList'
import Channel from './components/Channel'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import { useBreakpoints } from 'hooks'

export default function DiscoverView() {
  const { isMobile } = useBreakpoints()

  const {
    hasMoreChannels,
    loadMoreChannels,
    channels,
    isLoading,
  } = useDiscoverChannelList()

  const subscriptions = useSelector(state => state.feed.subscriptions)

  if (isLoading)
    return <PulsingLoader />

  return (
    <>
      <MetaTags>
        <title>Discover – Lightspot</title>
        <meta property="og:title" content="Discover – Lightspot" />
        <meta name="description" content="Discover – Lightspot" />
        <meta property="og:description" content="Discover – Lightspot" />
      </MetaTags>
      <MainLayout variant="discover">
        <MainLayout.LeftSidebar />

        <MainLayout.MainContent>
          <div className={styles.container}>
            <h1>Interesting channels to discover</h1>

            {isLoading
              ? <PulsingLoader />
              : <>
                {channels.length > 0 ? (
                  <>
                  <InfiniteScroll
                      className={styles.channels}
                      hasMore={hasMoreChannels}
                      loadMore={loadMoreChannels}
                      loader={
                        <div key={0} style={{ position: 'relative', marginTop: '50px' }}>
                          <PulsingLoader />
                        </div>
                      }
                    >
                      {channels.map(channel => (
                        <Surface key={channel.id}>
                          <Channel
                            channel={channel}
                            subscriptions={subscriptions}
                            isMobile={isMobile}
                          />
                        </Surface>
                      ))}
                  </InfiniteScroll>
                  <div style={{ height: '20vh' }}></div>
                  </>
                ) : (
                  <p className={styles.emptyMessage}>
                    You've already subscribed to all the gems we have! It's time
                    to <Link to="/welcome/create_channel">create</Link> your own!
                  </p>
                )}
                </>
            }
          </div>
        </MainLayout.MainContent>

        <MainLayout.RightSidebar />
      </MainLayout>
    </>
  )
}

export function Surface(props) {
  return (
    <div className={styles.surfaceContainer}>
      {props.children}
    </div>
  )
}
