import 'styles/main.scss'

import React from 'react'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import firebase from './firebase'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import AppWrapper from './AppWrapper'
import createMiddleware from './middleware'
import createRootReducer from './rootReducer'
import Routes from './routes'
import { AuthIsLoaded } from 'utils'

const reduxFirebaseConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
}

class App extends React.Component {
  constructor(props) {
    super(props)

    let composeEnhancers = compose
    if (process.env.NODE_ENV === 'development' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    }

    const history = createBrowserHistory()
    const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...createMiddleware(history)))(createStore)
    const store = createStoreWithMiddleware(createRootReducer(history))

    this.store = store
    this.history = history
  }

  render () {
    return (
      <Provider store={this.store}>
        <ReactReduxFirebaseProvider
          firebase={firebase}
          createFirestoreInstance={createFirestoreInstance}
          config={reduxFirebaseConfig}
          dispatch={this.store.dispatch}>
          <ConnectedRouter history={this.history}>
            <AuthIsLoaded>
              <AppWrapper>
                <Routes />
              </AppWrapper>
            </AuthIsLoaded>
          </ConnectedRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    )
  }
}

export default App
