// import styles from './SubscriptionsList.module.scss'

import React, {useMemo, useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { compose } from 'redux'

import { useBreakpoints, useOverlay } from 'hooks'
import Button from 'components/Button'
import SubscriptionListItem from '../SubscriptionListItem'
import pillStyles from 'components/Pill/Pill.module.scss'
import {ReactComponent as HamburgerIcon} from 'icons/Hamburger.svg'
import {ReactComponent as CloseIcon} from 'icons/Close.svg'
import { injectProps, listWithClass } from 'modules/channel/components/TagList/TagList'
// import PropTypes from 'prop-types'
import styles from './SubscriptionsList.module.scss'

export default function SubscriptionsList({
  subscriptions,
  activeSubscription,
  onSubscriptionClick
}) {
  const [isOverlay, setIsOverlay, OverlayOrInFlow] = useOverlay()
  const onChange = useMemo(() => () => setIsOverlay(false), [setIsOverlay])
  const {isMobile, isDesktop } = useBreakpoints({onChange})

  const overlayRef = useRef()
  useOnClickOutside(overlayRef, () => setIsOverlay(false))

  const showActionsButton = isOverlay? true : false
  const PillList = useMemo(() => compose(
    listWithClass((isOverlay || isDesktop) ? pillStyles.verticalList : pillStyles.horizontalList),
    injectProps({showActionsButton, pillStyle: isOverlay ? 'dark' : 'light', isOverlay})
  )(SubscriptionListItem), [isOverlay, isDesktop, showActionsButton])

  return (
    <OverlayOrInFlow>
      <div ref={overlayRef} className={isOverlay ? styles.overlay : styles.inFlow}>
        {isMobile && subscriptions.length > 0 &&
          <div className={styles.header}>
            <Button
              kind={isOverlay ? 'iconDark' : 'icon'}
              size="38"
              onClick={() => setIsOverlay(!isOverlay)}
            >
              {isOverlay ? <CloseIcon /> : <HamburgerIcon /> }
            </Button>
          </div>
        }
        <h1>Subscriptions</h1>
        <PillList
          items={subscriptions}
          onSubscriptionClick={(id) => {
            onSubscriptionClick(id)
            setIsOverlay(false)
          }}
          isMobile={isMobile}
          activeSubscription={activeSubscription}
        />
      </div>
    </OverlayOrInFlow>
  )
}

SubscriptionsList.propTypes = { }
