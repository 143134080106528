import styles from './EphyLayout.module.scss'

import React from 'react'

import { cls } from 'utils'

export const EphyLayout = React.forwardRef(({ children, className }, ref) => (
  <div
    ref={ref}
    className={cls`${className} ${styles.container}`}
  >
    {children}
  </div>
))

EphyLayout.Header = Header
export function Header(props) {
  return (
    <div className={styles.header}>
      {props.children}
    </div>
  )
}

EphyLayout.Body = Body
export function Body(props) {
  return (
    <div className={styles.body}>
      {props.children}
    </div>
  )
}

EphyLayout.Footer = Footer
export function Footer(props) {
  return (
    <div className={styles.footer}>
      {props.children}
    </div>
  )
}
