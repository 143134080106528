import {
  PROFILE_FETCH_PROFILE_START,
  PROFILE_FETCH_PROFILE_SUCCESS,
  PROFILE_FETCH_PROFILE_ERROR,
  PROFILE_FETCH_CHANNELS_START,
  PROFILE_FETCH_CHANNELS_SUCCESS,
  PROFILE_FETCH_CHANNELS_ERROR,
} from './profileConstants'

const initialState = {
  profile: {},
  channels: [],
  fetchInProgress: false,
}

export default function profileReducer(state = initialState, { type, payload }) {
  switch (type) {
    case PROFILE_FETCH_PROFILE_START:
    case PROFILE_FETCH_CHANNELS_START:
      return {...state,
        fetchInProgress: true,
      }

    case PROFILE_FETCH_PROFILE_SUCCESS:
      return {...state,
        profile: payload.data,
      }

    case PROFILE_FETCH_CHANNELS_SUCCESS:
      return {...state,
        channels: payload.data,
        fetchInProgress: false,
      }

    case PROFILE_FETCH_PROFILE_ERROR:
    case PROFILE_FETCH_CHANNELS_ERROR:
      return {...state, fetchInProgress: false}

    default:
      return state
  }
}
