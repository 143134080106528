import {
  // TAGS_NEW_TAG,
  // TAGS_REPLACE_TAG,
  // TAGS_REMOVE_TAG,
  TAGS_CREATE_TAG_START,
  TAGS_CREATE_TAG_SUCCESS,
  TAGS_UPDATE_TAG_START,
  TAGS_UPDATE_TAG_SUCCESS,
  TAGS_DELETE_TAG_START,
  TAGS_DELETE_TAG_SUCCESS,
  TAGS_REORDER_START,
  TAGS_REORDER_SUCCESS,
  // TAGS_REORDER_ERROR,
  // TAGS_FETCH_ERROR,
  // TAGS_FETCH_TAG_START,
  // TAGS_FETCH_TAG_SUCCESS,
  // TAGS_FETCH_TAG_ERROR,
} from './tagConstants'
// import isCallable from 'is-callable'

export function createTag(tag, { channel = null } = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: TAGS_CREATE_TAG_START })

    return new Promise((resolve, reject) => {
      const tagData = {
        name: tag.name,
        order: tag.order,
        createdAt: getFirebase().firestore.Timestamp.now(),
        createdBy: getState().firebase.auth.uid,
      }

      let tagsCollectionRef

      if (channel) {
        tagsCollectionRef = getFirebase()
          .firestore()
          .collection('channels')
          .doc(channel.id)
          .collection('tags')
      } else {
        tagsCollectionRef = getFirebase()
          .firestore()
          .collection('users')
          .doc(getState().firebase.auth.uid)
          .collection('tags')
      }

      return tagsCollectionRef
        .add(tagData)
        .then(() => {
          resolve(tagData)

          dispatch({
            type: TAGS_CREATE_TAG_SUCCESS,
            payload: {
              data: tagData
            }
          })
        })
    })
  }
}

export function updateTag(tag, { channel = null } = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: TAGS_UPDATE_TAG_START })

    return new Promise((resolve, reject) => {
      const tagData = {
        name: tag.name,
      }
      let originalData
      let tagsCollectionRef

      if (channel) {
        tagsCollectionRef = getFirebase()
          .firestore()
          .collection('channels')
          .doc(channel.id)
          .collection('tags')

        originalData = getState().channel.tags[channel.id].find(t => t.id === tag.id)
      } else {
        tagsCollectionRef = getFirebase()
          .firestore()
          .collection('users')
          .doc(getState().firebase.auth.uid)
          .collection('tags')

        originalData = getState().user.tags.find(t => t.id === tag.id)
      }

      return tagsCollectionRef
        .doc(tag.id)
        .update(tagData)
        .then(() => {
          resolve(tagData)

          dispatch({
            type: TAGS_UPDATE_TAG_SUCCESS,
            payload: {
              originalData,
              data: tagData
            }
          })
        })
    })
  }
}

export function deleteTag(tag, { channel = null } = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: TAGS_DELETE_TAG_START })

    return new Promise((resolve, reject) => {
      let tagsCollectionRef

      if (channel) {
        tagsCollectionRef = getFirebase()
          .firestore()
          .collection('channels')
          .doc(channel.id)
          .collection('tags')
      } else {
        tagsCollectionRef = getFirebase()
          .firestore()
          .collection('users')
          .doc(getState().firebase.auth.uid)
          .collection('tags')
      }

      return tagsCollectionRef
        .doc(tag.id)
        .delete()
        .then(() => {
          resolve(tag)

          dispatch({
            type: TAGS_DELETE_TAG_SUCCESS,
            payload: {
              data: tag
            }
          })
        })
    })
  }
}

export function reorder(tags, { channel = null } = {}) {
  return (dispatch, getState, getFirebase) => {
    dispatch({ type: TAGS_REORDER_START })

    return new Promise((resolve, reject) => {
      const batch = getFirebase().firestore().batch()

      tags.forEach((tag, index) => {
        if (tag.order !== index) {
          let tagsCollectionRef

          if (channel) {
            tagsCollectionRef = getFirebase()
              .firestore()
              .collection('channels')
              .doc(channel.id)
              .collection('tags')
          } else {
            tagsCollectionRef = getFirebase()
              .firestore()
              .collection('users')
              .doc(getState().firebase.auth.uid)
              .collection('tags')
          }

          const tagRef = tagsCollectionRef.doc(tag.id)

          batch.update(tagRef, {
            order: index,
          })
        }
      })

      batch.commit().then(() => {
        resolve()

        dispatch({
          type: TAGS_REORDER_SUCCESS,
          payload: {
            data: tags
          }
        })
      })
    })
  }
}
