import styles from './OnboardingForm.module.scss'
import { ReactComponent as EphyIcon } from './Logo.svg'

import React from 'react'
import { Link } from 'react-router-dom'

export const OnboardingForm = (props) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.header}>
        <Link to="/"><EphyIcon /></Link>
      </div>
      <div className={styles.title}>
        <h1>{props.title}</h1>
        <p>{props.subtitle}</p>
        {props.mobilePromo && (
          <div className={styles.mobilePromo}>{props.mobilePromo}</div>
        )}
      </div>
      <div className={styles.form}>
        {props.children}
      </div>
    </div>
    {props.footer &&
      <div className={styles.footer}>
        {props.footer}
      </div>
    }
  </div>
)
